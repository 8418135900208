import React, { useEffect, useState } from 'react';

import { Container, Text } from './search.element';
import './styles.scss';

const Search = ({ onChange, placeholder }) => {
  return (
    <>
    <div id='search'>
      <Container onChange={onChange} id='container'>
        <input id="input-search-component" type="text" placeholder={placeholder} onChange={onChange}/>
      </Container>
    </div>
    </>
  );
};

export default Search;

import React, { useState } from 'react';
import { Title } from '../../../components';

import anexo1 from '../../../assets/arquivos/ANEXO-I-BENEFICIARIO-ADVOGADO-PREENCHEDOR-RENDIMENTO-ISENTO-NAO-TRIBUTAVEL.docx';
import anexo2 from '../../../assets/arquivos/ANEXO-I-BENEFICIÁRIO-ADVOGADO-PREENCHEDOR-SEM-DECLARAÇAO-DE-ISENÇAO-NAO-INCIDENCIA.docx';
import anexo3 from '../../../assets/arquivos/ANEXO-I-BENEFICIARIO-BENEFICIARIO-PARTE-PJ-OPTANTE-PELO-SIMPLES.docx';
import anexo4 from '../../../assets/arquivos/ANEXO-I-BENEFICIARIO-BENEFICIARIO-PARTE-RENDIMENTO-ISENTO-NAO-TRIBUTAVEL.docx';
import anexo5 from '../../../assets/arquivos/ANEXO-I-BENEFICIARIO-BENEFICIARIO-PARTE-RENDIMENTO-NORMAL-SEM-DECLARAÇAO-ESPECIAL.docx';
import listDocuments from '../../../assets/arquivos/ANEXO-II-LISTA-DE-DOCUMENTOS.pdf'
import formulario from '../../../assets/arquivos/formulario_alvara_vs4.docx';

import bolinhas2 from '../../../assets/images/Alvaras/bolinhas2.svg';
import bolinhas3 from '../../../assets/images/Alvaras/bolinhas3.svg';
import bolinhas4 from '../../../assets/images/Alvaras/bolinhas4.svg';
import blob3 from '../../../assets/images/Alvaras/blob3.svg';
import blob4 from '../../../assets/images/Alvaras/blob4.svg';

import './styles.scss'

const BancoDoBrasil = () => {
  const [doc1,setDoc1] = useState(false);
  const [doc2,setDoc2] = useState(false);
  const [doc3,setDoc3] = useState(false);

  const handleDoc1 = () => {
    setDoc1(!doc1);
    setDoc2(false);
    setDoc3(false);
  }
  const handleDoc2 = () => {
    setDoc1(false);
    setDoc2(!doc2);
    setDoc3(false);
  }
  const handleDoc3 = () => {
    setDoc1(false);
    setDoc2(false);
    setDoc3(!doc3);
  }

  return (
    <div id="banco-do-brasil">
      <section className="section-1">
      <Title 
       textContent='banco do brasil'
       customClass='title-page'
       link1='A OAB/SE >'
       link2='&nbsp;Alvarás >'
       link3='&nbsp;Banco do brasil'
       rota1='/'
       customClassBackground='background-red-horizontal'
       title
      />
    </section>
    <section className="section-2">
      <div className="title">
      OAB/SE e Banco do Brasil lançam canal para recebimento de alvarás, 
      precatórios e RPV´s da Justiça Federal
      </div>
    </section>
    <section className="section-3">
      <img className="bolinhas2-externo" src={bolinhas2} alt="" />
      <div className="blue">
        <img className="details blob3" src={blob3} alt="" />
        <img className="details blob4" src={blob4} alt="" />
        <img className="details bolinhas2" src={bolinhas2} alt="" />
        <p>A Ordem dos Advogados do Brasil, em Sergipe, e o Banco do Brasil firmam 
          acordo em processo judicial deflagrado pela Seccional para criação de um 
          canal virtual visando o pagamento de alvarás, RPV’s e precatórios oriundos 
          da Justiça Federal, através de crédito em conta.
        </p>
        <p>Para tanto, deverão os advogados e advogadas se valerem do e-mail 
          saquebb@oabsergipe.org.br, para encaminhar os documentos e formulário, 
          indicados no fim da matéria.
        </p>
      </div>
      <div className="red">
        <h1 className="title" style={{color:"#FFFFFF"}}>Procedimento</h1>
        <p>Os valores serão creditados ou transferidos para a conta indicada pelo 
          advogado no prazo de até 4 dias úteis – contados após recebimento do 
          e-mail pelo banco -, sendo devidas as taxas e tarifas decorrentes da 
          operação escolhida (TED, DOCs ou TEVs), além da retenção de tributos.
        </p>
        <p>
        O crédito será realizado apenas para o beneficiário indicado, sendo vedado 
        crédito a terceiros. Porém, o advogado titular do crédito pode solicitar o 
        pagamento em favor de sociedade que tenha vínculo associativo ou societário, 
        a ser comprovada por certidão emitida pela OAB/SE.
        </p>
      </div>
    </section>
    <section className="section-4">
     <img className="details blob4" src={blob4} alt="" />
     <img className="details bolinhas2" src={bolinhas2} alt="" />
     <div className="column">
      <div className="title" style={{color:"#FFFFFF"}}>
      Formulário
      </div>
      <p>
      Os pedidos devem estar acompanhados de formulário disponível aqui, assinado 
      digitalmente pelo advogado por meio da Infraestrutura de Chaves – ICP Brasil, 
      onde indicado o banco, agência, conta, tipo de conta, nome completo e número 
      do CPF/CNPJ do titular da conta para crédito do valor, conforme os dados do 
      beneficiário constantes nos alvarás, precatórios e RPV’s.
      </p>
      <button className="button" type="button">
        <a href={formulario} target="_blank" rel="noreferrer">Formulário</a>
      </button>
     </div>
    </section>
    <section className="section-5">
    <img className="details bolinhas4" src={bolinhas4} alt="" />
      <h1 className="title">Documentos</h1>
      <div className="documentos">
        <p>
        O Banco cumprirá os alvarás judiciais, precatórios e RPV’s exclusivamente 
        da Justiça Federal, desde que remetidos pelos advogados à OAB/SE, que 
        conferirá e validará a assinatura digital dos documentos enviados, para 
        então encaminhar ao Banco, por e-mail. Todos os documentos deverão ser 
        enviados reunidos em um único PDF.
        </p>
        <span className="title subtitle">
        Os alvarás, precatórios e RPV’s deverão ser assinados de maneira digital 
        pela Justiça Federal para que seja possível a conferência pelo Banco no 
        site do Tribunal, devendo ainda serem anexados e assinados digitalmente 
        pelo advogado, quando do envio à OAB:
        </span>
        <div className="docs">
          <div className={doc1  ? "doc active" : "doc"} onClick={handleDoc1}>1</div>
          <div className={doc2  ? "doc active" : "doc"}  onClick={handleDoc2}>2</div>
          <div className={doc3  ? "doc active" : "doc"}  onClick={handleDoc3}>3</div>
        </div>
        {doc1 ?
            <div className="content doc-1">
              <p>cópia da carteira da OAB do advogado preenchedor;</p>
            </div>
          :
          doc2 ?
            <div className="content doc-2">
              <p>
              declaração e solicitação para resgate (modelos do anexo I conforme o caso) 
              devidamente preenchido, onde consta a declaração de responsabilidade do 
              advogado pelas informações prestadas, e;
              </p>
            </div>
          :
          doc3 &&
            <div className="content doc-3">
              <p>Documentos específicos discriminados na relação anexa (anexo II), conforme o caso</p>
              <a href={anexo1} target="_blank" rel="noreferrer">
              ANEXO I – BENEFICIÁRIO ADVOGADO PREENCHEDOR (RENDIMENTO ISENTO-NÃO TRIBUTÁVEL)
              </a>
              <a href={anexo2} target="_blank" rel="noreferrer">
              ANEXO I – BENEFICIÁRIO ADVOGADO PREENCHEDOR (SEM DECLARAÇÃO DE ISENÇÃO-NÃO INCIDÊNCIA)
              </a>
              <a href={anexo3} target="_blank" rel="noreferrer">
              ANEXO I – BENEFICIÁRIO PARTE (PJ OPTANTE PELO SIMPLES)
              </a >
              <a href={anexo4} target="_blank" rel="noreferrer">
              ANEXO I – BENEFICIÁRIO PARTE (RENDIMENTO ISENTO-NÃO TRIBUTÁVEL)
              </a>
              <a href={anexo5} target="_blank" rel="noreferrer">
              ANEXO I – BENEFICIÁRIO PARTE (RENDIMENTO NORMAL-SEM DECLARAÇÃO ESPECIAL)
              </a >
              <a href={listDocuments} target="_blank" rel="noreferrer">
              ANEXO II – LISTA DE DOCUMENTOS
              </a >
            </div>
          }
      </div>
      <span className="title mini-title">
      Todos os documentos anexados pelo advogado devem estar no formato PDF e 
      serem por ele assinados digitalmente por meio da Infraestrutura de Chaves 
      – ICP Brasil. O e-mail encaminhado pelo advogado à OAB deverá ter, ao todo, 
      no máximo 15MB.
      </span>
    </section>
    <section className="section-6">
      <img className="details bolinhas3" src={bolinhas3} alt="" />
      <div className="pagamentos">
        <span className="title" style={{color:"#FFFFFF"}}>Pagamentos altos</span>
        <p>
        Em caso de pagamento acima de cem mil reais, o crédito será realizado apenas 
        se a conta do beneficiário for no Banco do Brasil – exceto quando estejam 
        consignados na ordem judicial digitalmente assinada os dados de conta do 
        beneficiário em outra instituição financeira.
        </p>
        <p>
        Nos casos em que os dados bancários da outra instituição não estejam consignados 
        na ordem judicial ou os valores sejam oriundos de processos físicos ou não possam 
        ser conferidos no site do Tribunal, o Banco indicará agência e horário para 
        atendimento presencial do beneficiário.
        </p>
      </div>
      <div className="atendimento">
        <img className="details blob4" src={blob4} alt="" />
        <img className="details blob3" src={blob3} alt="" />
        <span className="title subtitle">Atendimento</span>
        <p>
        As demandas serão cumpridas pelo banco no horário do expediente de contingência, 
        que é das 10 às 14 horas.
        </p>
      </div>
    </section>
    </div>
  );
}

export default BancoDoBrasil;
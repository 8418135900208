import React from 'react'
import { Title } from '../../../components'
import infoTile from './info'
import './styles.scss'

const EmentarioTED = () => {
    const [indexTile, setIndexTile] = React.useState(null)
    const cards = [
        'ANUIDADES E CONTRIBUIÇÕES À OAB',
        'CONDUTA INCOMPATÍVEL COM A ADVOCACIA',
        'CONTRATO DE HONORÁRIOS',
        'IMPEDIMENTO e INCOMPATIBILIDADE',
        'JUÍZO DE ADMISSIBILIDADE',
        'LOCUPLETAMENTO, RETENÇÃO DE VALORES e PRESTAÇÃO DE CONTAS',
        'PATROCÍNIO DOS INTERESSES DO CLIENTE',
        'PRESCRIÇÃO',
        'PROCURAÇÃO',
        'RETENÇÃO DE AUTOS'
    ]

    const showInfoTile = indexFromTile => {
        if (
            document
                .getElementById('infoTileId')
                .classList.contains('showInfoTile')
        ) {
            if (indexFromTile === indexTile) {
                document
                    .getElementById('infoTileId')
                    .classList.remove('showInfoTile')
            } else {
                setIndexTile(indexFromTile)
            }
        } else {
            setIndexTile(indexFromTile)
            document.getElementById('infoTileId').classList.add('showInfoTile')
        }
    }

    return (
        <div id="ementarioted">
            <div className="section-1">
                <Title
                    textContent="EMENTÁRIO TED"
                    customClass="title-page"
                    link1="A OAB/SE >"
                    link2="&nbsp;PUBLICAÇÕES >"
                    link3="&nbsp;EMENTÁRIO TED"
                    rota1="/"
                    customClassBackground="background-red-horizontal"
                    title
                />
            </div>
            <div className="section-2">
                <section className="presentation">
                    <div className="blueRec" />
                    <p className="smallDescription">
                        O Ementário do Tribunal de Ética e Disciplina
                        encontra-se
                    </p>
                    <p className="dateDescription">
                        Atualizado de <span>01/01/2016</span> até{' '}
                        <span>25/01/2017</span>
                    </p>
                </section>
                <section className="cards">
                    {cards.map((card, index) => (
                        <article
                            key={index}
                            className="tileCard"
                            onClick={() => showInfoTile(index)}
                        >
                            <div className="smallBlueRec" />
                            <p className="cardDesc">{card}</p>
                        </article>
                    ))}
                </section>
                <section className="infoTile" id="infoTileId">
                    <p>{infoTile[indexTile]?.title1}</p>
                    <pre>{infoTile[indexTile]?.text1}</pre>
                    <p>{infoTile[indexTile]?.title2}</p>
                    <pre>{infoTile[indexTile]?.text2}</pre>
                </section>
            </div>
        </div>
    )
}

export default EmentarioTED

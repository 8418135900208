import React from "react";
import { TitleExameOrdem, Pattern } from "../../../components";
import Title from '../../../components/Title/title';

import './styles.scss'

const ExameOrdem = () => {
  return (
    <div id="examedeordem">
      <div className="section-1">
      <Title  
          textContent='EXAME DE ORDEM'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;SECRETARIA >'
          link3='&nbsp;EXAME DE ORDEM'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </div>
      <div className="section-2">
        <div className="pt-5">
          <TitleExameOrdem />
        </div>
        <div className="pt-5">
          <Pattern />
        </div>
      </div>
    </div>
  );
};

export default ExameOrdem;

import './Select.scss';

export default function Select({options, label, onChange, children, ...rest}){
    return(
        <div className="container-select">
			<label className="acessibilidade">{label}</label>
			<select id="select_beneficiarios_ano"
			className="select"
			{...rest}
			onChange={onChange}
			>
                {options.map((item) => (
				    <option className="select" value={item.value}>{item.option}</option>
                ))}
			</select>
			{children}
		</div>
    )
}
import React, {useEffect} from "react";
import {
  Noticias,
  Atalhos,
  Saibamais,
  Acessos,
  Slide,
  CookieBar,
  ModalPopup,
} from "../components";
import {useSelector} from "react-redux";
import {store} from "../store";

const HomePage = () => {
  useEffect(() => {
    console.log("aqui")
    console.log(store.getState().cookie)
  }, [])
  return (
    <>
      <div className="d-flex flex-column min-vh-100 section-1">
        <ModalPopup />
        <div>
          <Slide />
        </div>
        <div className="pt-5">
          <Noticias />
        </div>
        <div className="pt-5">
          <Atalhos />
        </div>
        <div className="pt-5">
          <Saibamais />
        </div>
        <div className="pt-5 pb-5">
          <Acessos />
        </div>
        <div className="bar">
          {
            !store.getState().cookie[0] &&
            <CookieBar/>
          }
        </div>
      </div>
    </>
  );
};

export default HomePage;

import React from "react";
import {
  StyledImage,
  StyledTypography,
  StyledBottomPattern,
  ShowMobile,
  HideMobile,
  StyledImageMobile,
} from "./Acessos.elements";
import advise from "../../assets/images/Home/advise.png";
import caixa from "../../assets/images/Home/caixa@2x.png";
import portal from "../../assets/images/Home/portal-de-oportunidades.jpg";
import convenio from "../../assets/pdfs/convenio.pdf";
import inss from "../../assets/images/Home/inss.jpg";
import banner_banese_acesso from "../../assets/images/Home/banese_refinado.jpg";
import { Link } from "react-router-dom";

import "./styles.scss";

const Acessos = () => {
  return (
    <>
      <HideMobile>
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <StyledTypography>
                <StyledBottomPattern>OUTROS ACESSOS</StyledBottomPattern>
                <StyledBottomPattern regular></StyledBottomPattern>
              </StyledTypography>{" "}
              <div className="organization">
                <div className="row justify-content-center">
                  <a
                    href={convenio}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mb-1"
                  >
                    <StyledImage style={{ marginRight: "15px" }} src={caixa} />
                  </a>

                  <a
                    href="https://advise.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImage style={{ marginRight: "15px" }} src={advise} />
                  </a>
                  <a
                    href="https://oportunidadesoabse.org.br/oportunidades/site/pesquisa/advogado"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StyledImage style={{ marginRight: "15px" }} src={portal} />
                  </a>
                  <Link to="/inss/inss" rel="noopener noreferrer">
                    <StyledImage style={{ marginRight: "15px" }} src={inss} />
                  </Link>
                  <Link to="/oabbanese/oabbanese" rel="noopener noreferrer">
                    <StyledImage
                      style={{ height: "100px" }}
                      src={banner_banese_acesso}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HideMobile>
      <ShowMobile>
        <div className="container">
          <StyledTypography>
            <div className="acessos-mobile">
              <StyledBottomPattern>OUTROS ACESSOS</StyledBottomPattern>
            </div>
          </StyledTypography>{" "}
          <div className="flex">
            <div className="left-half">
              <a
                href="https://advise.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                className="mb-1"
              >
                <img
                  src={advise}
                  className="container-mobile-img"
                  alt="orchestra"
                />
              </a>
            </div>
            <div className="right-half">
              <a href={convenio} target="_blank" rel="noopener noreferrer">
                <img
                  style={{ marginLeft: "5px" }}
                  src={caixa}
                  className="container-mobile-img"
                  alt="violin"
                />
              </a>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <a
              href="https://oportunidadesoabse.org.br/oportunidades/site/pesquisa/advogado"
              target="_blank"
              rel="noopener noreferrer"
              className="inss-portal-mobile"
            >
              <StyledImageMobile style={{ marginRight: "15px" }} src={portal} />
            </a>
            <Link
              to="/inss/inss"
              rel="noopener noreferrer"
              className="inss-portal-mobile"
            >
              <StyledImageMobile style={{ marginRight: "15px" }} src={inss} />
            </Link>
            <Link to="/oabbanese/oabbanese" rel="noopener noreferrer">
              <StyledImageMobile
                style={{ marginRight: "15px" }}
                src={banner_banese_acesso}
              />
            </Link>
          </div>
        </div>
      </ShowMobile>
    </>
  );
};

export default Acessos;

import styled from "styled-components";

export const HideMobile = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ShowMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const StyledImage = styled.img`
  height: 400px;
  width: 50%;
`;

import React from "react";
import "./styles.scss";
import { HideMobile, ShowMobile } from "./Pattern.elements";

const Pattern = () => {
  return (
    <>
      <HideMobile>
        <div className="container mb-3" style={{ textAlign: "center" }}>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="row">
                <div className="col-6 p-0">
                  {" "}
                  <div className="expand_container">
                    <div className="grow-left">
                      <div className="item">
                        <div className="position_item">
                          <a
                            href="https://oab.fgv.br/ "
                            target="_blank" rel="noreferrer"
                            style={{
                              fontSize: "2.7em",
                              fontFamily: "Teko",
                              fontWeight: "normal",
                              textDecoration:"none",
                              color: "#999999",
                            }}
                          >
                            Acompanhamento FGV
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>
              <div className="row">
                <div className="col-6"></div>
                <div className="col-6 p-0">
                  <div className="grow">
                    <a
                    href="https://examedeordem.oab.org.br//"
                    target="_blank" rel="noreferrer"
                      className="exam-text-color pt-5"
                      style={{
                        fontSize: "2.7em",
                        fontFamily: "Teko",
                        fontWeight: "normal",
                        color: "#999999",
                        textDecoration:"none"
                      }}
                    >
                      Exames
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </HideMobile>
      <ShowMobile>
        <div className="container">
          <div className="pb-4">
            <a
            href="https://oab.fgv.br/"
            target="_blank" rel="noreferrer"
              style={{
                backgroundColor: "#02354d",
                color: "#999999",
                height: "140px",
                textAlign: "center",
                fontSize: "2.7em",
                fontFamily: "Teko",
                fontWeight: "normal",
                textDecoration:"none"
              }}
              className="pt-5"
            >
              Acompanhamento FGV
            </a>
          </div>
          <div className="row">
            <div className="col-6">
              {" "}
              <a
              href="https://examedeordem.oab.org.br//"
              target="_blank" rel="noreferrer"
                style={{
                  backgroundColor: "#02354d",
                  textAlign: "center",
                  height: "110px",
                  fontSize: "2.7em",
                  fontFamily: "Teko",
                  fontWeight: "normal",
                  color: "#999999",
                  textDecoration:"none"
                }}
                className="pt-4"
              >
                Exames
              </a>
            </div>
            <div className="col-6"> </div>
          </div>
        </div>
      </ShowMobile>
    </>
  );
};

export default Pattern;

import React, { useState } from 'react'
//import CookieConsent from "react-cookie-consent";

import "./styles.scss"
import detalheEsquerdo from "../../assets/images/Cookies/mask-shapes-left.png"
import detalheDireito from "../../assets/images/Cookies/mask-shapes-right.png"
import detalheEsquerdoMobile from "../../assets/images/Cookies/mask-shapes-2.png"
import detalheDireitoMobile from "../../assets/images/Cookies/mask-shapes-3-mobile.png"
import bolinhas from "../../assets/images/Cookies/mask-shapes-1.png"
import {useDispatch} from "react-redux";

const text = "Utilizamos cookies para garantir a melhor experiência em nosso site. Se você \n continuar a usar esse site, assumiremos que você está satisfeito com ele."

const Cookies = () => {
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();
  if (showModal === false) {
    return null;
  }
  return (
    <div className="modalCookies">
      <div id="content">
        <img className="details left" src={detalheEsquerdo} alt="" />
        <img className="details leftMobile" src={detalheEsquerdoMobile} alt="" />
        <span>{text}</span>
        <div id="container-buttons__Cookies">
          <button id="accept" onClick={() => {
            setShowModal(false)
            dispatch({
              type: "ACEITAR_COOKIE"
            })
          }}>Aceitar</button>
          <button id="reject" onClick={() => setShowModal(false)}>Recusar</button>
        </div>
        <img className="details right" src={detalheDireito} alt="" />
        <img className="details rightMobile" src={detalheDireitoMobile} alt="" />
        <img className="balls" src={bolinhas} alt="" />
      </div>
    </div>
  )
}

export default Cookies

/* eslint-disable */
import { number, string, bool } from "prop-types";
import React,{ useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

export default function Title({  
  fontSize,
  color,
  textContent,
  width,
  maxWidth,
  customClass,
  customClassBackground,
  fontWeigth,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  textTransform,
  opacity,
  textAlign,
  title,
  rota1,
  rota2,
  rota3,
  link1,
  link2,
  link3
}) {

  useEffect(() => {
    const listButtons = document.getElementsByClassName(customClass);
    const listBackground = document.getElementsByClassName(customClassBackground);
  },[])

  const styles = {
    fontSize: fontSize,
    color: color,
    width: width,
    maxWidth: maxWidth,
    fontWeight: fontWeigth,
    marginBottom: marginBottom,
    marginTop:marginTop,
    marginRight:marginRight,
    marginLeft:marginLeft,
    textTransform: textTransform,
    opacity: opacity,
    textAlign: textAlign,
  };

  return (
    <div id="title">
      { title &&  
        <div className="container-title">
          <h1 style={styles} className={customClass}>
          {textContent}
          </h1>
        <div className="rotas">
          <Link to={rota1} className="link-title">{link1} </Link> 
          <Link to={rota2} className="link-title">{link2} </Link>
          <Link to={rota3} className="link-title">{link3}</Link>
        </div>
          <div className={customClassBackground}/>
        </div>
      }
    </div>
  );
}

Title.propTypes = {
  title: bool,
  rota1:string,
  rota2:string,
  rota3:string,
  link1:string,
  link2:string,
  link3:string,
  customClass:string,
  customClassBackground:string,
  fontSize: number,
  fontWeight: number,
  color: string,
  width: number,
  maxWidth: number,
  marginBottom: number,
  marginTop:number,
  marginRight:number,
  marginLeft:number,
  textTransform:string,
  opacity: number,
  textAlign:string,
  textContent: string.isRequired,
};

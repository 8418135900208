import styled from "styled-components";

export const HideMobile = styled.div`
  display: block;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ShowMobile = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

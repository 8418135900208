import prov112006 from '../../../assets/pdfs/sociedade/PROVIMENTO-112-2006.pdf'
import prov1692015 from '../../../assets/pdfs/sociedade/PROVIMENTO-169-2015.pdf'
import prov1702016 from '../../../assets/pdfs/sociedade/Provimento-170_2016.pdf'
import prov1872018 from '../../../assets/pdfs/sociedade/PROVIMENTO-187-2018.pdf'
import modaltsocuni from '../../../assets/pdfs/sociedade/Modelo-de-alteração-de-contrato-de-socieda-unipessoal.docx'
import modaltsocadv from '../../../assets/pdfs/sociedade/modelo-de-alteração-contrato-para-sociedades-de-advogados.docx'
import contsocadv from '../../../assets/pdfs/sociedade/Contrato-Social-de-Sociedade-de-Advogados.docx'
import atosocadv from '../../../assets/pdfs/sociedade/Ato-Consitutivo-de-Sociedade-Unipessoal.docx'
import modcontaso from '../../../assets/pdfs/sociedade/Modelo-de-Contrato-de-Associação.docx'
import moddist from '../../../assets/pdfs/sociedade/Modelo-de-distrato-social-da-Sociedade-de-Advogados-denominada.docx'


export const Data = [
    {
        nome: "Provimento N°112-2006 Atualizado",
        href: prov112006,
    },
    {
        nome: "Modelo de alteração e consolidação do Contrato Social da Sociedade de Advogados denominada",
        href: modaltsocadv,
    },
    {
        nome: "Modelo de Contrato de Associação",
        href: modcontaso,
    },
    {
        nome: "Modelo de Contrato Social para Sociedades de Advogados",
        href: contsocadv,
    },
    {
        nome: "Modelo de Distrato Social da Sociedade de Advogados Denominada",
        href: moddist,
    },
    {
        nome: "Modelo de Ato Constitutivo de Sociedade Unipessoal de Advocacia",
        href: atosocadv,
    },
    {
        nome: "Modelo de Alteração de Contrato de Sociedade Unipessoal",
        href: modaltsocuni,
    },
    {
        nome: "Provimento 169/2015",
        href: prov1692015,
    },
    {
        nome: "Provimento 170/2016",
        href: prov1702016,
    },
    {
        nome: "Provimento 187/2018",
        href: prov1872018,
    },
]
import  React, { useState, useEffect }  from 'react';
import Title from './../../../components/Title/title';

import anexo1 from '../../../assets/arquivos/ANEXO-I-CONVENIO-CAIXAxOAB-Declaracao-do-Advogado-beneficiario-cliente-do-advogado-1.doc';
import anexo2 from '../../../assets/arquivos/ANEXO-II-CONVENIO-CAIXAxOAB-Declaracao-do-advogado-beneficiario-advogado.doc';
import anexo3 from '../../../assets/arquivos/ANEXO-III-Declaracao-de-nao-retencao-IR-RPV.docx';

import bolinhas2 from '../../../assets/images/Alvaras/bolinhas2.svg';
import bolinhas3 from '../../../assets/images/Alvaras/bolinhas3.svg';
import bolinhas4 from '../../../assets/images/Alvaras/bolinhas4.svg';
import blob3 from '../../../assets/images/Alvaras/blob3.svg';
import blob4 from '../../../assets/images/Alvaras/blob4.svg';


import './styles.scss';


const CaixaEconomica = () => {
  const [click,setClick] = useState(false);
  const [id,setId] = useState(0);
  const [flags,setFlags] = useState(false)
  
  function handleClick(id){
    setClick(!click);
    setId(id)
    console.log(id)
  }

  
  const showFlags = () => {
    if (window.innerWidth <= 1040) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  }, []);

  window.addEventListener("resize", showFlags);

  return (
    <div id="caixa-economica">
      <img className="bolinhas5" src={bolinhas2} alt="" />
      <img className="bolinhas6" src={bolinhas4} alt="" />
      <section className="section-1">
        <Title 
          textContent='Caixa econômica Federal'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;Alvarás >'
          link3='&nbsp;Caixa econômica Federal'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      <section className="section-2">
        <div className="title">
        OAB/SE e Caixa Econômica Federal lançam canal para solicitação de recebimento 
        de valores em depósito em conta
        </div>
        <img className="details bolinhas2" src={bolinhas2} alt="" />
      </section>
      <section className="section-3">
        <img className="details blob3"src={blob3} alt="" />
        <img className="details blob4"src={blob4} alt="" />
        <img className="details bolinhas3" src={bolinhas3} alt="" />
        <p>
        Ante aos reflexos do Covid-19, a Ordem dos Advogados do Brasil, em Sergipe, 
        lançou um canal para solicitação de recebimento de valores. Através do meio eletrônico, 
        a advocacia poderá requerer o pagamento de alvarás, precatórios e Requisições de 
        Pequeno Valor (RPVs). 
        </p>
        <p>
        O canal eletrônico é fruto de uma cooperação técnica, firmada entre a OAB/SE 
        e a Caixa Econômica Federal, que visa a efetuação do depósito dos valores em conta, 
        retirando a necessidade de deslocamento de advogados e advogadas a agências bancárias. 
        </p>
        <p>
        A Caixa pagará os valores oriundos de processos eletrônicos que sejam encaminhados 
        exclusivamente pela OAB/SE ao banco. Para tanto, a classe deverá enviar para o e-mail 
        saquecaixa@oabsergipe.org.br alguns documentos e formulários. Confira quais no final da matéria. 
        </p>
        <p>
        Os valores serão creditados ou transferidos para a conta indicada pelo advogado 
        no prazo de até 5 dias úteis (no caso de documentos oriundos do TJ/SE) e 3 dias úteis 
        (nos demais casos) – contados após o recebimento do e-mail pelo banco, sendo devidas 
        todas as taxas e tarifas decorrentes da operação escolhida (TED, DOCs ou TEVs),
        além da retenção de tributos. 
        </p>
        <p>
        Em caso de pagamento de alvarás e precatórios acima de cem mil reais ou alvarás 
        emitidos em processos físicos, após a conferência e validação da documentação hábil, 
        o beneficiário será instado a comparecer a uma agência em data e horário previamente agendados. 
        </p>
        <p>
        “Os alvarás, precatórios e RPVs são o sustento da advocacia. Dessa forma, medidas como essa são 
        impreteríveis. Com toda certeza, esse será um instrumento que muito ajudará a classe nesse momento 
        alarmante e difícil”, afirma o presidente da OAB/SE, Inácio Krauss. 
        </p>
        <p>
        O acordo terá vigência até 15 dias após o término da situação excepcional 
        decorrente das medidas adotadas para o controle da disseminação do Covid-19.
        </p>
      </section>
      <section className="section-4">
        <span className="title subtitle">Documentos</span>
        <div className="documentos">
          <div className="left">
            <p>
            Os e-mails enviados pela OAB/SE ao Banco deverão ser instruídos com a 
            documentação abaixo, em formato PDF e devidamente assinada pelo advogado 
            com assinatura digital no padrão ICP- Brasil, inclusive a indicação dos 
            dados bancários para pagamento exclusivamente em favor dos beneficiários 
            e declaração de responsabilidade do advogado pelas informações prestadas 
            (conforme Anexos I e II assinados com certificação digital). 
            </p>
            <p>
            Caso a documentação enviada esteja incompleta ou divergente, o Banco poderá 
            solicitar a apresentação de mais outros documentos para efetivação do pagamento, 
            hipótese em que o prazo para pagamento será contado a partir da data do recebimento 
            da documentação complementar.
            </p>
          </div>
          {flags && 
          <div className="right-red">
            <span className="title mini-title">
            Para o pagamento de alvarás, precatórios e RPV’s, os advogados deverão 
            enviar para a OAB/SE os seguintes documentos, todos em formato PDF 
            (Os arquivos em PDF só podem ter, na soma total, até 10MB):
            </span>
            <p>
            a) Alvará, precatório ou RPV (assinado eletronicamente, com assinatura digital válida); 
            </p>
            <p>
            b) Formulário conforme modelo dos Anexos I ou II (Termos de Declaração e Indicação de Conta), 
            conforme o caso, onde constará a indicação do banco, agência, tipo de conta, número da conta, 
            nome completo e CPF/CNPJ do titular da conta bancária – que obrigatoriamente será conta individual 
            do próprio beneficiário, e não conjunta – para que seja procedido o crédito/transferência do valor; 
            </p>
            <p>
            c) E ainda, os seguintes documentos conforme cada uma das situações adiante descritas:
            </p>
            <div className={click && id === 1 ? "container-info show"  : "container-info"} onClick={() => handleClick(1)}>
              <p>
              c.1) Se o beneficiário for PESSOA FÍSICA (o próprio advogado):
              </p>
              <div className={ click && id === 1 ? "infos show"  : "infos"}>
                <p>c.1) Se o beneficiário for PESSOA FÍSICA (o próprio advogado):</p> 
                <p>c.1.1) Carteira da OAB (frente e verso)</p> 
                <p>c.1.2) CPF</p> 
                <p>c.1.3) Comprovante de residência</p>
              </div>
            </div>
            <div className={click && id === 2 ? "container-info show"  : "container-info"} onClick={() => handleClick(2)}>
              <p>
              c.2) Se o beneficiário for PESSOA FÍSICA (cliente do advogado):
              </p>
              <div className={ click && id === 2  ? "infos show"  : "infos"}>
                <p>c.2) Se o beneficiário for PESSOA FÍSICA (cliente do advogado):</p>
                <p>c.2.1) RG e CPF ou CNH (frente e verso)</p> 
                <p>c.2.2) Comprovante de residência</p> 
                <p>c.2.3) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 3 ? "container-info show"  : "container-info"} onClick={() => handleClick(3)}>
              <p>
              c.3) Se o beneficiário for PESSOA JURÍDICA:
              </p>
              <div className={ click && id === 3 ? "infos show"  : "infos"}>
              <p> c.3) Se o beneficiário for PESSOA JURÍDICA:</p>
              <p>c.3.1) Sociedade de advogados:</p>  
              <p>c.3.1.a) Ato constitutivo (ou última consolidação) e alterações posteriores</p>  
              <p>c.3.1.b) Certidão da OAB/SE atestando a data do último arquivamento e administração;</p>  
              <p>c.3.1.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e </p> 
              <p>c.3.1.d) Comprovante de residência do(s) representante(s) legal(is)</p>  
              <p>c.3.1.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 4 ? "container-info show"  : "container-info"} onClick={() => handleClick(4)}>
              <p>
              c.3.2) Sociedade limitada (LTDA):
              </p>
              <div className={ click && id === 4  ? "infos show"  : "infos"}>
                <p>c.3.2) Sociedade limitada (LTDA):</p>
                <p>c.3.2.a) Contrato social e alterações posteriores OU última alteração social consolidada e alterações posteriores,</p> 
                <p>c.3.2.b) certidão simplificada da JUCESE;</p> 
                <p>c.3.2.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e</p>
                <p>c.3.2.d) Comprovante de residência do(s) representante(s) legal(is)</p>
                <p> c.3.2.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 5 ? "container-info show"  : "container-info"} onClick={() => handleClick(5)}>
              <p>
              c.3.3) Sociedade Anônima (S.A.):
              </p>
              <div className={ click && id === 5  ? "infos show"  : "infos"}>
                <p>c.3.3) Sociedade Anônima (S.A.):</p>
                <p>c.3.3.a) Estatuto social e alterações posteriores </p> 
                <p>c.3.3.b) Ata de eleição da diretoria</p> 
                <p>c.3.3.c) Certidão simplificada da JUCESE;</p>
                <p>c.3.3.d) RG e CPF, ou CNH do(s) representante(s) legal(is), e </p>
                <p>c.3.3.e) Comprovante de residência do(s) representante(s) legal(is)</p>
                <p>c.3.3.f) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 6 ? "container-info show"  : "container-info"} onClick={() => handleClick(6)}>
              <p>
              c.3.4) Condomínio:
              </p>
              <div className={ click && id === 6  ? "infos show"  : "infos"}>
                <p>c.3.4) Condomínio:</p>
                <p>c.3.4.a) Convenção de condomínio </p> 
                <p>c.3.4.b) Ata de eleição do síndico/representante legal </p> 
                <p>c.3.4.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e</p>
                <p>c.3.4.d) Comprovante de residência do(s) representante(s) legal(is) </p>
                <p>c.3.4.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className="anexos">
              <p>
                Confira o anexo I do convênio CaixaxOAB 
                <a href={anexo1}target="_blank" rel="noreferrer" > aqui.</a> <br />
                Confira o anexo II do convênio CaixaxOAB 
                <a href={anexo2}target="_blank" rel="noreferrer" > aqui.</a>  <br />
                Confira o anexo III do convênio CaixaxOAB 
                <a href={anexo3}target="_blank" rel="noreferrer" > aqui.</a>
              </p>
            </div>
          </div>}
        </div>
      </section>
      {!flags && 
      <section className="section-41">
        <div className="right-red">
            <span className="title mini-title">
            Para o pagamento de alvarás, precatórios e RPV’s, os advogados deverão 
            enviar para a OAB/SE os seguintes documentos, todos em formato PDF 
            (Os arquivos em PDF só podem ter, na soma total, até 10MB):
            </span>
            <p>
            a) Alvará, precatório ou RPV (assinado eletronicamente, com assinatura digital válida); 
            </p>
            <p>
            b) Formulário conforme modelo dos Anexos I ou II (Termos de Declaração e Indicação de Conta), 
            conforme o caso, onde constará a indicação do banco, agência, tipo de conta, número da conta, 
            nome completo e CPF/CNPJ do titular da conta bancária – que obrigatoriamente será conta individual 
            do próprio beneficiário, e não conjunta – para que seja procedido o crédito/transferência do valor; 
            </p>
            <p>
            c) E ainda, os seguintes documentos conforme cada uma das situações adiante descritas:
            </p>
            <div className={click && id === 1 ? "container-info show"  : "container-info"} onClick={() => handleClick(1)}>
              <p>
              c.1) Se o beneficiário for PESSOA FÍSICA (o próprio advogado):
              </p>
              <div className={ click && id === 1 ? "infos show"  : "infos"}>
                <p>c.1) Se o beneficiário for PESSOA FÍSICA (o próprio advogado):</p> 
                <p>c.1.1) Carteira da OAB (frente e verso)</p> 
                <p>c.1.2) CPF</p> 
                <p>c.1.3) Comprovante de residência</p>
              </div>
            </div>
            <div className={click && id === 2 ? "container-info show"  : "container-info"} onClick={() => handleClick(2)}>
              <p>
              c.2) Se o beneficiário for PESSOA FÍSICA (cliente do advogado):
              </p>
              <div className={ click && id === 2  ? "infos show"  : "infos"}>
                <p>c.2) Se o beneficiário for PESSOA FÍSICA (cliente do advogado):</p>
                <p>c.2.1) RG e CPF ou CNH (frente e verso)</p> 
                <p>c.2.2) Comprovante de residência</p> 
                <p>c.2.3) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 3 ? "container-info show"  : "container-info"} onClick={() => handleClick(3)}>
              <p>
              c.3) Se o beneficiário for PESSOA JURÍDICA:
              </p>
              <div className={ click && id === 3 ? "infos show"  : "infos"}>
              <p> c.3) Se o beneficiário for PESSOA JURÍDICA:</p>
              <p>c.3.1) Sociedade de advogados:</p>  
              <p>c.3.1.a) Ato constitutivo (ou última consolidação) e alterações posteriores</p>  
              <p>c.3.1.b) Certidão da OAB/SE atestando a data do último arquivamento e administração;</p>  
              <p>c.3.1.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e </p> 
              <p>c.3.1.d) Comprovante de residência do(s) representante(s) legal(is)</p>  
              <p>c.3.1.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 4 ? "container-info show"  : "container-info"} onClick={() => handleClick(4)}>
              <p>
              c.3.2) Sociedade limitada (LTDA):
              </p>
              <div className={ click && id === 4  ? "infos show"  : "infos"}>
                <p>c.3.2) Sociedade limitada (LTDA):</p>
                <p>c.3.2.a) Contrato social e alterações posteriores OU última alteração social consolidada e alterações posteriores,</p> 
                <p>c.3.2.b) certidão simplificada da JUCESE;</p> 
                <p>c.3.2.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e</p>
                <p>c.3.2.d) Comprovante de residência do(s) representante(s) legal(is)</p>
                <p> c.3.2.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 5 ? "container-info show"  : "container-info"} onClick={() => handleClick(5)}>
              <p>
              c.3.3) Sociedade Anônima (S.A.):
              </p>
              <div className={ click && id === 5  ? "infos show"  : "infos"}>
                <p>c.3.3) Sociedade Anônima (S.A.):</p>
                <p>c.3.3.a) Estatuto social e alterações posteriores </p> 
                <p>c.3.3.b) Ata de eleição da diretoria</p> 
                <p>c.3.3.c) Certidão simplificada da JUCESE;</p>
                <p>c.3.3.d) RG e CPF, ou CNH do(s) representante(s) legal(is), e </p>
                <p>c.3.3.e) Comprovante de residência do(s) representante(s) legal(is)</p>
                <p>c.3.3.f) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className={click && id === 6 ? "container-info show"  : "container-info"} onClick={() => handleClick(6)}>
              <p>
              c.3.4) Condomínio:
              </p>
              <div className={ click && id === 6  ? "infos show"  : "infos"}>
                <p>c.3.4) Condomínio:</p>
                <p>c.3.4.a) Convenção de condomínio </p> 
                <p>c.3.4.b) Ata de eleição do síndico/representante legal </p> 
                <p>c.3.4.c) RG e CPF, ou CNH do(s) representante(s) legal(is), e</p>
                <p>c.3.4.d) Comprovante de residência do(s) representante(s) legal(is) </p>
                <p>c.3.4.e) Carteira da OAB do advogado que cadastrar o alvará (frente e verso)</p>
              </div>
            </div>
            <div className="anexos">
              <p>
                Confira o anexo I do convênio CaixaxOAB aqui. <br />
                Confira o anexo II do convênio CaixaxOAB aqui.  <br />
                Confira o anexo III do convênio CaixaxOAB aqui.
              </p>
            </div>
          </div>
      </section>
      }
      <section className="section-5">
        <div className="prazos">
        <img className="details blob3"src={blob3} alt="" />
        <img className="details blob4"src={blob4} alt="" />
        <img className="details bolinhas3" src={bolinhas3} alt="" />
          <span className="title mini-title">
            Prazos
          </span>
          <p>
          Os valores dos alvarás, RPV e Precatório serão creditados ou transferidos 
          para a conta indicada pelo advogado na forma deste instrumento no prazo de 
          até 5 (cinco) dias úteis no caso de documentos oriundos do TJ/SE, e 3 (três) 
          dias úteis nos demais casos, contados após o recebimento do e-mail pelo BANCO, 
          sendo devidas todas as taxas e tarifas decorrentes da operação escolhida (TED, DOCs, TEVs), 
          além da retenção de tributos na forma da legislação competente.
          </p>
          <p>
          Quando o beneficiário for ISENTO de Imposto de Renda ou o valor for NÃO TRIBUTÁVEL, 
          além dos documentos acima relacionados, será necessário encaminhar também a 
          Declaração de Não Retenção (Anexo III) devidamente preenchida e assinada digitalmente.
          </p>
        </div>
      </section>
    </div>
  );
}

export default CaixaEconomica;

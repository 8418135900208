/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

const mapStyles = {
    width: '100%',
    height: '350px',
    position: 'relative'
}

const container = {
    width: '100%',
    height: '350px',
    position: 'relative'
}

function MapContainer(props) {
    const { google } = props
    return (
        <Map
            containerStyle={container}
            google={google}
            zoom={18}
            style={mapStyles}
            initialCenter={{
                lat: -10.917382,
                lng: -37.0477891
            }}
        >
            <Marker
                name="OAB - Sergipe"
                position={{
                    lat: -10.92322,
                    lng: -37.04502
                }}
            />
        </Map>
    )
}
export default GoogleApiWrapper(props => ({
    apiKey: 'AIzaSyDoE-X59FwQ9Z1D4iy9QeZjpa6BxHcQ510'
}))(MapContainer)

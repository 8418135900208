import React,{ useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { api } from '../../../services/api.js';
import compartilhar from '../../../assets/images/Noticias/button-share-news.svg';
import email from '../../../assets/images/Noticias/icon-email.svg';
import telegram from '../../../assets/images/Noticias/icon-telegram.svg';
import whatsapp from '../../../assets/images/Noticias/icon-whatsapp.svg';
import imprimir from '../../../assets/images/Noticias/icon-print.svg';
import facebook from '../../../assets/images/Noticias/icon-facebook.svg';
import instagram from '../../../assets/images/Noticias/icon-instagram.svg';
import twitter from '../../../assets/images/Noticias/icon-twitter.svg';
import CarrosselNoticias from './CarrosselNoticias';

function PaginaInterna() {

  const id = useParams().idNoticia;
  const [titulo, setTitulo] = useState();
  const [data, setData] = useState();
  const [imagem, setImagem] = useState();
  const [openRedesSociais, setOpenRedesSociais] = useState(false);
  const dataPublicacao = new Date(data);
  const dataBR = dataPublicacao.toLocaleDateString("pt-BR")
  const hora = dataPublicacao.getHours() + ':' + dataPublicacao.getMinutes();
  
  const [noticias, setNoticias] = useState([])

  const [paginaAtual, setPaginaAtual] = useState(1)

    useEffect(()=>{
      setNoticias([])
       async function blogPrincipal(){
            await api.get(`/posts/${id}?_embed`).then((res)=>
                {console.log(res.data)
                console.log(res.data.length)
                setNoticias(res.data)
                  setImagem()
              }
            ).catch((err)=>console.log(err))
       }
       blogPrincipal()
    },[id])

  useEffect(() => {
    setTitulo(noticias?.acf?.titulo_da_noticia ? noticias?.acf?.titulo_da_noticia : noticias?.title?.rendered);
    setData(noticias?.acf?.data_da_publicacao ? noticias?.acf?.data_da_publicacao : noticias?.date);
    setImagem(noticias?._embedded && noticias?._embedded["wp:featuredmedia"]["0"]
    ?.media_details.sizes?.full.source_url)
    //document.getElementById('texto-noticia').innerHTML = noticias?.acf?.texto_da_noticia === undefined ? 'Carregando...' : noticias?.acf?.texto_da_noticia;
    document.getElementById('texto-noticia').innerHTML = noticias?.content?.rendered === undefined ? 'Carregando...' : noticias?.content?.rendered;
  }, [noticias])

  return (
    <div id="pagina-interna">
      { noticias?.date === undefined ?
        <p/>
      : <>
      <div id="links-paginainterna">
        <div id="background-link"></div>
        <Link to="/blog/principal" target="_blank">Blog </Link> {` > `}
        <p>
          {
            titulo?.split(new RegExp(`&#8211;`, "i"))
            .join("")
            .slice(0, 60)
          }
          {titulo?.length > 60 && <>...</>}
        </p>
      </div>
      <div id="titulo-noticia">
        <div id="barra" />
        {titulo?.split(new RegExp(`&#8211;`, "i")).join("")}
      </div>
      <div id="data-noticia">
        Atualizado em {dataBR} às {hora}
        <br/>
        <br/>
        <div id="container-compartilhar">
          <a onClick={() => setOpenRedesSociais(!openRedesSociais)}>
            <img id="share" src={compartilhar} alt="compartilhar"/>
          </a>
          <div id="container-redes-sociais" style={{display: !openRedesSociais && "none"}}>
            <a target="_blank" href={`https://api.whatsapp.com/send?text=${window.location}`}>
              <img className="redes-sociais" src={whatsapp} alt="compartilhar no whatsapp"/>
            </a>
            <a target="_blank" href={`https://telegram.me/share/url?url=${window.location}`}>
              <img className="redes-sociais" src={telegram} alt="compartilhar no telegram"/>
            </a>
            <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}>
              <img className="redes-sociais" src={facebook} alt="compartilhar no facebook"/>
            </a>
            <a target="_blank" href={`https://twitter.com/intent/tweet?text=${window.location}`}>
              <img className="redes-sociais" src={twitter} alt="compartilhar no twitter"/>
            </a>
            <a target="_blank" href={`mailto: ?subject=${noticias?.acf?.titulo_da_noticia ? noticias?.acf?.titulo_da_noticia : noticias?.title?.rendered}
              &body=Leia a notícia no site: ${window.location}`}>
              <img className="redes-sociais" src={email} alt="compartilhar no email"/>
            </a>
            <a onClick={() => window.print()}>
              <img className="redes-sociais" src={imprimir} alt="imprimir"/>
            </a>
          </div>
        </div>
      </div>
      <div id="imagem-noticia">
        <img src={imagem} alt="imagem" />
      </div>
      </>}
      <div id="texto-noticia" className="container__link-em-bg-branco" />
      <div className='ui two buttons'>
        <button className="button-retornar">
          <Link to="/blog/principal">
            <center>Retornar as Notícias</center>
          </Link>
        </button>
      </div>
      <CarrosselNoticias />
    </div>
    );
}

export default PaginaInterna;
import styled, { css } from "styled-components";

export const StyledTypography = styled.p`
  color: #444444;
  font-size: 1.2em;
  font-weight: bold;
`;

export const StyledBottomPattern = styled.span`
  border-bottom: 3px solid #aa4f50;
  padding-bottom: 5px;
  font-family: Teko;

  ${(props) =>
    props.regular &&
    css`
      border-bottom: none;
      padding-bottom: none;
    `}
`;

export const HideMobile = styled.div`
  display: block;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ShowMobile = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
`;

export const Items = styled.div`
  &:hover {
    color: pink;
  }
`;

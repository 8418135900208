import React from 'react';
import './styles.scss';
import { Carrossel, Title, Abas } from '../../../components';

import Presidente from './Presidente';
import Conselheiro from './Conselheiro';
import Diretoria from "./Diretorias/ESA/Diretoria";
import CoordenadoriasCientificas from "./Diretorias/ESA/CoordenadoriasCientificas";
import CoordenadoriasEspecificas from "./Diretorias/ESA/CoordenadoriasEspecificas";
import CAA from "./Diretorias/CAA";
import { diretoriaAtual } from "./diretoria";

const Sobre = () => {

  const TabOptions3 = [
    { id: 0, opcao: 'DIRETORIA' },
    { id: 1, opcao: 'COORDENADORIAS CIENTÍFICAS' },
    { id: 2, opcao: 'COORDENADORIAS ESPECÍFICAS' },
  ];

  const TabOptions2 = [
    { id: 0, opcao: 'ESA' },
    { id: 1, opcao: 'OAB' },
    { id: 2, opcao: 'CAA/SE' },
  ];

  const TabOptions1 = [
    { id: 0, opcao: "PRESIDENTE", },
    { id: 1, opcao: "DIRETORIAS", },
    { id: 2, opcao: "CONSELHEIROS", },
  ]

  const diretoria = ({ className }) => (
    <div className={className}>
      <Diretoria />
    </div>
  );

  const coordenadoriasCientificas = ({ className }) => (
    <div className={className}>
      <CoordenadoriasCientificas />
    </div>
  );

  const coordenadoriasEspecificas = ({ className }) => (
    <div className={className}>
      <CoordenadoriasEspecificas />
    </div>
  );

  const Opcao2_2 = ({ open, className }) => (
    <div className={className}>
      <Carrossel aberto={open} diretores={diretoriaAtual} />
    </div>
  );

  const Opcao2_1 = ({ className }) => (
    <div className={className}>
      <Abas className={className} options={TabOptions3} menuPadding="20%" renderOptions={[diretoria, coordenadoriasCientificas, coordenadoriasEspecificas]} textColor="#AA4F50" />
    </div>
  );

  const Opcao2_3 = ({ className }) => (
    <div className={className}>
      <CAA />
    </div>
  );

  const Opcao1_1 = ({ open, className }) => (
    <div className={className}>
      <Presidente aberto={open} className={className} />
    </div>
  );

  const Opcao1_2 = ({ className }) => (
    <div className={className}>
      <Abas
        className={className}
        options={TabOptions2}
        menuPadding='40%'
        renderOptions={[Opcao2_1, Opcao2_2, Opcao2_3]}
      />
    </div>
  );

  const Opcao1_3 = ({ className }) => (
    <div className={className}>
      <Conselheiro />
    </div>
  );

  return (
    <div id='sobre-oab'>
      <section id='section-1'>
        <Title
          textContent='SOBRE A OAB/SE'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;INSTITUCIONAL >'
          link3='&nbsp;SOBRE A OAB/SE'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      <section id='section-2'>
        <Abas
          options={TabOptions1}
          menuPadding='35%'
          renderOptions={[Opcao1_1, Opcao1_2, Opcao1_3]}
        />
      </section>
    </div>
  );
};

export default Sobre;

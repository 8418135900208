import React, {useEffect, useState} from 'react'

import { Link } from 'react-router-dom'
import lgpd from '../../../assets/legislacoes/cartilhas/Cartilha_LGPD_2021.pdf'
import cartilha_Antirracista from '../../../assets/legislacoes/cartilhas/Cartilha_Antirracista.pdf'
import cartilha_seg from '../../../assets/legislacoes/cartilhas/Cartilha_Seguranca.pdf'
import { BiSearchAlt2 } from 'react-icons/bi'
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs'
import { api } from '../../../services/api.js'
import './styles.scss'

const BlogPrincipal = () => {
    
    const [noticias, setNoticias] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [filterednews, setFilteredNews] = useState(noticias);
    const [text, setText] = useState('');

    useEffect(()=>{
       async function blogPrincipal(){
            await api.get(`/posts?_embed&page=${paginaAtual}`)
            .then((res)=> {
                setNoticias(res.data)
                setFilteredNews(res.data)
            })
            .catch((err)=>console.log(err))
       }
       blogPrincipal()
    },[paginaAtual])

    const pagesNumbers = [0, 1, 2]
    const pagesNumbersMobile = [0, 1]

    useEffect(() => {
        function filtrarNoticias(){
            if(noticias !== undefined){
                const news = noticias.filter(item => (
                    item.title.rendered.toLowerCase().indexOf(text.toLowerCase()) !== -1
                    || item.content.rendered.toLowerCase().indexOf(text.toLowerCase()) !== -1
                    || item.slug.toLowerCase().indexOf(text.toLowerCase()) !== -1
                ))
                setFilteredNews(news)
            }
        }
        filtrarNoticias()
    }, [text])

    return (
        <div id="blogPrincipal">
            <div className="searchBar">
                <BiSearchAlt2 />
                <input
                    placeholder="O está buscando ?"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                />
            </div>
            <div className="blogStrip">
                <p>BLOG</p>
            </div>
            <div className="generalContainer">
                <section className="showcases">
                    <div className="showcasesInner">
                        <a href={lgpd} target="blank" rel="noopener noreferrer"
                        className="anuncioLateral"
                        >
                            {' '}
                            <article className="eachShowcase">
                                {/*  <img src={seguranca} alt="cartilha de segurança" /> */}
                            </article>
                        </a>
                        <a
                            href={cartilha_Antirracista}
                            target="blank"
                            rel="noopener noreferrer"
                            className="anuncioCentral"
                        >
                            {' '}
                            <article className="eachShowcase">
                                {/*  <img src={bioseg} alt="cartilha coronavirus" /> */}
                            </article>
                        </a>

                        <a
                            href={cartilha_seg}
                            target="blank"
                            rel="noopener noreferrer"
                            className="anuncioLateral"
                        >
                            <article className="eachShowcase">
                                {/*  <img src={inss} alt="inss" /> */}
                            </article>
                        </a>
                    </div>
                </section>
                
                <section className="news">
                    <div className="newsCol2">
                        {filterednews.map((noticia, index) => (
                            <article className="newsIndividual">
                                <Link to={`/blog/${noticia.id}`} style={{flexDirection: index %2 !== 0 && "row-reverse"} }>
                                    {/*<div
                                        style={{
                                            backgroundImage: `url(${noticia?.acf?.imagem_da_noticia?.url ? noticia?.acf?.imagem_da_noticia?.url :
                                                noticia._embedded["wp:featuredmedia"]["0"]
                                                .media_details.sizes.full.source_url
                                            })`
                                        }}
                                        className="newsIndividualImg"
                                    ></div>*/}
                                    <img className="newsIndividualImg" src={noticia?.acf?.imagem_da_noticia?.url ? noticia?.acf?.imagem_da_noticia?.url :
                                                noticia._embedded["wp:featuredmedia"]["0"]
                                                .media_details.sizes.full.source_url}/>
                                    <div className="newsIndividualText">
                                        <p>
                                            {
                                                noticia?.acf?.titulo_da_noticia
                                                ?noticia?.acf?.titulo_da_noticia
                                                :noticia.title.rendered
                                                    .split(new RegExp(`&#8211;`, "i"))
                                                    .join("")
                                            }
                                        </p>
                                        <p>
                                            {
                                            noticia?.acf?.data_da_publicacao
                                            ?new Date(noticia?.acf?.data_da_publicacao)
                                            :new Date(noticia.date).toLocaleDateString("pt-BR")
                                            }
                                        </p>
                                        <p>
                                            {
                                            noticia?.acf?.descricao_da_noticia
                                            ?noticia?.acf?.descricao_da_noticia
                                            :noticia?.content?.rendered
                                                .split(new RegExp(`<.*?>`, "i"))
                                                .join("")
                                                .slice(0, 60)
                                            }...
                                        </p>
                                    </div>
                                </Link>
                            </article>
                        ))}
                    </div>
                </section>
                
            </div>
            <div className="pagination">
                <div className="arrowLeft">
                    <BsArrowBarLeft onClick={()=> paginaAtual > 1 && setPaginaAtual(paginaAtual-1)} />
                </div>
                <div className="pagesNumbers">
                    {pagesNumbers.slice(0,8).map((page, index) => (
                        <p style={{backgroundColor: index===0 && "#02283a", color: index===0 && "white"}} onClick={()=>setPaginaAtual(index+paginaAtual)} key={page}>
                            {index+paginaAtual}
                        </p>
                    ))}
                </div>
                <div className="pagesNumbersMobile">
                    {pagesNumbersMobile.map((page, index) => (
                        <p style={{backgroundColor: index===0 && "#02283a", color: index===0 && "white"}} onClick={()=>setPaginaAtual(index+paginaAtual)} key={page}>
                            {index + paginaAtual}
                        </p>
                        
                    ))}
                </div>
                <div className="arrowRight">
                    <BsArrowBarRight onClick={()=>setPaginaAtual(paginaAtual+1)} />
                </div>
            </div>
        </div>
    )
}

export default BlogPrincipal

import React from "react";
import { HideMobile, ShowMobile } from "./Title.elements";

import './styles.scss'

const Title = () => {
  return (
    <>
      <HideMobile>
        <div id="exameOrdem" class="container">
          <div className="row-info">
            <div className="container-back" style={{ fontSize: "0.9em" }}>
              <p style={{ fontWeight: "bold" }}>
                Documentos necessários para a retirada do Certificado de
                Aprovação no Exame de Ordem:
              </p>
              <p>1. Documento de Identidade (RG) original e cópia</p>
              <p>2. C.P.F. original e cópia</p>
              <p>3. Título de Eleitor original e cópia</p>
              <p>
                4. Diploma original e cópia ou Declaração da Faculdade
                informando o período cursado.
              </p>
            </div>
            <div className="title">
              <h1>
                EXAME <br /> DE ORDEM
              </h1>
            </div>
          </div>
        </div>
      </HideMobile>
      <ShowMobile>
        <div id="exameOrdem" style={{ alignItems:"center" }}>
          <span>
            EXAME <br /> DE ORDEM
          </span>
          <div className="container-mobile">
          <p style={{ fontWeight: "bold" }} className="pt-3">
            Documentos necessários para a retirada do Certificado de Aprovação
            no Exame de Ordem:
          </p>
          <p>1. Documento de Identidade (RG) original e cópia</p>
          <p>2. C.P.F. original e cópia</p>
          <p>3. Título de Eleitor original e cópia</p>
          <p>
            4. Diploma original e cópia ou Declaração da Faculdade informando o
            período cursado.
          </p>
          </div>
        </div>
      </ShowMobile>
    </>
  );
};

export default Title;

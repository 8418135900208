import React from "react";
import banner_banese_page from "../../assets/images/banese_banese_page.png";
import "./styles.scss";

const OabBanese = () => {
  return (
    <div>
      <center>
        <p className="text-banner-page">OAB/SE e BANESE Card</p>
        <a
          href="https://drive.google.com/drive/folders/14Pc_69bv_1OXX6Fp6lxX__A9_M0cWP3C"
          target="_blank"
        >
          <img className="banner-page" src={banner_banese_page} />
        </a>
      </center>
    </div>
  );
};
export default OabBanese;

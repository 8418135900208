const CARDS = [
    {
        descricao: "2 via de cartão com chip",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "50.00"
        
    },
    {
        descricao: "2 via de cartão com chip + alteração de nome",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "50.00"
        
    },
    {
        descricao: "2 via de carteira (brochura)",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "50.00"
        
    },
    {
        descricao: "2 via de carteira (brochura) + alteração de nome",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "50.00"
        
    },
    {
        descricao2: "2 Via - Taxa",
        descricao: "Carteira e Cartão",
        descricao3: "",
        descricao4: "",
        value: "90.00"
        
    },
    {
        descricao2: "2 Via - Taxa",
        descricao: "Alteração no registro de sociedade/aditivo",
        descricao3: "",
        descricao4: "",
        value: "210.00"    
    },
    {
        descricao2: "Taxa",
        descricao: "Distrato social",
        descricao3: "",
        descricao4: "",
        value: "210.00"    
    },
    {
        descricao2: "Taxa",
        descricao: "Uso do auditório",
        descricao3: "ARACAJU",
        descricao4: "Dois turnos",
        value: "3200.00"    
    },
    {
        descricao2: "Taxa",
        descricao: "Uso do auditório",
        descricao3: "ARACAJU",
        descricao4: "Turno",
        value: "2100.00"    
    },
    {
        descricao2: "Taxa",
        descricao: "Uso do auditório",
        descricao3: "ITABAIANA E LAGARTO",
        descricao4: "Turno",
        value: "450.00"    
    },
    {
        descricao2: "Taxa",
        descricao: "Uso do auditório",
        descricao3: "ITABAIANA E LAGARTO",
        descricao4: "Dois turnos",
        value: "850.00"    
    },
    {
        descricao2: "Taxa Certidão",
        descricao: "Pessoa física",
        descricao3: "",
        descricao4: "",
        value: "35.00"    
    },
    {
        descricao2: "Taxa Certidão",
        descricao: "Pessoa jurídica",
        descricao3: "",
        descricao4: "",
        value: "70.00"    
    },
    {
        descricao2: "Taxa de custeio",
        descricao: "Uso do auditório",
        descricao3: "ARACAJU",
        descricao4: "",
        value: "530.00"    
    },
    {
        descricao2: "Taxa de custeio",
        descricao: "Uso do auditório",
        descricao3: "ITABAIANA E LAGARTO",
        descricao4: "Turno",
        value: "150.00"    
    },
    {
        descricao2: "Taxa de custeio",
        descricao: "Uso do auditório",
        descricao3: "ITABAIANA E LAGARTO",
        descricao4: "Turno",
        value: "150.00"    
    },
    {
        descricao2: "Taxa custeio",
        descricao: "Uso do plenário",
        descricao3: "",
        descricao4: "",
        value: "270.00"    
    },
    {
        descricao2: "Taxa de inscrição definitivo",
        descricao: "Inclui carteira e cartão",
        descricao3: "",
        descricao4: "",
        value: "280.00"    
    },
    {
        descricao2: "Taxa de inscrição estagiário",
        descricao: "Inclui cartão",
        descricao3: "",
        descricao4: "",
        value: "115.00"    
    },
    {
        descricao2: "Taxa de inscrição por transferência",
        descricao: "Inclui carteira e cartão",
        descricao3: "",
        descricao4: "",
        value: "425.00"    
    },
    {
        descricao2: "Taxa de inscrição suplementar",
        descricao: "Inclui cartão",
        descricao3: "",
        descricao4: "",
        value: "425.00"    
    },
    {
        descricao: "Taxa de registro de sociedade unipessoal",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "450.00"    
    },
    {
        descricao: "Taxa de inscrição plúrima",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "900.00"    
    },
    {
        descricao2: "Taxa de uso do plenário",
        descricao: "Turno",
        descricao3: "",
        descricao4: "",
        value: "630.00"    
    },
    {
        descricao2: "Taxa de uso do plenário",
        descricao: "Dois turnos",
        descricao3: "",
        descricao4: "",
        value: "1100.00"    
    },
    {
        descricao: "Taxa registro de advogado associado",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "210.00"    
    },
    {
        descricao: "Taxa de distrato de associação de advogado associado",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "210.00"    
    },
    {
        descricao: "Taxa de listagem atualizada de advogados inscritos",
        descricao2: "",
        descricao3: "",
        descricao4: "",
        value: "4250.00"    
    },

]

export default CARDS;
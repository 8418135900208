import resolucao2021 from "../../../assets/resolucoes/resolucao-anuidade-2021.pdf"
import resolucao2020 from "../../../assets/resolucoes/resolucao-anuidade-2020.pdf"
import cfoab from "../../../assets/resolucoes/resolucao-13-19-cfoab.pdf"
import resolucao2019 from "../../../assets/resolucoes/resolucao-2019.pdf"
import resolucao2017 from "../../../assets/resolucoes/resolucao-2017.pdf"
import resolucao2018 from "../../../assets/resolucoes/resolucao-2018.pdf"
import resolucao2016 from "../../../assets/resolucoes/resolução-anuidade-2016.docx"

const data = [
    {
        nome: "ANUIDADE 2021",
        value: "1",
        arquivo: resolucao2021,
        label: "ANUIDADE 2021"
    },
    {
        nome: "ANUIDADE 2020",
        value: "2",
        arquivo: resolucao2020,
        label: "ANUIDADE 2020"
    },
    {
        nome: "ANUIDADE 2019",
        value: "3",
        arquivo: resolucao2019,
        label: "ANUIDADE 2019"
    },
    {
        nome: "ANUIDADE 2018",
        value: "4",
        arquivo: resolucao2018,
        label: "ANUIDADE 2018"
    },
    {
        nome: "ANUIDADE 2017",
        value: "5",
        arquivo: resolucao2017,
        label: "ANUIDADE 2017"
    },
    {
        nome: "ANUIDADE 2016",
        value: "6",
        arquivo: resolucao2016,
        label: "ANUIDADE 2016"
    },
    {
        nome: "13/2019 DO CFOAB",
        value: "7",
        arquivo: cfoab,
        label: "13/2019 DO CFOAB"
    },
]

export default data;
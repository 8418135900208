import React from 'react';
import { Title } from '../../../components';

import './styles.scss';

const Editais = () => {
  return(
    <div id="editais">
      <section className="section-1" >
        <Title 
        textContent='Editais'
        customClass='title-page'
        link1='A OAB/SE >'
        link2='&nbsp;publicações>'
        link3='&nbsp;Editais'
        rota1='/'
        customClassBackground='background-red-horizontal'
        title
        />
      </section>
      <section className="section-2">
        <div className="arquivos">
          <span className="title mini-title">2016</span>
          <span className="title retangle">Revista ESA</span>
          <p>
            1. Edital N001/2016 – REVISTA ESA 
            <a href="https://oabsergipe.org.br/wp-content/uploads/2016/06/EDITALN%C2%BA0012016.pdf" target="_blank" rel="noreferrer"> Baixar</a>. 
          </p>
          <p>
            2. Aditamento do Edital 001/2016 
            <a href="https://oabsergipe.org.br/wp-content/uploads/2016/09/Aditamento_01_edital_01_revistaesa.pdf" target="_blank" rel="noreferrer"> Baixar</a>.
          </p>
          <span className="title mini-title margin">2016</span>
          <span className="title retangle">CONCURSO CULTURAL DE DESENHO PINTURA E POESIA</span>
          <p>
            1. Aditivo do Concurso Cultural de Desenho Pintura e Poesia
            <a href="https://oabsergipe.org.br/wp-content/uploads/2016/09/Aditivo.pdf" target="_blank" rel="noreferrer"> Baixar</a>.
          </p>
          <span className="title mini-title margin">2021</span>
          <span className="title retangle">EDITAL REVISTA "PELA ORDEM, AS ADVOGADAS PEDEM A PALAVRA: DIREITO E TRANSFORMAÇÃO"</span>
          <p>
            1. Edital 01/2021 
            <a href="https://oabsergipe.org.br/wp-content/uploads/2021/08/Edital-01-2021.pdf" target="_blank" rel="noreferrer"> Baixar</a>. 
          </p>
        </div>
      </section>
    </div>
  );
}

export default Editais;
import Styles from 'styled-components';

export const Card = Styles.div`
    height: 10rem;
    width: 10rem;
    padding: 1rem;
    color: ${props => (props.selected ? "#fff" : "#2C627A")};
    font-size: 1.5rem;
    font-family: Teko;
    font-variant-caps: all-petite-caps;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0.5rem 0.5rem;
    cursor: pointer;
    background-color: ${props => (props.selected ? "#2C627A" : "#fff")};
    transition: all 0.5s ease-in-out;
    :hover{
        background-color: ${props => (props.selected ? "#2C627A" : "#1189C3")};
        color: #fff;
        transform: scale(1.2);
        box-shadow: 2px 2px 20px #999;
    }
    @media(max-width: 600px){
        width: 100%;
        font-size: 1rem
        height: auto;
        margin: 0.5rem 0;
        box-shadow: 1px 1px 10px #bfbebe96;
    }
`;

export const SelectedItem = Styles.div`
    background-color: #2c627a;
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
`;

export const SelectedItemText = Styles.a`
    color: white;
    font-family: Roboto;
    :hover{
        color:#fff;
        opacity: 0.5;
        text-decoration: none;
    }
`;

export const SelectedItemTitle = Styles.h3`
    color: white;
    font-family: Teko;
    font-weight: 400;
`;
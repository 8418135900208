import { Switch, Route } from 'react-router-dom'
//pages
import Home from './pages/HomePage'
import TribunalEtica from './pages/Institucional/Tribunal/TribunalEtica'
import Comissao from './pages/Institucional/Comissao/Comissao'
import Legislacao from './pages/Institucional/Legislacao/Legislacao'
import Sobre from './pages/Institucional/Sobre/Sobre'
import BancoDoBrasil from './pages/Alvaras/BancoDoBrasil'
import ExameOrdem from './pages/Secretaria/ExamedeOrdem/ExameOrdem'
import EntregadeCarteiras from './pages/Secretaria/EntregadeCarteira/EntregadeCarteiras'
import CaixaEconomica from './pages/Alvaras/CaixaEconomica'
import Banese from './pages/Alvaras/Banese'
import TabelaHonorarios from './pages/Publicacoes/TabelaHonorarios'
import Editais from './pages/Publicacoes/Editais'
import Resolucoes from './pages/Tesouraria/Resolucoes/Resolucoes'
import Artigos from './pages/Publicacoes/Artigos'
import InscricaoProfissional from './pages/Secretaria/InscricaoProfissional'
import Sociedade from './pages/Secretaria/Sociedade/Sociedade'
import Ouvidoria from './pages/FaleConosco/Ouvidoria'
import DefesaPrerrogativas from './pages/FaleConosco/DefesaPrerrogativas'
import EmentarioTED from './pages/Publicacoes/EmentarioTED'
import PisoSalarial from './pages/Publicacoes/PisoSalarial'
import Eleicoes from './pages/Publicacoes/Eleicoes'
import Transparencia from './pages/Tesouraria/Transparencia/Transparencia'
import LocTel from './pages/FaleConosco/LocTel/LocTel'
import DiarioOab from './pages/Publicacoes/Diarios/oab'
import Cartilhas from './pages/Publicacoes/Cartilhas'
import TvOab from './pages/Publicacoes/TvOab'
import TaxaseEmolumentos from './pages/Tesouraria/TaxaseEmolumentos/TaxaseEmolumentos'
import PaginaInterna from './pages/Blog/paginaInterna'
import BlogPrincipal from './pages/Blog/BlogPrincipal/BlogPrincipal'
import Inss from './pages/Inss/Inss'
import OabBanese from './pages/OabBanese/OabBanese'
import BuscaGeral from './pages/Search'
import { Memorial } from "./pages/Memorial"

//import { createHashHistory} from "history"

const Routes = () => {
    //const history = createHashHistory();

    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/blog/principal" component={BlogPrincipal} />
            <Route path="/search/:q" component={BuscaGeral} />
            <Route path="/memorial" component={Memorial} />

            {/*menu institucional */}
            <Route
                exact
                path="/institucional/tribunal-de-etica"
                component={TribunalEtica}
            />
            <Route exact path="/institucional/comissoes" component={Comissao} />
            <Route
                exact
                path="/institucional/legislacoes"
                component={Legislacao}
            />
            <Route exact path="/institucional/sobre" component={Sobre} />
            {/*menu institucional */}

            {/*menu secretaria */}
            <Route
                exact
                path="/secretaria/entregadecarteiras"
                component={EntregadeCarteiras}
            />
            <Route
                exact
                path="/secretaria/examedeordem"
                component={ExameOrdem}
            />
            <Route
                exact
                path="/secretaria/inscricao-profissional"
                component={InscricaoProfissional}
            />
            <Route exact path="/secretaria/sociedade" component={Sociedade} />
            {/*menu secretaria */}

            {/*menu alavras */}
            <Route
                exact
                path="/alvaras/banco-do-brasil"
                component={BancoDoBrasil}
            />
            <Route
                exact
                path="/alvaras/caixa-economica"
                component={CaixaEconomica}
            />
            <Route exact path="/alvaras/banese" component={Banese} />
            {/*menu alavras */}

            {/*menu tesouraria */}
            <Route exact path="/tesouraria/resolucoes" component={Resolucoes} />
            <Route
                exact
                path="/tesouraria/taxaseemolumentos"
                component={TaxaseEmolumentos}
            />
            <Route
                exact
                path="/tesouraria/transparencia"
                component={Transparencia}
            />
            {/*menu tesouraria */}

            {/*menu publicacoes */}
            <Route
                exact
                path="/publicacoes/tabela-honorarios"
                component={TabelaHonorarios}
            />
            <Route exact path="/publicacoes/editais" component={Editais} />
            <Route exact path="/publicacoes/artigos" component={Artigos} />
            <Route exact path="/publicacoes/eleicoes" component={Eleicoes} />
            <Route
                exact
                path="/publicacoes/ementarioTED"
                component={EmentarioTED}
            />
            <Route
                exact
                path="/publicacoes/piso-salarial"
                component={PisoSalarial}
            />
            <Route
                exact
                path="/publicacoes/diario/diario-oab"
                component={DiarioOab}
            />
            <Route exact path="/publicacoes/TV-Oab" component={TvOab} />
            <Route
                exact
                path="/publicacoes/cartilhas"
                component={Cartilhas}
            />
            {/*menu publicacoes */}

            {/*menu fale conosco */}
            <Route exact path="/fale-conosco/ouvidoria" component={Ouvidoria} />
            <Route
                exact
                path="/fale-conosco/defesa-prerrogativas"
                component={DefesaPrerrogativas}
            />
            <Route exact path="/fale-conosco/loctel" component={LocTel} />
            {/*menu fale conosco */}

            {/* menu blog */}
            <Route exact path="/blog/:idNoticia" component={PaginaInterna} />
            {/* menu blog */}

            {/*Inss*/}
            <Route exact path="/inss/inss" component={Inss} />
           
            {/*OabBanese*/}
            <Route exact path="/oabBanese/oabBanese" component={OabBanese} />
        </Switch>
    )
}

export default Routes


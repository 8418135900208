import React from 'react';
import "./styles.scss";

// import { Container } from './styles';

function CoordenadoriasCientificas() {
  return (
    <div id="esa-coordenadorias-cientificas-container">
      <div id="diretoria-background" />
      <div id="diretores">
        <div className="title title-start">Nome</div>
        <div className="title title-end">Função</div>

        <div className="elements element-start">LUIS FELIPE DE JESUS</div>
        <div className="elements element-end">COORDENADOR DO NÚCLEO DE DIREITO CONSTITUCIONAL E ADMINISTRATIVO</div>

        <div className="elements element-start">MATHEUS BRITO MEIRA</div>
        <div className="elements element-end">COORDENADORA DO NÚCLEO DE DIREITO TRIBUTÁRIO E FINANCEIRO</div>
        
        <div className="elements element-start">THIAGO AUGUSTO SOUZA SILVA</div>
        <div className="elements element-end">COORDENADOR DO NUCLEO DIREITO CIVIL E EMPRESARIAL</div>

        <div className="elements element-start">WINSTON NEIL BEZERRA DE ALENCAR</div>
        <div className="elements element-end">COORDENADOR DO NÚCLEO DE DIREITO DO CONSUMIDOR</div>
        
        <div className="elements element-start">FÁBIO VICTOR DE AGUIAR MENESES</div>
        <div className="elements element-end">COORDENADO DO NÚCLE DE DIREITO PROCESSUAL E RESOLUÇÃO DE CONFLITOS</div>

        <div className="elements element-start">FERNANDA SOUZA SANTOS BITTENCOURT</div>
        <div className="elements element-end">COORDENADORA DO NÚCLEO DE HUMANIDADES E PROMOÇÃO DE IGUALDADE</div>
        
        <div className="elements element-start">CRISTINA MARIA SANTANA NASCIMENTO</div>
        <div className="elements element-end">COORDENADORA DO NÚCLEO DE ÉTICA E INOVAÇÃO</div>

        <div className="elements element-start">CLODOALDO ANDRADE JÚNIOR</div>
        <div className="elements element-end">COORDENADOR DO NÚCLEO DE DIREITO DO TRABALHO</div>
        
        <div className="elements element-start">RIVALDO SALVINO DO NASCIMENTO FILHO</div>
        <div className="elements element-end">COORDENADOR DO NÚCLEO DE DIREITO PENAL</div>
      </div>
    </div>
  );
}

export default CoordenadoriasCientificas;
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Title } from '../../components';
import { useContextSearch } from '../../hooks/ContextSearch';

import './styles.scss';

export default function BuscaGeral(){
    const { data, itensMenuFiltered, text, setText } = useContextSearch()
    const { q } = useParams();

    useEffect(() => {
        if(q.length > 2){
            setText(q)
        }
    }, [])

    return(
        <div id="BuscaGeral">
            <section id='section-1__BuscaGeral'>
                <Title
                textContent='RESULTADOS DA PESQUISA'
                customClass='title-page'
                customClassBackground='background-red-horizontal'
                title
            />
            </section>
            <section id="section-busca__BuscaGeral">
                <i
                    id='icon-search'
                    class='fas fa-search'
                ></i>
                <input
                    id='input-search'
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                />
            </section>
            <section id="section-2__BuscaGeral">
                <div>
                    <h2>Notícias</h2>
                    {data.length !== 0 ?
                        data.map((item) => (
                            <article className="newsIndividual">
                            <Link to={`/blog/${item.id}`}>
                                <img className="newsIndividualImg" src={item?.acf?.imagem_da_item?.url ? item?.acf?.imagem_da_item?.url :
                                            item._embedded["wp:featuredmedia"]["0"]
                                            .media_details.sizes.full.source_url}/>
                                <div className="newsIndividualText">
                                    <p>
                                        {
                                            item?.acf?.titulo_da_noticia
                                            ?item?.acf?.titulo_da_noticia
                                            :item.title.rendered
                                                .split(new RegExp(`&#8211;`, "i"))
                                                .join("")
                                        }
                                    </p>
                                    <p>
                                        {
                                        item?.acf?.data_da_publicacao
                                        ?new Date(item?.acf?.data_da_publicacao)
                                        :new Date(item.date).toLocaleDateString("pt-BR")
                                        }
                                    </p>
                                    <p>
                                        {
                                        item?.acf?.descricao_da_noticia
                                        ?item?.acf?.descricao_da_noticia
                                        :item?.content?.rendered
                                            .split(new RegExp(`<.*?>`, "i"))
                                            .join("")
                                            .slice(0, 60)
                                        }...
                                    </p>
                                </div>
                            </Link>
                        </article>
                    )) 
                    : <p>Carregando...</p>
                    }
                </div>
                <div>
                    <h2>Páginas</h2>
                        {itensMenuFiltered.length !== 0 ?
                            itensMenuFiltered.map((item) => (
                            <>
                                <Link to={item.path}>
                                    {item.component}
                                </Link> <br />
                            </>
                        )) : <p style={{color: "#b0b0b3"}}>Nenhuma página foi encontrada com este nome</p>}
                </div>
            </section>
        </div>
    )
}
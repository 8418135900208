import React from 'react'
import { Link } from 'react-router-dom';
import {
    StyledTypography,
    StyledBottomPattern,
    HideMobile,
    ShowMobile
} from './Atalhos.elements'
import consulta from '../../assets/images/Home/consulta.svg'
import { ReactComponent as Exame } from '../../assets/images/Home/exame.svg'
import { ReactComponent as Defesa } from '../../assets/images/Home/defesa.svg'
import { ReactComponent as Memorial } from '../../assets/images/Home/memorial.svg'
import { ReactComponent as Transparencia } from '../../assets/images/Home/transparencia.svg'
import { ReactComponent as Ouvidoria } from '../../assets/images/Home/ouvidoria.svg'
import { ReactComponent as Consulta } from '../../assets/images/Home/consulta.svg'


const Atalhos = () => {
    return (
        <>
            <HideMobile>
                <div className="container">
                    <StyledTypography>
                        <StyledBottomPattern>Ata</StyledBottomPattern>
                        <StyledBottomPattern regular>lhos</StyledBottomPattern>
                    </StyledTypography>{' '}
                    <div className="card-deck">
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <a
                                    href="https://www6.oab.org.br/sgd/livre/consulta/processual/seccional/f8ede15a-aa0a-4942-bf45-308bfaf4b7a0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none ' }}
                                >
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/*  <img src={consulta} alt="" />{' '} */}
                                            <Consulta />
                                        </div>
                                        <div className="col-5">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                CONSULTA PROCESSUAL
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <a
                                    href="https://examedeordem.oab.org.br//"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none ' }}
                                >
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/*  <img src={exame} alt="" />{' '} */}
                                            <Exame />
                                        </div>
                                        <div className="col-5">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                EXAME DE ORDEM
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <Link
                                    to="/fale-conosco/defesa-prerrogativas"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/* <img src={defesa} alt="" />{' '} */}
                                            <Defesa />
                                        </div>
                                        <div className="col-7">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                DEFESA DAS PRERROGATIVAS
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-deck" style={{ marginTop: '15px' }}>
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <Link
                                    to="/memorial"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/* <img src={memorial} alt="" />{' '} */}
                                            <Memorial />
                                        </div>
                                        <div className="col-5">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                MEMORIAL DA ADVOCACIA
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <Link
                                    to="/tesouraria/transparencia"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    {' '}
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/*  <img src={transparencia} alt="" />{' '} */}
                                            <Transparencia />
                                        </div>
                                        <div className="col-7">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                PORTAL DA TRANSPARÊNCIA
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="card h-full">
                            <div
                                className="card-body"
                                style={{ cursor: 'pointer' }}
                            >
                                <Link
                                    to="/fale-conosco/ouvidoria"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div className="row title_card">
                                        <div className="col-2"></div>
                                        <div className="col-3">
                                            {/* <img src={ouvidoria} alt="" />{' '} */}
                                            <Ouvidoria />
                                        </div>
                                        <div className="col-5">
                                            <p
                                                style={{
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                OUVIDORIA
                                            </p>{' '}
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </HideMobile>
            <ShowMobile style={{ marginBottom: '2rem' }}>
                <div className="container">
                    <StyledTypography>
                        <StyledBottomPattern style={{ fontSize: '1.2em' }}>
                            Ata
                        </StyledBottomPattern>
                        <StyledBottomPattern
                            style={{ fontSize: '1.2em' }}
                            regular
                        >
                            lhos
                        </StyledBottomPattern>
                    </StyledTypography>{' '}
                    <div className="row">
                        <div className="col-6">
                            <div
                                className="card h-half mb-2"
                                style={{ width: '100%' }}
                            >
                                <a
                                    href="https://www6.oab.org.br/sgd/livre/consulta/processual/seccional/f8ede15a-aa0a-4942-bf45-308bfaf4b7a0"
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none'
                                    }} rel="noreferrer"
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/* <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    /> */}
                                        <Consulta />

                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            CONSULTA PROCESSUAL
                                        </p>{' '}
                                    </div>
                                </a>
                            </div>
                            <div className="card h-half mb-2">
                                <a
                                    href="https://examedeordem.oab.org.br//"
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none'
                                    }} rel="noreferrer"
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/* <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    />{' '} */}
                                        <Exame />

                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            EXAME DE ORDEM
                                        </p>{' '}
                                    </div>
                                </a>
                            </div>
                            <div className="card h-half mb-2">
                                <Link
                                    to="/fale-conosco/defesa-prerrogativas"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/*  <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    />{' '} */}
                                        <Defesa />
                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            DEFESA DAS PRERROGATIVAS
                                        </p>{' '}
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card h-half mb-2">
                                <a
                                    href="https://museus.cultura.gov.br/espaco/8504/"
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none'
                                    }} rel="noreferrer"
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/*  <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    />{' '} */}
                                        <Memorial />
                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            MEMORIAL DA ADVOCACIA
                                        </p>{' '}
                                    </div>
                                </a>
                            </div>
                            <div className="card h-half mb-2">
                                <Link
                                    to="/tesouraria/transparencia"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/* <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    />{' '} */}
                                        <Transparencia />
                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            PORTAL DA TRANSPARÊNCIA
                                        </p>{' '}
                                    </div>
                                </Link>
                            </div>
                            <div className="card h-half mb-2">
                                <Link
                                    to="/fale-conosco/ouvidoria"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                        className="card-body"
                                    >
                                        {/*  <img
                                        style={{ paddingBottom: '15px' }}
                                        src={consulta}
                                        alt=""
                                    />{' '} */}
                                        <Ouvidoria />
                                        <p
                                            style={{
                                                color: '#3E92B7',
                                                fontWeight: 'bolder'
                                            }}
                                        >
                                            OUVIDORIA
                                        </p>{' '}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card mt-5 mb-2">
                        <div
                            style={{
                                textAlign: 'center',
                                alignItems: 'center'
                            }}
                            className="card-body"
                        >
                            <img
                                style={{ paddingBottom: '15px' }}
                                src={consulta}
                                alt=""
                            />{' '}
                            <p
                                style={{
                                    color: '#3E92B7',
                                    fontWeight: 'bolder'
                                }}
                            >
                                MEMORIAL DA ADVOCACIA
                            </p>{' '}
                        </div>
                    </div> */}
                </div>
            </ShowMobile>
        </>
    )
}

export default Atalhos

import React from 'react';
import { data } from "./data";
import './styles.scss';

function CAA() {
    return (
        <div id="caa-container">
            <div id="diretoria-background" />
            <div id="diretores">
                <div id="data-title">
                    DIRETORIA CAA/SE - TRIÊNIO {data.trienio}
                </div>
                <div id="data-info">
                    <div className="column">
                        <div className="cargo">Presidente</div>
                        <div className="ocupante">{data.presidente.nome} OAB/SE {data.presidente.codigo}</div>

                        <div className="cargo">Vice-Presidente</div>
                        <div className="ocupante">{data.vicePresidente.nome} OAB/SE {data.vicePresidente.codigo}</div>

                        <div className="cargo">Secretário Geral</div>
                        <div className="ocupante">{data.secretarioGeral.nome} OAB/SE {data.secretarioGeral.codigo}</div>
                    </div>

                    <div className="column">

                        <div className="cargo">Secretário Geral Adjunto</div>
                        <div className="ocupante">{data.secretarioGeralAdjunto.nome} OAB/SE {data.secretarioGeralAdjunto.codigo}</div>

                        <div className="cargo">Tesouraria</div>
                        <div className="ocupante">{data.tesouraria.nome} OAB/SE {data.tesouraria.codigo}</div>

                        <div className="cargo">Suplentes</div>
                        {data.suplentes.map(suplente => (
                            <div className="ocupante" key={suplente.codigo}>
                                {suplente.nome} OAB/SE {suplente.codigo}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CAA;
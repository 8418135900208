import React from 'react';

// import { Container } from './styles';

function CloseIconMobile({ onReturn }) {
    return (
        <svg onClick={() => onReturn()} xmlns="http://www.w3.org/2000/svg" width="30.762" height="27.46" viewBox="0 0 30.762 27.46">
            <g id="backspace" transform="translate(0 -2.25)">
                <path id="Caminho_54" data-name="Caminho 54" d="M12.952,4.211H26.839A1.961,1.961,0,0,1,28.8,6.173V25.787a1.961,1.961,0,0,1-1.961,1.961H12.952a1.961,1.961,0,0,1-1.491-.686L1.961,15.98,11.463,4.9A1.961,1.961,0,0,1,12.952,4.211ZM26.839,2.25a3.923,3.923,0,0,1,3.923,3.923V25.787a3.923,3.923,0,0,1-3.923,3.923H12.952a3.923,3.923,0,0,1-2.98-1.37L.473,17.256a1.961,1.961,0,0,1,0-2.553l9.5-11.079a3.923,3.923,0,0,1,2.98-1.373Z" transform="translate(0)" fill-rule="evenodd" />
                <path id="Caminho_55" data-name="Caminho 55" d="M13.075,11.537a.981.981,0,0,0,0,1.389l9.807,9.807a.981.981,0,1,0,1.387-1.389l-9.807-9.807a.981.981,0,0,0-1.389,0Z" transform="translate(-1.64 -1.154)" fill-rule="evenodd" />
                <path id="Caminho_56" data-name="Caminho 56" d="M24.269,11.537a.981.981,0,0,1,0,1.389l-9.807,9.807a.982.982,0,1,1-1.389-1.389l9.807-9.807a.981.981,0,0,1,1.387,0Z" transform="translate(-1.64 -1.154)" fill-rule="evenodd" />
            </g>
        </svg>

    );
}

export default CloseIconMobile;
import styled, { css } from 'styled-components'
import smallNoticias from '../../assets/images/Home/noticias/banner-small-noticias-2.png'
import smallNoticiasMobile from '../../assets/images/Home/noticias/banner-small-noticias-mobile.png'
export const StyledImage = styled.img`
    height: 200px;
`

export const StyledTypography = styled.div`
    font-size: 1.2em;
    color: #444444;
    font-weight: bold;
    

    ${props =>
        props.white &&
        css`
            color: #fff;
        `}
`

export const StyledBottomPattern = styled.span`
    border-bottom: 3px solid #aa4f50;
    padding-bottom: 5sspx;
    font-family: Teko;

    ${props =>
        props.regular &&
        css`
            border-bottom: none;
            padding-bottom: none;
        `}

    ${props =>
        props.borderwhite &&
        css`
            border-bottom: 3px solid #fff;
        `}
`

export const StyledSidePattern = styled.div`
    background-color: #2c627a;
    height: 107px;
    margin-top: 10px;
    background-image: url(${smallNoticias});
    background-size: 100% 92%;
    background-position: 50%;
    ${props =>
        props.margintop &&
        css`
            margin-top: 35px;
        `}
`
export const StyledSidePattern2 = styled.div`
    background-color: #2c627a;
    height: 107px;
    margin-top: 10px;
    background-image: url(${smallNoticiasMobile});
    background-size: cover;
    background-position: 50%;
    ${props =>
        props.margintop &&
        css`
            margin-top: 35px;
        `}
`

export const Styledlabel = styled.h6`
    color: #f8f8f8;
    text-align: center;
    padding-top: 15px;
`

export const HideMobile = styled.div`
    display: block;

    @media (max-width: 900px) {
        display: none;
        
    }
`

export const ShowMobile = styled.div`
    display: none;

    @media (max-width: 900px) {
        display: block;
    }
`

export const StyledBox = styled.div`
    background-color: #aa4f50;
    position: relative;
    margin: auto;
    top: -5%;
    width: 70%;
    height: 25px;
`

export const StyledBoxMobile = styled.div`
    background-color: #aa4f50;
    position: relative;
    top: -5%;
    width: 100%;
    height: 40px;
`

import React, { useState, useRef, useEffect } from 'react';
import { Button, Card } from 'semantic-ui-react';
import { RiShoppingCartLine } from 'react-icons/ri'
import CardDetalhesdeFaturamento from './CardDetalhesdeFaturamento';
import { Title, Search } from '../../../components';
import './styles.scss';
import Data from './data.js';
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs';

const TaxaseEmolumentos = () => {

  const [selected, setSelected] = useState([]);
  const [continuarComprando, setContinuarComprando] = useState(true)
  const [isSelected, setIsSelected] = useState();
  const [dataFiltered, setDataFiltered] = useState(Data);
  const Cards = useRef(null);

  function handleLeft() {
    Cards.current.scrollLeft += 254.15
  }

  function handleRight() {
    Cards.current.scrollLeft -= 254.15
  }

  function addCarrinho({ item, index }) {
    setSelected([item, ...selected])
    setIsSelected(index)
    setContinuarComprando(true);
  }

  function Pagar({ item, index }) {
    setSelected([item, ...selected])
    setIsSelected(index)
    setContinuarComprando(false);
  }

  // Função chamada pelo callbackParent();
  const onChildChanged = (limpar, newArray) => {
    newArray ? setContinuarComprando(false) : setContinuarComprando(true)
    limpar && setSelected([])
    newArray && setSelected([...newArray])
  }

  function searchData(e) {
    let search = e.target.value;
    if (search === "") {
      setDataFiltered(Data);
    } else {
      setDataFiltered(Data.filter(item => item.descricao.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))));
    }
  }

  return (
    <div id='taxas-e-emolumentos'>
      <section id='section-1'>
        <Title
          textContent='TAXAS E EMOLUMENTOS'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;TESOURARIA >'
          link3='&nbsp;TAXAS E EMOLUMENTOS'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      {continuarComprando === true ?
        <>
          <div id="titulo">
            Efetue seu pagamento
            <div id="container-carrinho">
              <RiShoppingCartLine className="carrinho-svg" size={25} color="#2C627A" onClick={() => selected.length > 0 && setContinuarComprando(false)} />
              <div id="qtd-carrinho">
                <p>{selected.length}</p>
              </div>
            </div>
          </div>
          <div id="subtexto">
            Escolha a opção desejada e clique em "Pagar" para prosseguir
          </div>
          <section id="section-cards">
            <div id="pesquisa">
              <Search onChange={searchData} placeholder="O que você deseja encontrar?" />
            </div>
            <div id="card" ref={Cards}>
              {dataFiltered.map((item, index) => (
                <Card key={index}>
                  <div>
                    <Card.Header id="header-description">{item.descricao}</Card.Header>
                    <div id="subtext">
                      {item.descricao2}<br />
                      {item.descricao3}<br />
                      {item.descricao4}
                    </div>
                  </div>
                  <div>
                    <Card.Description id="value-description"><div id="linha-horizontal-card"></div>
                      R$ {item.value}
                    </Card.Description>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <center>
                          <Button basic color='green' id="button" onClick={() => Pagar({ item, index })} selected={isSelected === index ? true : false}>
                            Pagar
                          </Button>
                        </center>
                        <center>
                          <Button id="button-carrinho" onClick={() => addCarrinho({ item, index })} selected={isSelected === index ? true : false}>
                            Carrinho
                          </Button>
                        </center>
                      </div>
                    </Card.Content>
                  </div>
                </Card>
              ))}
            </div>
            <div className="paginacao">
              <div className="Esquerda">
                <BsArrowBarLeft onClick={() => handleRight()} />
              </div>
              <div className="Direita">
                <BsArrowBarRight onClick={() => handleLeft()} />
              </div>
            </div>
          </section>
        </>
        :
        <>

          <section id="section-cards">
            <CardDetalhesdeFaturamento
              itens={selected}
              value={selected?.value}
              callbackParent={(limpar, newArray) => onChildChanged(limpar, newArray)}
            />
          </section>
        </>
      }
    </div>
  );
}

export default TaxaseEmolumentos;
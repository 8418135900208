import Styles from 'styled-components';

export const Card = Styles.div`
    height: 10rem;
    width: 10rem;
    padding: 1rem;
    color: #1189C3;
    font-size: 1.5rem;
    font-family: Teko;
    font-variant-caps: all-petite-caps;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0.5rem 0.5rem;
    cursor: pointer;
    background-color: #fff;
    :hover{
        background-color: #1189C3;
        color: #fff;
        box-shadow: 2px 2px 20px #999;
    }
    @media(max-width: 600px){
        width: 100%;
        font-size: 1rem
        height: auto;
        margin: 0.5rem 0;
        box-shadow: 1px 1px 10px #bfbebe96;
    }
`;

export const SelectedItem = Styles.div`
    background-color: #1189C3;
    width: 60%;
    height: 100%;
    padding: 2rem;
    margin: 0 auto 5rem;
    box-shadow: 2px 5px 20px #999;
    @media (max-width: 768px) {
        width: 90%;
        padding: 2rem 1rem;
    }
`;

export const SelectedItemText = Styles.p`
    color: white;
    font-family: Roboto;
`;

export const SelectedItemTitle = Styles.h3`
    color: white;
    font-family: Teko;
    font-weight: 400;
`;

export const SelectedItemTitleH2 = Styles.h2`
    color: #333 !important;
    font-family: Teko !important;
    font-weight: 400 !important;
    font-size: 1.5rem !important;
    cursor:pointer;
`;

export const MembersTextBold = Styles.p`
    color: #fff;
    font-family: Roboto;
    font-size: 0.9rem !important;
    font-weight: bold;
    padding-right: 4px;
    display: flex;
`;

export const MembersTitle = Styles.p`
    color: #fff;
    font-family: Roboto;
    font-size: 0.9rem !important;
    font-weight: bold;
    text-align: center;
`;

export const MembersText= Styles.p`
    color: #fff;
    font-family: Roboto;
    font-size: 0.9rem !important;
`;

export const MembersTextRight= Styles.p`
    color: #fff;
    font-family: Roboto;
    font-size: 0.9rem !important;
    text-align: right;
    padding-left: 4px;    
`;

export const MembersContent= Styles.div`
    display: grid;
    width: 100%;
`;
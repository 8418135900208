import React, { useState } from 'react'
import { Title, Search } from '../../../components';
import { Data } from "./data";

import arrow from '../../../assets/images/Institucional/Legislacao/Arrow.svg'

import info from './info'
import './styles.scss'

const Sociedade = () => {
    const [content, setContent] = React.useState('');
    const [dataFiltered, setDataFiltered] = useState(Data);

    document.addEventListener('click', function(event) {
        if (event.target.id === "input-search-component") {
            document
            .getElementById('dropdownSociedadeList')
            .classList.add('showList')
        } else {
        document
            .getElementById('dropdownSociedadeList')
            .classList.remove('showList')
        }
    })


    function searchData(e) {
        let search = e.target.value;
        if (search === "") {
            setDataFiltered(Data);
        } else {
            setDataFiltered(Data.filter(item => item.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))));
        }
    }

    const handleInfoCard = i => {
        document
            .getElementById('infoCardBig')
            .classList.toggle('showInfoCardBig')
        setContent(info[i])
        if (
            document.getElementById('infoCard').classList[0] === 'showInfoCard'
        ) {
            if (info[i] !== content) {
                setContent(info[i])
            } else {
                document
                    .getElementById('infoCard')
                    .classList.remove('showInfoCard')
            }
        } else {
            document.getElementById('infoCard').classList.toggle('showInfoCard')
            setContent(info[i])
        }

        for (let j = 0; j < 6; j++) {
            if (j === i) {
                document
                    .getElementById(`smallInfo${i}`)
                    .classList.toggle('showSmall')
            } else {
                document
                    .getElementById(`smallInfo${j}`)
                    .classList.remove('showSmall')
            }
        }
    }

    return (
        <div id="sociedade">
            <div className="section-1 ">
                <Title
                    textContent="SOCIEDADE DE ADVOGADOS"
                    customClass="title-page"
                    link1="A OAB/SE >"
                    link2="&nbsp;SECRETARIA >"
                    link3="&nbsp;SOCIEDADE DE ADVOGADOS"
                    rota1="/"
                    customClassBackground="background-red-horizontal"
                    title
                />
            </div>
            <div className="section-2 container " id="section2">
                <Search
                    onChange={searchData}
                    placeholder="Faça o download dos documentos-modelo aqui"
                />

                <div className="row justify-content-center" id="dd">
                    <div className="dropdownSociedade row justify-content-center col-11 col-md-9 col-xl-9 p-0">

                        <ul
                            id="dropdownSociedadeList"
                            className="dropdownSociedadeList"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            {dataFiltered.map((data, index) => (
                                <li className="dropdown-item" key={index}>
                                    <a
                                        href={data.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.nome}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/*  <div className="row justify-content-center mt-4">
                    <button id="sociedadeDownloadButton">Download</button>
                </div> */}
                <div className="row justify-content-center mt-5 w-100 h2Container">
                    <h2 id="subtituloSociedade">
                        TIPOS DE REQUERIMENTOS PARA SOCIEDADE DE ADVOGADOS
                    </h2>
                </div>
                <div className="row  justify-content-center mt-5 col-lg-9 imageAndCards">
                    <div id="infoCardBig">{content}</div>
                    <div className="row justify-content-center align-items-center col-8 col-sm-5 col-md-4 col-lg-4 imagem m-0">
                        <img id="arrowToCards" src={arrow} alt="arrowToRight" />
                        <span
                            className="row justify-content-center col-8 m-0"
                            id="selecioneUmaOpcao"
                        >
                            Selecione uma opção
                        </span>
                    </div>
                    <div className="row justify-content-center col-12 col-sm-10 col-md-9 col-lg-7 cards">
                        <div className="container row justify-content-center p-0">
                            <div className="row col-12  justify-content-center p-0">
                                <div
                                    className="col-md-4 justify-content-center"
                                    onClick={() => handleInfoCard(0)}
                                >
                                    REGISTRO DE SOCIEDADE DE ADVOGADOS
                                </div>
                                <div id="smallInfo0">{content}</div>
                                <div
                                    className="col-md-4 justify-content-center"
                                    onClick={() => handleInfoCard(1)}
                                >
                                    REGISTRO DE SOCIEDADE INDIVIDUAL DE
                                    ADVOCACIA
                                </div>
                                <div id="smallInfo1">{content}</div>
                                <div
                                    className="col-md-4 justify-content-center"
                                    onClick={() => handleInfoCard(2)}
                                >
                                    DISTRATO SOCIAL
                                </div>
                                <div id="smallInfo2">{content}</div>
                            </div>
                            <div className="row col-12  justify-content-center p-0">
                                <div
                                    className="col-md-4"
                                    onClick={() => handleInfoCard(3)}
                                >
                                    ALTERAÇÃO CONTRATUAL
                                </div>
                                <div id="smallInfo3">{content}</div>
                                <div
                                    className="col-md-4"
                                    onClick={() => handleInfoCard(4)}
                                >
                                    CONTRATO DE ASSOCIAÇÃO COM ADVOGADO
                                </div>
                                <div id="smallInfo4">{content}</div>
                                <div
                                    className="col-md-4"
                                    onClick={() => handleInfoCard(5)}
                                >
                                    DISTRATO DE ASSOCIAÇÃO
                                </div>
                                <div id="smallInfo5">{content}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-10 mx-auto justify-content-center goToTop">
                        <div id="infoCard">{content}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sociedade

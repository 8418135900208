import React from "react";
import logo from "../../assets/images/Home/Logo_OAB_footer.png";
//import payments from '../../assets/images/Home/payments@2x.png'
import getinfo from "../../assets/images/Home/logo-getinfo.png";
import facebook from "../../assets/images/Home/Icon simple-facebook.svg";
import instagram from "../../assets/images/Home/Icon simple-instagram.svg";
import linkedin from "../../assets/images/Home/Icon simple-linkedin.svg";
import twitter from "../../assets/images/Home/Icon simple-twitter.svg";
import youtube from "../../assets/images/Home/Icon simple-youtube.svg";
import { HideMobile, ShowMobile, LinksUteis } from "./Footer.elements";
import { Link } from "react-router-dom";
import "../../pages/Institucional/Tribunal/styles.scss";
import "./styles.scss";

const Footer = () => {
  return (
    <>
      <HideMobile>
        <div id="footer">
          <section className="section-1">
            <div className="title">
              <span>Links Úteis</span>
            </div>
            <div className="grid">
              <LinksUteis
                href="https://www.oab.org.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>CFOAB</p>
              </LinksUteis>
              <LinksUteis
                href="https://caase.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>CAA/SE</p>
              </LinksUteis>

              <LinksUteis
                href="https://cna.oab.org.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>CNA</p>
              </LinksUteis>

              <LinksUteis
                href="http://www.esasergipe.org.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>ESA</p>
              </LinksUteis>
              <LinksUteis>
                <Link
                  to="/institucional/tribunal-de-etica"
                  rel="noopener noreferrer"
                >
                  <p>Tribunal de Ética</p>
                </Link>
              </LinksUteis>
              <LinksUteis
                href="https://advise.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Advise</p>
              </LinksUteis>
              <LinksUteis
                href="https://www1.oab.org.br/Login/?aplicacao=34&ReturnUrl=https://www6.oab.org.br/sgd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>SGD</p>
              </LinksUteis>
              <LinksUteis
                href="https://anuidadezero.oab.org.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Anuidade Zero</p>
              </LinksUteis>
            </div>
          </section>
          <section className="section-2">
            <div className="span-logo">
              <div className="logo">
                <img src={logo} alt="logo-OAB" />
              </div>
              <span style={{ color: "#f5f5dc" }}>
                Copyright © 2021 OAB - Ordem dos Advogados do Brasil Seccional
                Sergipe - OAB/SE
              </span>
              <div
                className="getinfo pt-3"
                style={{
                  marginBottom: 20,
                }}
              >
                <a
                  href="https://www.getinfo.net.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={getinfo} alt="logo-GetInfo" className="logoGet" />
                </a>
                <a
                  href="https://www.getinfo.net.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <p style={{ fontSize: "12px" }}>Design e Desenvolvimento</p>
                </a>
              </div>
            </div>
          </section>
          <section className="section-3">
            <div className="logos">
              <div className="logo">
                <a
                  href="https://www.facebook.com/OABSergipe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img style={{ paddingRight: "25px" }} src={facebook} alt="" />
                </a>
              </div>
              <div style={{ paddingRight: "25px" }} className="logo">
                <a
                  href="https://www.instagram.com/oabsergipe/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img src={instagram} alt="" />
                </a>
              </div>
              <div className="logo">
                <a
                  href="https://www.linkedin.com/company/ordem-dos-advogados-do-brasil-conselho-seccional-de-sergipe/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img style={{ paddingRight: "25px" }} src={linkedin} alt="" />
                </a>
              </div>
              <div className="logo">
                <a
                  href="https://twitter.com/OAB_SE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img style={{ paddingRight: "25px" }} src={twitter} alt="" />
                </a>
              </div>
              <div className="logo">
                <a
                  href="https://www.youtube.com/channel/UChIcCm_wnug91KzKavHCK8A"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img style={{ paddingRight: "5px" }} src={youtube} alt="" />
                </a>
              </div>
            </div>
          </section>
        </div>
      </HideMobile>
      <ShowMobile>
        <div className="container">
          <h1 style={{ color: "#ffffff", fontSize: "1.5em" }}>Links úteis</h1>
          <a
            href="https://www.oab.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>CFOAB</h2>
          </a>

          <a
            href="https://www.esasergipe.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>ESA</h2>
          </a>

          <a
            href="https://caase.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>CAA/SE</h2>
          </a>
          <LinksUteis>
            <Link to="/institucional/tribunal-de-etica" className="link">
              <p className="text-tribunal">Tribunal de Ética</p>
            </Link>
          </LinksUteis>
          <a
            href="https://www1.oab.org.br/Login/?aplicacao=34&ReturnUrl=https://www6.oab.org.br/sgd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>SGD</h2>
          </a>
          <a
            href="https://advise.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>Advise</h2>
          </a>
          <a
            href="https://cna.oab.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>CNA</h2>
          </a>

          <a
            href="https://anuidadezero.oab.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 style={{ color: "#fff", fontSize: "1.0em" }}>Anuidade Zero</h2>
          </a>

          <div
            className="pt-3"
            style={{
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <a href="https://www.facebook.com/OABSergipe" target="blank">
              <img style={{ paddingRight: "25px" }} src={facebook} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/ordem-dos-advogados-do-brasil-conselho-seccional-de-sergipe/"
              target="blank"
            >
              <img style={{ paddingRight: "25px" }} src={linkedin} alt="" />
            </a>
            <a href="https://www.instagram.com/oabsergipe/" target="blank">
              <img style={{ paddingRight: "25px" }} src={instagram} alt="" />
            </a>
            <a href="https://twitter.com/OAB_SE" target="blank">
              <img style={{ paddingRight: "25px" }} src={twitter} alt="" />
            </a>
            <a
              href="https://www.youtube.com/channel/UChIcCm_wnug91KzKavHCK8A"
              target="blank"
            >
              <img style={{ paddingRight: "25px" }} src={youtube} alt="" />
            </a>
          </div>
          <div
            className="pt-5"
            style={{
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="logo">
              <img src={logo} alt="logo-OAB" />
            </div>
            <span style={{ color: "#f5f5dc" }}>
              Copyright © 2021 OAB - Ordem dos Advogados do Brasil Seccional
              Sergipe - OAB/SE
            </span>
            <div className="getinfo pt-3" style={{ margin: "20px 10px" }}>
              <a
                href="https://www.getinfo.net.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={getinfo}
                  alt="logo-GetInfo"
                  style={{ width: "100%" }}
                  className="getinfoLogo"
                />
              </a>
              <a
                href="https://www.getinfo.net.br/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p style={{ fontSize: "12px" }}>Design e Desenvolvimento</p>
              </a>
            </div>
          </div>
        </div>
      </ShowMobile>
    </>
  );
};

export default Footer;

import './styles.scss';

function firstCard(){
  return(
    <div id="info-card">
    <div className="list">
    <span className="title">Documentos necessários:</span>
    <ul>
      <li>
        <strong>1 –</strong> Diploma e fotocópia* (na ausência do diploma, Certidão de Colação 
        de grau e Cópia do Histórico Escolar da Faculdade)
      </li>
      <li>
       <strong> 2 –</strong> Carteira de Identidade (fotocópia*) 
      </li>
      <li>
        <strong>3 –</strong> Carteira de Reservista (fotocópia*)
      </li>
      <li>
        <strong>4 –</strong> Fotocópia* do comprovante de Residência
      </li>
      <li>
        <strong>5 –</strong> Titulo de Eleitor (fotocópia*)
      </li>
      <li>
        <strong>6 –</strong> C.P.F (fotocópia*)
      </li>
      <li>
        <strong>7 –</strong> Três Fotos 3X4 (Fundo Branco e roupa escura – Sexo Masculino de Terno e Gravata)
      </li>
      <li>
        <strong>8 –</strong> Declaração da Atividade Exercida pelo (a) Candidato (a) – Atividade Pública ou Privada.
      </li>
      <li>
        <strong>9 –</strong> Certidão de aprovação no Exame de Ordem (Cópia*)
      </li> 
      <li>
        <strong>10 -</strong> Taxa de Inscrição:
        <a href="https://oabsergipe.org.br/taxas/" target="_blank" rel="noreferrer"> Link</a>
      </li>
      <li>
        <strong>11 -</strong> Publicação do Edital de Inscrição no Diário Oficial DJ (após entrega de toda documentação)
      </li>
      <li>
        <strong>12 -</strong> Certidões Negativas do Tribunal de Justiça do Estado de Sergipe: <br /> 
        <strong>a.</strong>Ação Cível: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Ação Criminal: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>13 -</strong> Certidão Negativa de distribuição de ações e execuções na Justiça Federal: <br /> <br />
        <strong>a.</strong>Tribunal Regional Federal da 5ª Região: <a href="https://www4.trf5.jus.br/certidoes/" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>14 -</strong> Certidões do Tribunal Regional Eleitoral de Sergipe: <br />
        <strong>a.</strong>Negativa de Crimes Eleitorais: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-de-crimes-eleitorais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Quitação Eleitoral: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-quitacao-eleitoral" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>15 -</strong> Atestado de Antecedentes Criminais <br />
        <strong>a.</strong>Polícia Federal: <a href="http://www.pf.gov.br/servicos-pf/antecedentes-criminais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>SSP/SE: <a href="http://187.17.2.52/antecedentes/" target="_blank" rel="noreferrer">Link</a>
      </li>
    </ul>
    </div>
    <div className="obs">
      <span>OBS:<p> Não ter sido condenado por sentença transitado e julgado em processo criminal, 
        salvo por crime que não importe em incapacidade moral.</p> 
      </span> 
      <strong>*fotocópia autenticada em cartório</strong>
    </div>
  </div>
  );
};

function secondCard(){
  return(
    <div id="info-card">
    <div className="list">
    <span className="title">Documentos necessários:</span>
    <ul>
      <li>
        <strong>1 –</strong> Carteira e Cédula da Ordem originária para devolução
      </li>
      <li>
       <strong> 2 –</strong> Juntar Certidão e fotocópia* do Processo de Inscrição de origem 
      </li>
      <li>
        <strong>3 –</strong> Carteira de Identidade (fotocópia*) obs.: só será necessária se o RG do processo estiver antigo.
      </li>
      <li>
        <strong>4 –</strong> Comprovante de Residência
      </li>
      <li>
        <strong>5 –</strong> Titulo de Eleitor (fotocópia*) obs.: só será necessária se houve alguma alteração como 
        secção, estado, etc., da fotocópia do processo.
      </li>
      <li>
        <strong>6 –</strong> C.P.F (fotocópia*) idem ao item 3
      </li>
      <li>
        <strong>7 –</strong> Três Fotos 3X4 (Fundo Branco e roupa escura – 
        Sexo Masculino de Terno e Gravata)
      </li>
      <li>
        <strong>8 –</strong> Declaração da Atividade Exercida pelo (a) Candidato (a) – Atividade Pública ou Privada.
      </li>
      <li>
        <strong>9 -</strong> Taxa de Inscrição:
        <a href="https://oabsergipe.org.br/taxas/" target="_blank" rel="noreferrer"> Link</a>
      </li>
      <li>
        <strong>10 -</strong> Publicação do Edital de Inscrição no Diário Oficial DJ (após entrega de toda documentação)
      </li>
      <li>
        <strong>11 -</strong> Certidões Negativas do Tribunal de Justiça do Estado de Sergipe: <br /> 
        <strong>a.</strong>Ação Cível: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Ação Criminal: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>12 -</strong> Certidão Negativa de distribuição de ações e execuções na Justiça Federal: <br /> <br />
        <strong>a.</strong>Tribunal Regional Federal da 5ª Região: <a href="https://www4.trf5.jus.br/certidoes/" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>13 -</strong> Certidões do Tribunal Regional Eleitoral de Sergipe: <br />
        <strong>a.</strong>Negativa de Crimes Eleitorais: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-de-crimes-eleitorais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Quitação Eleitoral: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-quitacao-eleitoral" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>14 -</strong> Atestado de Antecedentes Criminais <br />
        <strong>a.</strong>Polícia Federal: <a href="http://www.pf.gov.br/servicos-pf/antecedentes-criminais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>SSP/SE: <a href="http://187.17.2.52/antecedentes/" target="_blank" rel="noreferrer">Link</a>
      </li>
    </ul>
    </div>
    <div className="obs">
      <span>OBS:<p> Não ter sido condenado por sentença transitado e julgado em processo criminal, 
        salvo por crime que não importe em incapacidade moral.</p> 
      </span> 
      <strong>*fotocópia autenticada em cartório</strong>
    </div>
  </div>
  );
}
function thirdCard(){
  return(
    <div id="info-card">
    <div className="list">
    <span className="title">Documentos necessários:</span>
    <ul>
      <li>
        <strong>1 –</strong> Carteira da Ordem de origem para competente 
        anotação Juntar Certidão e fotocópia* do Processo de Inscrição de origem
      </li>
      <li>
        <strong>2 –</strong> Carteira de Identidade (fotocópia*) obs.: só será necessária se o RG do processo estiver antigo.
      </li>
      <li>
        <strong>3 –</strong> Comprovante de Residência
      </li>
      <li>
        <strong>4 –</strong> Titulo de Eleitor (fotocópia*) obs.: só será necessária se houve alguma alteração como 
        secção, estado, etc., da fotocópia do processo.
      </li>
      <li>
        <strong>5 –</strong> C.P.F (fotocópia*) idem ao item 3
      </li>
      <li>
        <strong>6 –</strong> Duas Fotos 3X4 (Fundo Branco e roupa escura – 
        Sexo Masculino de Terno e Gravata)
      </li>
      <li>
        <strong>7 –</strong> Declaração da Atividade Exercida pelo (a) Candidato (a) – Atividade Pública ou Privada.
      </li>
      <li>
        <strong>8 -</strong> Taxa de Inscrição:
        <a href="https://oabsergipe.org.br/taxas/" target="_blank" rel="noreferrer"> Link</a>
      </li>
      <li>
        <strong>9 -</strong> Publicação do Edital de Inscrição no Diário Oficial DJ (após entrega de toda documentação)
      </li>
      <li>
        <strong>10 -</strong> Certidões Negativas do Tribunal de Justiça do Estado de Sergipe: <br /> 
        <strong>a.</strong>Ação Cível: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Ação Criminal: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>11 -</strong> Certidão Negativa de distribuição de ações e execuções na Justiça Federal: <br /> <br />
        <strong>a.</strong>Tribunal Regional Federal da 5ª Região: <a href="https://www4.trf5.jus.br/certidoes/" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>12 -</strong> Certidões do Tribunal Regional Eleitoral de Sergipe: <br />
        <strong>a.</strong>Negativa de Crimes Eleitorais: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-de-crimes-eleitorais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Quitação Eleitoral: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-quitacao-eleitoral" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
        <strong>13 -</strong> Atestado de Antecedentes Criminais <br />
        <strong>a.</strong>Polícia Federal: <a href="http://www.pf.gov.br/servicos-pf/antecedentes-criminais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>SSP/SE: <a href="http://187.17.2.52/antecedentes/" target="_blank" rel="noreferrer">Link</a>
      </li>
    </ul>
    </div>
    <div className="obs">
      <span>OBS:<p> Não ter sido condenado por sentença transitado e julgado em processo criminal, 
        salvo por crime que não importe em incapacidade moral.</p> 
      </span> 
      <strong>*fotocópia autenticada em cartório</strong>
    </div>
  </div>
  );
}
function fourthCard(){
  return(
    <div id="info-card">
    <div className="list">
    <span className="title">Documentos necessários:</span>
    <ul>
      <li>
        <strong>1 –</strong> Carteira de identidade (fotocópia*)
      </li>
      <li>
       <strong> 2 –</strong> Carteira de reservista (fotocópia*) 
      </li>
      <li>
        <strong>3 –</strong> Comprovante de Residência
      </li>
      <li>
        <strong>4 –</strong> Titulo de eleitor (fotocópia*)
      </li>
      <li>
        <strong>5 –</strong> C.P.F. (fotocópia*)
      </li>
      <li>
        <strong>6 –</strong> Três fotos 3×4 (Fundo Branco e roupa escura – sexo masculino de terno e gravata)
      </li>
      <li>
        <strong>7 –</strong> Certidão de Matrícula (fotocópia*) no curso de Bacharelado em Direito (Atestado da Universidade – a partir do 7º período)
      </li>
      <li>
        <strong>8 –</strong> Declaração da Atividade Exercida pelo (a) Candidato (a) – Atividade Pública ou Privada.
      </li>
      <li>
        <strong>9 –</strong> Declaração de admissão em Estágio Profissional de Advocacia
      </li> 
      <li>
        <strong>10 -</strong> Declaração de admissão em Estágio Profissional de Advocacia
      </li>
      <li>
        <strong>11 -</strong> Taxa de Inscrição:
        <a href="https://oabsergipe.org.br/taxas/" target="_blank" rel="noreferrer"> Link</a>
      </li>
      <li>
        <strong>12 -</strong> Publicação do Edital de Inscrição no Diário Oficial DJ (após entrega de toda documentação)
      </li>
      <li>
      <strong>13 -</strong> Certidões Negativas do Tribunal de Justiça do Estado de Sergipe: <br /> 
        <strong>a.</strong>Ação Cível: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>Ação Criminal: <a href="http://www.tjse.jus.br/portal/judiciais/certidao-online/solicitacao-de-certidao-negativa" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
      <strong>14 -</strong> Certidão Negativa de distribuição de ações e execuções na Justiça Federal: <br /> <br />
        <strong>a.</strong>Tribunal Regional Federal da 5ª Região: <a href="https://www4.trf5.jus.br/certidoes/" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
      <strong>15 -</strong> Certidões do Tribunal Regional Eleitoral de Sergipe: <br />
      <strong>a.</strong>Negativa de Crimes Eleitorais: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-de-crimes-eleitorais" target="_blank" rel="noreferrer">Link</a> <br />
      <strong>b.</strong>Quitação Eleitoral: <a href="https://www.tre-se.jus.br/eleitor/certidoes/certidao-quitacao-eleitoral" target="_blank" rel="noreferrer">Link</a>
      </li>
      <li>
      <strong>16 -</strong> Atestado de Antecedentes Criminais <br />
      <strong>a.</strong>Polícia Federal: <a href="http://www.pf.gov.br/servicos-pf/antecedentes-criminais" target="_blank" rel="noreferrer">Link</a> <br />
        <strong>b.</strong>SSP/SE: <a href="http://187.17.2.52/antecedentes/" target="_blank" rel="noreferrer">Link</a>
      </li>
    </ul>
    </div>
    <div className="obs">
      <span>OBS:<p> Não ter sido condenado por sentença transitado e julgado em processo criminal, 
        salvo por crime que não importe em incapacidade moral.</p> 
      </span> 
      <strong>*fotocópia autenticada em cartório</strong>
    </div>
  </div>
  );
};


export {firstCard,  secondCard, thirdCard, fourthCard };
import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link } from "react-router-dom";
import banner_banese from "../../assets/images/banner_banese.png";
import "./styles.scss";

const ModalPopup = () => {
  const [showModal, setShowModal] = useState(true);
  console.log(showModal);
  if (showModal === false) {
    return null;
  }
  return (
    <div className="modalPopup">
      <Popup
        trigger={
          <div>
            <button
              onClick={() => setShowModal(false)}
              className="button-fechar"
            >
              <p className="text-fechar">Fechar</p>
            </button>
            <Link to="/oabBanese/oabBanese" target="_blank">
              <img className="img_banner_banese" src={banner_banese} />
            </Link>
          </div>
        }
      ></Popup>
    </div>
  );
};
export default ModalPopup;

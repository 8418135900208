import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { Title } from "../../components";
import InputSelect from "../FaleConosco/Components/InputSelect";
import Input from "../FaleConosco/Components/Input";
import "./Inss.scss";
import manual from "../../assets/arquivos/manual-de-utilizacao-inss.pdf";
import termo1 from "../../assets/arquivos/termo-sigilo-inss.doc";
import termo2 from "../../assets/arquivos/termo-inss.doc";
import data from "./data";

const Inss = () => {
  const [anexo, setAnexo] = useState("");

  const formRef = useRef(null);

  const [mostrar, setMostrar] = useState(false);

  const handleSubmit = async (data, { reset }) => {
    try {
      const formdata = new FormData();
      formdata.append("file", anexo);
      formdata.append("subject", "Email de contato da OAB");
      formdata.append(
        "html",
        `<p><b style="color: #777bd9">Nome: </b>${data.nome}</p>
            <p><b style="color: #777bd9">Registro: </b>${data.registro}</p>
            <p><b style="color: #777bd9">Cpf: </b>${data.cpf}</p>
            <p><b style="color: #777bd9">Email: </b>${data.email}</p>
            <p><b style="color: #777bd9">Endereço: </b>${data.endereco}</p>
            <p><b style="color: #777bd9">Bairro: </b>${data.bairro}</p>
            <p><b style="color: #777bd9">Número: </b>${data.numero}</p>
            <p><b style="color: #777bd9">Complemento: </b>${data.complemento}</p>
            <p><b style="color: #777bd9">Cidade: </b>${data.cidade}</p>
            <p><b style="color: #777bd9">Estado: </b>${data.estado}</p>
            <p><b style="color: #777bd9">Cep: </b>${data.cep}</p>
            <p><b style="color: #777bd9">País: </b>${data.pais}</p>
            <p><b style="color: #777bd9">Telefone Fixo: </b>${data.tel_fixo}</p>
            <p><b style="color: #777bd9">Celular: </b>${data.celular}</p>`
      );

      await fetch("https://portaldoadvogado.herokuapp.com/api/v1/email", {
        method: "POST",
        headers: {
          Accept: "application/*",
          type: "formData",
        },
        body: formdata,
      }).then(
        function (res) {
          if (res.ok) {
            alert("E-mail enviado com sucesso!");
          } else if (res.status === 401) {
            alert("Não possui permissão !");
          }
        },
        function (e) {
          alert(
            "Houve um erro no envio do formulário, tente novamente mais tarde !"
          );
        }
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
        console.log(errorMessages);
      } else {
        alert(
          "Desculpe, mas parece que houve algum problema ao enviar seu formulário. Tente novamente mais tarde."
        );
        console.log(err);
      }
    }
  };

  return (
    <div id="inss">
      <section id="section-1">
        <Title
          textContent="Formulário INSS DIGITAL"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;HOME >"
          link3="&nbsp;Formulário INSS DIGITAL"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
        <h3 className="title-inss-digital">INSS DIGITAL</h3>
        <div className="inss-geral">
          <p className="inss-text">
            <b>
              Advogados(as) inscritos(as) e ativos(as) na Ordem podem se
              cadastrar no INSS DIGITAL.
            </b>
            <br />
            <br />A Ordem dos Advogados do Brasil – Seccional Sergipe e o
            Instituto Nacional do Seguro Social (INSS) assinaram um Acordo de
            Cooperação Técnica para a implementação do INSS Digital para a
            advocacia sergipana. O advogado poderá realizar vários procedimentos
            pelo sistema do INSS na internet.
            <br />
            <br />
            Para isso, as advogadas e os advogados deverão fazer a solicitação
            de cadastramento na OAB/SE, preenchendo, Formulário "Requerimento de
            Cadastro no INSS DIGITAL" e o "Termo de Compromisso e Manutenção de
            Sigilo".
            <br />
            <br />
            Quem pode ter acesso
            <br />
            <br />
            Advogados(as) inscritos(as) e ativos(as) na Ordem dos Advogados do
            Brasil - Seccional de Sergipe e que não tenham sido punidos por
            infração ética no Tribunal de Ética e Disciplina da OAB/SE.
            <br />
            <br />
            <b>Como se cadastrar</b>
            <br />
            <br />
            <b>1. Preencher o formulário disponível abaixo.</b>
            <br />
            <b>
              2. Preencher e assinar o "Termo de Compromisso e Manutenção de
              Sigilo" e anexar ao formulário e em seguida clicar em enviar.
            </b>
            <br />
            <br />
            Após o envio a OAB/SE realizara o cadastramento do(a) mesmo(a) no
            INSS DIGITAL. No prazo até de <b>1 (um) dia útil.</b>
            <br />
            <br />
            Após o cadastramento, o(a) advogado(a) receberá um e-mail (enviado
            pelo INSS, com o assunto “não responda Dataprev”) com uma senha
            provisória, o prazo para alteração da senha é de até{" "}
            <b>24 horas após o recebimento,</b> que deverá ser feita pelo
            próprio(a) advogado(a). A partir daí todo o procedimento deverá ser
            realizado através do sistema do INSS DIGITAL.
          </p>
          <br />
          <p className="inss-text">
            <b>Requerimentos</b>
            <br />
            <br />
            <b>
              • Termo de Compromisso e Manutenção de Sigilo, documento
              obrigatório -{" "}
              <a
                href={termo1}
                target="_blank"
                rel="noreferrer"
                className="link-inss"
              >
                baixe aqui
              </a>
            </b>
            <br />
            <b>
              • Termo de Representação e Autorização de Acesso as Informações
              previdenciárias, documento obrigatório -{" "}
              <a
                href={termo2}
                target="_blank"
                rel="noreferrer"
                className="link-inss"
              >
                baixe aqui
              </a>
            </b>
            <br />
            <br />
          </p>
          <p className="inss-text">
            <b>Manual</b>
            <br />
            <br />
            <b>
              • Manual de utilização do sistema INSS,{" "}
              <a
                href={manual}
                target="_blank"
                rel="noreferrer"
                className="link-inss"
              >
                baixe aqui
              </a>
            </b>
            <br />
            <br />
            <b>Acesso ao INSS</b>
            <br />
            <br />
            <b>
              • Para acessar no sistema do INSS DIGITAL, clique em um dos links
              abaixo:
            </b>
            <br />
            <b>
              <a
                href="https://novorequerimento.inss.gov.br/"
                target="_blank"
                rel="noreferrer"
                className="link-inss"
              >
                novorequerimento.inss.gov.br
              </a>
            </b>
            <b> ou </b>
            <b>
              <a
                href="https://geridinss.dataprev.gov.br/"
                target="_blank"
                rel="noreferrer"
                className="link-inss"
              >
                requerimento.inss.gov.br
              </a>
            </b>
            <br />
            <br />
            <b>Problemas de acesso ao sistema INSS Digital</b>
            <br />
            <br />
            Se está com problemas para acessar o sistema do INSS Digital, tente
            as seguintes opções:
            <br />
            <br />
            <b>•</b> Digitar um dos endereços do sistema
            (requerimento.inss.gov.br ou novorequerimento.inss.gov.br) no
            navegador, e não apenas clicar em um link com os endereços já
            salvos;
            <br />
            <b>•</b> Tentar acessar o sistema em navegadores diferentes(Google
            Chrome, Mozilla Firefox, etc);
            <br />
            <b>•</b> Apagar o histórico, cache e cookies do navegador;
            <br />
            <b>•</b> Utilizar o sistema em uma janela anônima do seu navegador.
            <br />
            <br />
            <b>
              <i>SUPORTE INSS DIGITAL</i>
            </b>
            <br />
            <br />
            <b>OAB-SE - Cadastramento no INSS DIGITAL:</b>
            <br />
            <br />
            <b>(79) 33301-9129</b>
            <br />
            <br />
            <b>
              <i>POSTO AVANÇADO DO INSS / SISTEMA MEU INSS:</i>
            </b>
            <br />
            <br />O posto avançado do INSS na CAASE atenderá exclusivamente à
            advocacia, o agendamento deve ser efetuado somente por e-mail
            agendamento.inss@oabsergipe.org.br
          </p>
          <button
            className="button-inss"
            onClick={() => {
              setMostrar(!mostrar);
            }}
          >
            Mostrar/Ocultar Formulário
          </button>
          {mostrar && (
            <div className="container-form">
              <Form ref={formRef} className="form-inss" onSubmit={handleSubmit}>
                <label className="label">
                  Nome: *
                  <br />
                  <Input className="input-inss" type="text" name="nome" required />
                </label>

                <label className="label">
                  Registro OAB nº: *
                  <br />
                  <Input className="input-inss" type="text" name="registro" required/>
                </label>

                <label className="label">
                  CPF: *
                  <br />
                  <Input className="input-inss" type="text" name="cpf" required />
                </label>

                <label className="label">
                  E-mail: *
                  <br />
                  <Input className="input-inss" type="text" name="email" required />
                </label>

                <label className="label">
                  Endereço: *
                  <br />
                  <Input className="input-inss" type="text" name="endereco" required />
                </label>

                <label className="label">
                  Bairro: *
                  <br />
                  <Input className="input-inss" type="text" name="bairro" required />
                </label>

                <label className="label">
                  Complemento:
                  <br />
                  <Input
                    className="input-inss"
                    type="text"
                    name="complemento"
                    required
                  />
                </label>

                <label className="label">
                  Número: *
                  <br />
                  <Input className="input-inss" type="text" name="numero" required />
                </label>

                <label className="label">
                  Cidade: *
                  <br />
                  <Input
                    className="input-inss"
                    type="text"
                    name="cidade"
                    pattern="[a-zA-Z\s]+"
                    title="Apenas letras"
                    required
                  />
                </label>
                <label className="label">
                  Cep: *
                  <br />
                  <Input className="input-inss" type="text" name="cep" required />
                </label>

                <label className="label">
                  Estado: *
                  <br />
                  <InputSelect
                    className="select-inss"
                    options={data}
                    name="estado"
                    placeholder="Escolha seu Estado"
                    required
                  />
                </label>

                <label className="label">
                  País: *
                  <br />
                  <Input
                    className="input-inss"
                    type="text"
                    name="pais"
                    pattern="[a-zA-Z\s]+"
                    title="Apenas letras"
                    required
                  />
                </label>

                <label className="label">
                  Telefone Fixo:
                  <br />
                  <Input className="input-inss" type="text" name="tel_fixo"  />
                </label>

                <label className="label">
                  Telefone Celular: *
                  <br />
                  <Input className="input-inss" type="text" name="celular" required />
                </label>
                <label className="label">
                  TERMO DE COMPROMISSO DE MANUTENÇÃO DE SIGILO *
                  <br />
                  <input
                    type="file"
                    className="input-inss-anexo"
                    onChange={(event) => {
                      setAnexo(event.target.files[0]);
                      console.log(anexo);
                    }}
                    name="file"
                    required
                  />
                </label>
                <br />
                <button className="button-inss" type="submit">
                  Enviar
                </button>
              </Form>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Inss;

const data = [
  {
    value: "1",
    type: "00",
    label: "RESOLUÇÕES E PORTARIAS DO TED/OAB/SE",
  },
  {
    value: "2",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2016/03/regimeto.doc",
    label: "REGIMENTO INTERNO DA OAB/SE",
  },
  {
    value: "3",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2019/11/Regimento-Interno-TED-OAB-SE.pdf",
    label: "REGIMENTO INTERNO DO TED - OAB/SE",
  },
  {
    value: "4",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2016/03/codigo-de-etica.pdf",
    label: "CÓDIGO DE ÉTICA E DISCIPLINA DA OAB",
  },
  {
    value: "5",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2016/03/regulamento-geral.pdf",
    label: "REGULAMENTO GERAL DO ESTATUTO DA ADVOCACIA E DA OAB",
  },
  {
    value: "6",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2016/03/estatuto.pdf",
    label: "ESTATUTO DA ADVOCACIA E DA OAB E LEGISLAÇÃO COMPLEMENTAR",
  },
  {
    value: "7",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2019/05/Codigo-Etica-Disciplina.pdf",
    label: "NOVO CÓDIGO DE ÉTICA E DISCIPLINA DA OAB",
  },
  {
    value: "8",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2019/04/Provimento-n.-185.2018.pdf",
    label: "PROVIMENTO NÚMERO 185/2018",
  },
  {
    value: "9",
    type: "00",
    arquivo: "https://oabsergipe.org.br/wp-content/uploads/2016/03/lei_complementar86.pdf",
    label: "LEI COMPLEMENTAR Nº. 86",
  },
];

export default data;

import React, { useRef, useEffect } from 'react';
//libs
import Select from 'react-select';
import { useField } from '@unform/core';
//styles
import '../Input/styles.scss';

export default function InputSelect({ name, icon, options,label, multiple, margin, placeholder, className,...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div id='input'>
      <div className='form-group'>
          <label className='label'>
            {label}
          </label>
          <Select
            placeholder={placeholder}
            options={options}
            defaultValue={defaultValue}
            ref={selectRef}
            {...rest}
          />
        <img className="icon" src={icon} alt="" />
        {error && <span className='error'> {error}</span>}
        <div className={className} />
      </div>
    </div>
  );
};

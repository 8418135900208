/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  ContainerMenu,
  ContainerTitles,
  Icons,
  Input,
  Logo,
  Menu,
  NavBar,
  Titles,
  Overlay,
} from "./header.element";

import logo from "../../assets/images/Header/Logo_OAB_top.png";
import logoMobile from "../../assets/images/Header/Logo_OAB_menu-mobile.png";
import face from "../../assets/images/Header/face.svg";
import insta from "../../assets/images/Header/insta.svg";
import linkedin from "../../assets/images/Header/linkedin.svg";
import youtube from "../../assets/images/Header/youtube.svg";
import twitter from "../../assets/images/Header/twitter.svg";
import banner from "../../assets/images/Header/Banner-Sessoes.png";

import { FiBarChart, FiX } from 'react-icons/fi'

import "./styles.scss";
import { useContextSearch } from '../../hooks/ContextSearch';

const itensMenu = {
  title: "A OAB/SE",
  title2: "INSTITUCIONAL",
  link2: "#",
  title3: "SECRETARIA",
  link3: "#",
  title4: "TESOURARIA",
  link4: "#",
  title5: "ALVARÁS",
  link5: "#",
  title6: "PUBLICAÇÕES",
  link6: "#",
};

const Header = () => {
  const [click, setClick] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);
  const [flags, setFlags] = useState(true);
  const [itens, setItens] = useState(itensMenu);
  const [oab, setOab] = useState(true);
  const [institucional, setInstitucional] = useState(false);
  const [legislacoes, setLegislacoes] = useState(false);
  const [secretaria, setSecretaria] = useState(false);
  const [alvaras, setAlvaras] = useState(false);
  const [tesouraria, setTesouraria] = useState(false);
  const [publicacoes, setPublicacoes] = useState(false);
  const [diario, setDiario] = useState(false);

  const [voltarMenu, setVoltarMenu] = useState(false);
  const [seta, setSeta] = useState(false);
  const [linkDiario, setLinkDiario] = useState(false);
  //const [linkLegislacoes, setLinkLegislacoes] = useState(false);

  const handleClick = () => setClick(!click);
  const handleSearchClick = () => setClickSearch(!clickSearch);
  const { setText } = useContextSearch()

  const showFlags = () => {
    if (window.innerWidth <= 550) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  const handleOab = () => {
    setOab(true);
    setSecretaria(false);
    setInstitucional(false);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(false);
    setLegislacoes(false);
    setDiario(false);
    setItens({
      title: "A OAB/SE",
      title2: "INSTITUCIONAL",
      link2: "",
      title3: "SECRETARIA",
      link3: "",
      title4: "TESOURARIA",
      link4: "#",
      title5: "ALVARÁS",
      link5: "#",
      title6: "PUBLICAÇÕES",
      link6: "#",
    });
    setVoltarMenu(true);
    setSeta(false);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handleInstitucional = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(true);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(false);
    setDiario(false);
    setLegislacoes(true);
    setItens({
      title: "INSTITUCIONAL",
      title2: "Sobre a OAB/SE",
      link2: "/institucional/sobre",
      title3: "Comissões",
      link3: "/institucional/comissoes",
      title4: "Sessões do Conselho",
      link4: banner,
      title5: "Legislações",
    });
    setVoltarMenu(true);
    setSeta(true);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handleLegislacoes = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(false);
    setLegislacoes(true);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(false);
    setDiario(false);
    setItens({
      title: "Legislações",
      title2: "Legislação",
      link2: "/institucional/legislacoes",
      title3: "Ementário TED",
      link3: "/publicacoes/ementarioTED",
      title4: "Novo código de ética e disciplina da advocacia",
      link4: "https://www.oab.org.br/Content/pdf/novo-ced.pdf",
      title5: "",
      link5: "",
    });
    setVoltarMenu(false);
    setSeta(false);
    setLinkDiario(false);
    //setLinkLegislacoes(true);
  };
  const handleSecretaria = () => {
    setOab(false);
    setSecretaria(true);
    setInstitucional(false);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(false);
    setDiario(false);
    setLegislacoes(false);
    setItens({
      title: "SECRETARIA",
      title2: "Exame de Ordem",
      link2: "/secretaria/examedeordem",
      title3: "Cronograma entrega de carteiras",
      link3: "/secretaria/entregadecarteiras",
      title4: "Inscrição de profissional",
      link4: "/secretaria/inscricao-profissional",
      title5: "Sociedade de advogados",
      link5: "/secretaria/sociedade",
      title6: "CNA - Conselho Nacional de Advogados",
      link6: "https://cna.oab.org.br/",
    });
    setVoltarMenu(true);
    setSeta(false);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handleAlvaras = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(false);
    setAlvaras(true);
    setTesouraria(false);
    setPublicacoes(false);
    setDiario(false);
    setLegislacoes(false);
    setItens({
      title: "Alvarás",
      title2: "Banese",
      link2: "/alvaras/banese",
      title3: "Caixa Econômica Federal",
      link3: "/alvaras/caixa-economica",
      title4: "Banco do Brasil",
      link4: "/alvaras/banco-do-brasil",
    });
    setVoltarMenu(true);
    setSeta(false);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handleTesouraria = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(false);
    setAlvaras(false);
    setTesouraria(true);
    setPublicacoes(false);
    setDiario(false);
    setLegislacoes(false);
    setItens({
      title: "Tesouraria",
      title2: "Anuidade",
      link2: "http://190.15.114.122:8080/siscafweb/carregaConselho.do?tipoAcesso=2&sigla=oabse&impressaorapida=1",
      title3: "Taxas e Emolumentos",
      link3: "/tesouraria/taxaseemolumentos",
      title4: "Resolução",
      link4: "/tesouraria/resolucoes",
      title5: "Anuidades Anteriores 2ª Via",
      link5: "#",
      title6: "Transparência",
      link6: "/tesouraria/transparencia",
      title7: "",
      link7: "",
    });
    setVoltarMenu(true);
    setSeta(false);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handlePublicacoes = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(false);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(true);
    setDiario(true);
    setLegislacoes(false);
    setItens({
      title: "Publicações",
      title2: "Diário Oficial",
      link2: "#",
      title3: "Artigos",
      link3: "/publicacoes/artigos",
      title4: "Editais",
      link4: "/publicacoes/editais",
      title5: "TV OAB/SE",
      link5: "/publicacoes/TV-Oab",
      title6: "Piso salarial",
      link6: "/publicacoes/piso-salarial",
      title7: "Tabela de Honorários",
      link7: "/publicacoes/tabela-honorarios",
      title8: "Cartilhas",
      link8: "/publicacoes/cartilhas",
      title9: "Eleições 2021",
      link9: "/publicacoes/eleicoes",
    });
    setVoltarMenu(true);
    setSeta(true);
    setLinkDiario(false);
    //setLinkLegislacoes(false);
  };
  const handleDiario = () => {
    setOab(false);
    setSecretaria(false);
    setInstitucional(false);
    setAlvaras(false);
    setTesouraria(false);
    setPublicacoes(true);
    setDiario(true);
    setLegislacoes(false);
    setItens({
      title: "Diário Oficial",
      title2: "Diário eletrônico OAB",
      link2: "https://deoab.oab.org.br/pages/visualizacao?pagina=1",
      title3: "Diário OAB/SE",
      link3: "/publicacoes/diario/diario-oab",
    });
    setVoltarMenu(false);
    setSeta(false);
    setLinkDiario(true);
    //setLinkLegislacoes(false);
  };


  useEffect(() => {
    showFlags();
    console.log(seta)
  });

  window.addEventListener("resize", showFlags);
  const faleConoscoTab = document.querySelector("fale-conosco");
  let iconTab = document.querySelector("icon-2");
  let containerSubtitle = document.querySelector("container-subtitles");
  let containerTitles = document.querySelector("container-titles");
  let iconTab2 = document.querySelector("icon");
  let iconTab3 = document.querySelector("icon-3");
  let acessosTab = document.querySelector('acessos');

  useEffect(() => {
    var MenuOpen = document.getElementById("open-menu");
    var MenuTitles = document.getElementById("title");
    let openMenu = document.getElementById("menu");
    let container = document.getElementById("container-subtitles");
    let containerTitle = document.getElementById("container-titles");
    let icon = document.getElementById("icon");
    let borda = document.getElementsByClassName("border");

    let faleConosco = document.getElementById('fale-conosco');
    let icon2 = document.getElementById("icon-2");
    const faleConoscoTitle = document.getElementById("fale-conosco-title");

    let acessos = document.getElementById('acessos');
    let icon3 = document.getElementById("icon-3");
    const acessostitle = document.getElementById("acessos-title");

    const listTitleMenu = document.getElementsByClassName("title-menu");
    const TitleMenu = [].slice.call(listTitleMenu);

    let input = document.getElementById("input-search");
    // let iconSearch = document.getElementById('icon-search');

    TitleMenu.map((item, index) => {
      item.addEventListener("mouseover", () => {
        listTitleMenu[index].classList.add("title-hover");
      });
      item.addEventListener("mouseout", () => {
        listTitleMenu[index].classList.remove("title-hover");
      });
    });

    MenuOpen.addEventListener("click", () => {
      if (openMenu.classList.contains("menu-show")) {
        containerTitle?.classList?.add("container-titles");
        container?.classList?.add("container-subtitles");
        containerTitle?.classList?.remove("container-titles-show");
        container?.classList?.remove("container-show");
        icon?.classList?.remove("rotate");
        icon?.classList?.add("icon-title");
        MenuTitles?.classList?.remove("background");
        borda[0]?.classList?.remove("border-show");
        input?.classList?.remove("show-input");
        input?.classList?.add("input");
        icon2?.classList?.add("icon-title");
        icon2?.classList?.remove("rotate");
        faleConosco?.classList?.remove('container-show-2');
        faleConosco?.classList?.add('container-subtitles-2');
        icon3?.classList?.add("icon-title");
        icon3?.classList?.remove("rotate")
        acessos?.classList?.remove('container-show-3');
        acessos?.classList?.add('container-subtitles-3');
        handleOab();
      }
    });

    MenuTitles.addEventListener("click", () => {
      if (
        container.classList.contains("container-subtitles") &&
        containerTitle.classList.contains("container-titles")
      ) {
        container.classList.remove("container-subtitles");
        container.classList.add("container-show");
        containerTitle.classList.remove("container-titles");
        containerTitle.classList.add("container-titles-show");
        icon.classList.remove("icon-title");
        icon.classList.add("rotate");
        MenuTitles.classList.add("background");
        borda[0]?.classList?.add("border-show");
        icon2?.classList?.add("icon-title");
        icon2?.classList?.remove("rotate");
        faleConosco?.classList?.remove('container-show-2');
        faleConosco?.classList?.add('container-subtitles-2');
        icon3?.classList?.add("icon-title");
        icon3?.classList?.remove("rotate")
        acessos?.classList?.remove('container-show-3');
        acessos?.classList?.add('container-subtitles-3');
      } else if (
        container.classList.contains("container-show") &&
        containerTitle.classList.contains("container-titles-show")
      ) {
        container.classList.remove("container-show");
        container.classList.add("container-subtitles");
        containerTitle.classList.remove("container-titles-show");
        containerTitle.classList.add("container-titles");
        icon.classList.remove("rotate");
        icon.classList.add("icon-title");
        MenuTitles.classList.remove("background");
        borda[0]?.classList?.remove("border-show");
      }
    });

    faleConoscoTitle.addEventListener('click', () => {
      if (faleConosco.classList.contains("container-subtitles-2")) {
        icon2?.classList?.toggle("icon-title");
        icon2?.classList?.toggle("rotate");
        //faleConosco?.classList?.remove('container-subtitles-2');
        faleConosco?.classList?.toggle('container-show-2');
        container?.classList?.remove("container-show");
        container?.classList?.add("container-subtitles");
        containerTitle?.classList?.remove("container-titles-show");
        containerTitle?.classList?.add("container-titles");
        icon?.classList?.remove("rotate");
        icon?.classList?.add("icon-title");
        icon3?.classList?.add("icon-title");
        icon3?.classList?.remove("rotate");
        acessos?.classList?.remove('container-show-3');
        acessos?.classList?.add('container-subtitles-3');
        handleOab()
      } else if (faleConosco.classList.contains("container-show-2")) {
        icon2?.classList?.add("icon-title");
        icon2?.classList?.remove("rotate");
        faleConosco?.classList?.remove('container-show-2');
        faleConosco?.classList?.add('container-subtitles-2');
      }
    })

    acessostitle.addEventListener('click', () => {
      if (acessos.classList.contains("container-subtitles-3")) {
        icon3?.classList?.toggle("icon-title");
        icon3?.classList?.toggle("rotate");
        //acessos?.classList?.remove('container-subtitles-3');
        acessos?.classList?.toggle('container-show-3');
        container?.classList?.remove("container-show");
        container?.classList?.add("container-subtitles");
        containerTitle?.classList?.remove("container-titles-show");
        containerTitle?.classList?.add("container-titles");
        icon?.classList?.remove("rotate");
        icon?.classList?.add("icon-title");
        icon2?.classList?.add("icon-title");
        icon2?.classList?.remove("rotate");
        faleConosco?.classList?.remove('container-show-2');
        faleConosco?.classList?.add('container-subtitles-2');
        handleOab()
      } else if (acessos.classList.contains("container-show-3")) {
        icon3?.classList?.add("icon-title");
        icon3?.classList?.remove("rotate");
        acessos?.classList?.remove('container-show-3');
        acessos?.classList?.add('container-subtitles-3');
      }
    })
  }, [oab]);

  useEffect(() => {
    let openMenu = document.getElementById('open-menu')
    let bordaList = document.getElementById('semborda');

    openMenu.addEventListener("mouseover", () => {
      bordaList?.classList?.add('borda')
    })
    openMenu.addEventListener("mouseout", () => {
      bordaList?.classList?.remove('borda')
    })
  })

  return (
    <>
      <Overlay onClick={handleClick} style={{ display: !click && "none" }} />
      <Container>
        <NavBar>
          <Logo>
            <Link to='/' className='link'>
              <img src={logo} alt='logo-OAB' />
            </Link>
          </Logo>
          <Titles id='open-menu' onClick={handleClick} className={click && !flags && "opacity"}>
            <div id="semborda" className="semborda" />
            <p className="textMenu">{flags && "Menu"}</p>
            {click ? <FiX className="menu-icon" /> : <FiBarChart className="menu-icon rotate-menu" />}
          </Titles>
        </NavBar>
        <Menu id='menu' className={click ? "menu-show" : "menu"}>
          {!flags &&
            <Titles
              id='open-menu'
              onClick={handleClick}
              className={click ? "open-menu-mobile-show" : "open-menu-mobile"}
            >
              {click && <FiX className="menu-icon" />}
            </Titles>
          }
          {!flags && (
            <>
              <div className='icons'>
                <Icons>
                  <img src={face} alt='facebook' />
                </Icons>
                <Icons>
                  <img src={insta} alt='instagram' />
                </Icons>
                <Icons>
                  <img src={linkedin} alt='linkedin' />
                </Icons>
                <Icons>
                  <img src={twitter} alt='twitter' />
                </Icons>
                <Icons>
                  <img src={youtube} alt='youtube' />
                </Icons>
              </div>
            </>
          )}
          <div className='search'>
            <i
              id='icon-search'
              class='fas fa-search'
              onClick={handleSearchClick}
            ></i>
            <Input
              id='input-search'
              className={clickSearch ? "show-input" : "input"}
              onChange={(event) => setText(event.target.value)}
            />
          </div>
          <ContainerMenu>
            <Titles id='title' className='title-menu'
              onClick={voltarMenu ? handleOab : (legislacoes ? handleInstitucional : (diario ? handlePublicacoes : null))}>
              <div id='icon' className='icon-title'>
                <i className='fas fa-chevron-down'></i>
              </div>
              {itens.title} {!flags && <span className='border' />}
            </Titles>
            <ContainerTitles
              id='container-subtitles'
              className="container-subtitles"
            >
              <Titles
                className='subtitles'
                onClick={
                  secretaria ||
                    alvaras ||
                    tesouraria || legislacoes
                    ? null :
                    !publicacoes ?
                      handleInstitucional :
                      (diario &&
                        handleDiario) || (legislacoes && handleLegislacoes)
                }
              >
                {tesouraria || linkDiario ?
                  <a href={itens.link2} target="_blank" rel="noopener noreferrer" className='link' >{itens.title2}</a>
                  : <Link
                    to={
                      institucional ||
                        secretaria ||
                        alvaras ||
                        tesouraria ||
                        publicacoes ||
                        diario ||
                        legislacoes
                        ? itens.link2 : "#"}
                    className='link'
                  >
                    {!institucional && (oab || seta) ?
                      <div id='icon-submenu' className='icon-submenu'>
                        <i className='fas fa-chevron-down'></i>
                      </div> : null
                    }
                    {itens.title2}
                  </Link>}
              </Titles>
              <Titles
                className='subtitles'
                onClick={
                  institucional ||
                    alvaras ||
                    tesouraria ||
                    publicacoes ||
                    diario || legislacoes // aqui impede que ao clicar em ementario ted troque a tab
                    ? null : handleSecretaria}
              >
                <Link
                  to={
                    institucional ||
                      secretaria ||
                      alvaras ||
                      tesouraria ||
                      publicacoes ||
                      legislacoes
                      ? itens.link3 : "#"}
                  className='link'
                >
                  {oab ?
                    <div id='icon-submenu' className='icon-submenu'>
                      <i className='fas fa-chevron-down'></i>
                    </div> : null
                  }
                  {itens.title3}
                </Link>
              </Titles>
              <Titles
                className='subtitles'
                onClick={
                  institucional ||
                    alvaras ||
                    secretaria ||
                    publicacoes || legislacoes //impede que ao clicar em novo codigo de etica abra outra tab
                    ? null : handleTesouraria}
              >
                {legislacoes && !oab ?
                    <a href={itens.link4} target="_blank" rel="noopener noreferrer" className='link' >{itens.title4}</a>
                  :
                  <Link
                    to={institucional ||
                      secretaria ||
                      alvaras ||
                      tesouraria ||
                      publicacoes ||
                      legislacoes
                      ? itens.link4 : "#"}
                    className='link'
                  >
                    {oab ?
                      <div id='icon-submenu' className='icon-submenu'>
                        <i className='fas fa-chevron-down'></i>
                      </div> : null
                    }
                    {itens.title4}
                  </Link>}
              </Titles>
              <Titles className='subtitles'
                onClick={
                  secretaria ||
                    tesouraria ||
                    publicacoes
                    ? null : legislacoes && institucional ? handleLegislacoes : handleAlvaras}
              >
                {tesouraria ?
                  <a href="http://190.15.114.122:8080/siscafweb/carregaConselho.do?tipoAcesso=2&sigla=oabse&ini=1" target="_blank" rel="noopener noreferrer" className='link'>
                    {itens.title5}
                  </a>
                  : <Link
                    to={institucional ||
                      secretaria ||
                      alvaras ||
                      tesouraria ||
                      publicacoes ||
                      legislacoes
                      ? itens.link5 : "#"}
                    className='link'
                  >
                    {!publicacoes && (oab || seta) ?
                      <div id='icon-submenu' className='icon-submenu'>
                        <i className='fas fa-chevron-down'></i>
                      </div> : null
                    }
                    {itens.title5}
                  </Link>}
              </Titles>
              <Titles
                onClick={institucional ||
                  secretaria ||
                  tesouraria ||
                  alvaras
                  ? null : handlePublicacoes}
                className='subtitles'>
                {secretaria ?
                  <a href={itens.link6} target="_blank" rel="noopener noreferrer" className='link' >{itens.title6}</a>
                  : <Link to={
                    institucional ||
                      secretaria ||
                      alvaras ||
                      tesouraria ||
                      publicacoes
                      ? itens.link6 : "#"
                  } className='link'>
                    {oab ?
                      <div id='icon-submenu' className='icon-submenu'>
                        <i className='fas fa-chevron-down'></i>
                      </div> : null
                    }
                    {itens.title6}
                  </Link>}
              </Titles>
              <Titles className='subtitles'>
                <Link to={
                  institucional ||
                    secretaria ||
                    alvaras ||
                    tesouraria ||
                    publicacoes
                    ? itens.link8 : "#"} className='link'>
                  {itens.title8}
                </Link>
              </Titles>
              <Titles className='subtitles'>
                <Link to={
                  institucional ||
                    secretaria ||
                    alvaras ||
                    tesouraria ||
                    publicacoes
                    ? itens.link9 : "#"} target="_blank" className='link'>
                  {itens.title9}
                </Link>
              </Titles>
            </ContainerTitles>
            <ContainerTitles id='container-titles' className='container-titles'>
              <Titles className='title-menu'>
                <a href="https://esa-hg.web.app/" target="_blank" rel="noopener noreferrer" className='link-menu'>
                  ESA
                </a>
              </Titles>
              <Titles className='title-menu'>
                <a href='https://caase.com.br/' target="_blank" rel="noopener noreferrer" className='link-menu'>
                  CAA/SE
                </a>
              </Titles>
              <Titles className='title-menu' style={{ textTransform: "uppercase" }}>
                <Link to='/institucional/tribunal-de-etica' className='link-menu'>
                  Tribunal de Ética
                </Link>
              </Titles>
              {/*<Titles className='title-menu' style={{ textTransform: "uppercase" }}>
              <a href='https://portal-oab-homologacao.web.app/' target="_blank" rel="noopener noreferrer" className='link-menu'>
                Portal da Advocacia
              </a>
              </Titles>*/}
              <Titles id="acessos-title" className='title-menu'>
                <div id='icon-3' className='icon-title'>
                  <i className='fas fa-chevron-down'></i>
                </div>
                ACESSOS EXTERNOS
              </Titles>
              <ContainerTitles id="acessos" className="container-subtitles-3">
                <Titles className="subtitles">
                  <a href="https://www.acoab.com.br/home"
                    target="_blank" rel="noopener noreferrer" className="link">
                    Certificado Digital
                  </a>
                </Titles>
                <Titles className="subtitles">
                  <a href="https://www6.oab.org.br/sgd/livre/consulta/processual/seccional/f8ede15a-aa0a-4942-bf45-308bfaf4b7a0"
                    target="_blank" rel="noopener noreferrer" className="link">
                    Consulta Processual
                  </a>
                </Titles>
                <Titles className="subtitles">
                  <a href="https://www1.oab.org.br/Login/?aplicacao=34&ReturnUrl=https://www6.oab.org.br/novoedespacho"
                    target="_blank" rel="noopener noreferrer" className="link">
                    Despacho Relator
                  </a>
                </Titles>
                <Titles className="subtitles">
                  <a href="https://www.tjse.jus.br/portal/servicos/judiciais/seeu-sistema-eletronico-de-execucao-unificado"
                    target="_blank" rel="noopener noreferrer" className="link">
                    SEEU - Sistema Eletrônico de Execução Unificado
                  </a>
                </Titles>
                <Titles className="subtitles">
                  <a href="/oabbanese/oabbanese"
                    target="_blank" rel="noopener noreferrer" className="link">
                    Banese Card
                  </a>
                </Titles>
              </ContainerTitles>
              <Titles className='title-menu'>
                <Link to='/blog/principal' className='link-menu'>
                  NOTÍCIAS
                </Link>
              </Titles>
              <Titles
                id="fale-conosco-title"
                className='title-menu'
              >
                <div id='icon-2' className='icon-title'>
                  <i className='fas fa-chevron-down'></i>
                </div>
                FALE CONOSCO
              </Titles>
              <ContainerTitles id="fale-conosco" className="container-subtitles-2">
                <Titles className="subtitles">
                  <Link to="/fale-conosco/ouvidoria" className="link">
                    Ouvidoria
                  </Link>
                </Titles>
                <Titles className="subtitles">
                  <Link to="/fale-conosco/defesa-prerrogativas" className="link">
                    Defesa das Prerrogativas
                  </Link>
                </Titles>
                <Titles className="subtitles">
                  <Link to="/fale-conosco/loctel" className="link">
                    Localização e Telefones
                  </Link>
                </Titles>
              </ContainerTitles>
            </ContainerTitles>
          </ContainerMenu>
          {!flags &&
            <>
              <Logo className='logo'>
                <Link to='/' className='link'>
                  <img src={flags ? logo : logoMobile} alt='logo-OAB' />
                </Link>
              </Logo>
            </>
          }
        </Menu>
      </Container>
    </>
  );
};

export default Header;

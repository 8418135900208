import "./App.scss";
import GlobalStyle from "./globalStyles";
import ScrollToTop from "./components/ScrollToTop";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from './store'

import { ContextSearchProvider } from "./hooks/ContextSearch";

//componentes
import { Header, Footer } from "./components";

import "swiper/swiper.scss";
import Routes from "./Routes";
import {Provider} from "react-redux";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ContextSearchProvider>
            <GlobalStyle />
            <ScrollToTop />
            <Header />
            <Routes />
            <div
              style={{ backgroundColor: "#101e38" }}
              className="pt-5 d-flex flex-column min-vh-100"
            >
              <Footer />
            </div>
          </ContextSearchProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;

{
  /* <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/institucional/tribunal-de-etica"
          component={TribunalEtica}
        />
        <Route exact path="/institucional/comissoes" component={Comissao} />
        <Route exact path="/institucional/legislacoes" component={Legislacao} />
        <Route exact path="/institucional/sobre" component={Sobre} />
        <Route exact path="/institucional/tesouraria/resolucoes" component={Resolucoes} />

        <Route
          exact
          path="/secretaria/entregadecarteiras"
          component={EntregadeCarteiras}
        />
        <Route exact path="/secretaria/examedeordem" component={ExameOrdem} />


        <Route exact path="/tesouraria/taxaseemolumentos" component={TaxaseEmolumentos} />

      </Switch>
      <div
        style={{ backgroundColor: "#101e38" }}
        className="pt-5 d-flex flex-column min-vh-100"
      >
        <Footer />
      </div>
    </Router>
  );
};

        <Route exact path="/alvaras/banco-do-brasil" component={BancoDoBrasil} />
        <Route exact path="/alvaras/caixa-economica" component={CaixaEconomica} />
        <Route exact path="/alvaras/banese" component={Banese} />
        <Route exact path="/tesouraria/tabela-honorarios" component={TabelaHonorarios} />


      </Switch> */
}

import React from "react";

import "./styles.scss"

import { Title } from "../../components"
import estatua from "../../assets/images/Memorial/Desktop/mask-picture.png"
import fundoEstatua from "../../assets/images/Memorial/Desktop/div-pattern.png"

import { Galery } from "./components/Galery"

export const Memorial = () => {
  return (
    <div className="memorial">
      <section id="section-1">
        <Title
          textContent='SOBRE A OAB/SE'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;INSTITUCIONAL >'
          link3='&nbsp;SOBRE A OAB/SE'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      <section id="section-2">
        <div id="blueBg">
          <div id="content">
            <h2>A OAB/SE, como forma de resgatar a Memória e Identidade da sua Seccional,</h2>
            <p>
              funda seu Memorial em 11/05/2010 (data alusiva às comemorações dos 75 anos de existência em nosso Estado), num prédio secular datado desde o inicio do século XX, tombado pelo Patrimônio Histórico desde 1989 através do Decreto 11.189/1989, que o classifica como “um dos mais significativos monumentos ligados à história da arquitetura sergipana.
            </p>
          </div>
        </div>
        <div id="estatua">
          <img src={estatua} alt="" />
        </div>

      </section>
      <section id="section-3">
        <div id="content">
          <h3>O imóvel Construído entre 1917 e 1919,</h3>
          <p> por <strong>Adolpho de Faro Rollemberg </strong> (homem rico, neto dos barões das cidades de Japaratuba e Estância), e localizado à Avenida Ivo do Prado, 1072 no Bairro São Jose, abriga atualmente a atual sede Administrativa da OAB/SE, e foi cognominado por seus pares de <strong>“Palácio da Cidadania”.</strong> </p>
        </div>
        <div id="text-1">
          <p>
            ímpar no cenário arquitetônico do Estado, sua estrutura física é bastante eclética,
          </p>
        </div>
        <div id="text-2">
          <p>
            apresentando uma combinação de diferentes estilos históricos <strong>(Art Nouveau)</strong>, com mobiliário requintado, além de um belo jardim que valoriza todo o seu conjunto arquitetônico,
          </p>
        </div>
        <div id="text-3">
          <p>
            e uma bela varanda coberta por azulejaria portuguesa, e piso de ladrilho hidráulico
          </p>
        </div>
      </section>
      <section id="section-4">
        <div id="content">
          <div id="content-1">
            <h3>
              Contando com o auxílio da Curadoria do Memorial da OAB
            </h3>
            <p>
              que subsidia as atividades museais realizadas, o Memorial da Advocacia Sergipana, ocupa os 2º e 3º pavimentos do Palácio.
            </p>
          </div>
          <div id="content-2">
            <p>
              Nestes pavimentos, está exposta uma parte do acervo histórico da Seccional, além das dezenas de homenagens que são prestadas àqueles que contribuíram para o engrandecimento da militância forense em nosso Estado. Milhares de visitantes já passaram por aqui para prestigiar nossa História.
            </p>
          </div>
        </div>
        <Galery />

      </section>
      <section id="section-5">
        <h2>Como entrar em contato</h2>
        <p>Para agendamento, os interessados deverão enviar previamente uma solicitação para o e-mail <strong>presidência@oabse.org.br</strong>, contendo nome da Instituição de Ensino, quantitativo de alunos, docente responsável e horário. Para quaisquer dúvidas entrar em contato através dos telefones <strong>(79) 3301-9127.</strong></p>
      </section>
    </div >
  );
}
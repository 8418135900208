import React, { createContext, useState, useEffect, useContext } from 'react';
import { createBrowserHistory} from "history"
import { api } from '../services/api.js';

const ContextSearch = createContext({});
const history = createBrowserHistory();
const itensMenu =[
    {
        path:"/",
        component:"Home"
    },
    {
        path:"/blog/principal",
        component:"Notícias"
    },
    {
        path:"/institucional/tribunal-de-etica",
        component:"Tribunal de Ética"
    },
    {
        path:"/institucional/comissoes",
        component:"Comissões"
    },
    {
        path:"/institucional/legislacoes",
        component:"Legislação"
    },
    {
        path:"/institucional/sobre",
        component:"Sobre a OAB/SE"
    },
    {
        path:"/secretaria/entregadecarteiras",
        component:"Entrega de Carteiras"
    },       
    {
        path:"/secretaria/examedeordem",
        component:"Exame de Ordem"
    },      
    {
        path:"/secretaria/inscricao-profissional",
        component:"Inscricao Profissional"
    },
    {
        path:"/secretaria/sociedade",
        component:"Sociedade"
    },
    {
        path:"/alvaras/banco-do-brasil",
        component:"Banco Do Brasil"
    },
    {
        path:"/alvaras/caixa-economica",
        component:"Caixa Economica"
    },
    {
        path:"/alvaras/banese",
        component:"Banese"
    },
    {
        path:"/tesouraria/resolucoes",
        component:"Resoluções"
    },
    {
        path:"/tesouraria/taxaseemolumentos",
        component:"Taxas e Emolumentos"
    },
    {
        path:"/tesouraria/transparencia",
        component:"Transparência"
    },
    {
        path:"/publicacoes/tabela-honorarios",
        component:"Tabela Honorarios"
    },
    {
        path:"/publicacoes/editais",
        component:"Editais"
    },
    {   
        path:"/publicacoes/artigos",
        component:"Artigos"
    },
    {
        path:"/publicacoes/ementarioTED",
        component:"Ementario TED"
    },
    {
        path:"/publicacoes/piso-salarial",
        component:"Piso Salarial"
    },
    {
        path:"/publicacoes/diario/diario-oab",
        component:"Diario Oab"
    },
    {
        path:"/publicacoes/TV-Oab",
        component:"TvOab"
    },
    {
        path:"/publicacoes/cartilhas",
        component:"Cartilhas"
    },
    {
        path:"/fale-conosco/ouvidoria", 
        component:"Ouvidoria"
    },
    {
        path:"/fale-conosco/defesa-prerrogativas",
        component:"Defesa das Prerrogativas"
    },
    {
        path:"/fale-conosco/loctel",
        component:"LocTel"
    },
    {
        path:"/inss/inss",
        component:"Inss"
    }
]

export const ContextSearchProvider = ({ children }) => {
    const [data,setData] = useState([]);
    const [itensMenuFiltered,setItensMenuFiltered] = useState(itensMenu);
    const [text, setText] = useState("")

    async function searchContent(value){
        setItensMenuFiltered(itensMenu.filter((item) => (
            item.component.toUpperCase().indexOf(value.toUpperCase()) !== -1
        )))
        await api.get(`posts?search=${value}&_embed`)
          .then(res => {
            setData(res.data)
          })
          .catch(err => {
            console.log(err)
          })
    }
    
    useEffect(() => {
        if(text.length > 2){
            if(window.location.pathname.indexOf("/search") === -1){
                history.push(`/search/${text}`)
                history.go()
            }
            searchContent(text)
        }
    }, [text])

    return (
        <ContextSearch.Provider 
        value={{
            data, setText, itensMenuFiltered, text, searchContent
        }}>
            {children}
        </ContextSearch.Provider>
    );
}

export function useContextSearch() {
    return useContext(ContextSearch);

}
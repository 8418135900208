import React from "react";
import { AiFillYoutube } from "react-icons/ai";
import { Title } from "../../../components";

import bolinhas7 from "../../../assets/images/Publicacoes/TvOab/bolinhas7.svg";
import bolinhas8 from "../../../assets/images/Publicacoes/TvOab/bolinhas8.svg";

import "./styles.scss";

function TvOab() {
  return (
    <div id="tv-oab">
      <section id="section-1">
        <Title
          textContent="SOBRE A OAB/SE"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;PUBLICAÇÕES >"
          link3="&nbsp;TV OAB/SE"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
      </section>
      <section id="section-2">
        <div id="bar" />
        <div id="title">Reunião Ordinária do Conselho OAB/SE</div>
      </section>
      <section id="section-3">
        <img className="details bolinhas8" src={bolinhas8} alt="" />
        <iframe
          src="https://www.youtube.com/embed/9uHrWVqNasM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div id="subscribe">
          <div id="subscribe-text">
            Se inscreva no nosso canal no youtube e acompanhe as novidades
          </div>
          <a href="https://www.youtube.com/user/OABSE" target="blank">
            <AiFillYoutube />
            <div>TV OAB/SE</div>
          </a>
        </div>
      </section>
      <section id="section-4">
        <img className="details bolinhas7" src={bolinhas7} alt="" />
      </section>
    </div>
  );
}

export default TvOab;

import styled, { css } from 'styled-components'

export const StyledImage = styled.img`
    height: 100px;
    max-width: 170px;
`

export const StyledImageMobile = styled.img`
    height: 95%;
    max-width: 200px;
    width: 100%;
    @media (min-width:700px) and (max-width:800px) {
        height:15vh;
        max-width: 42vw;
    }
`

export const StyledTypography = styled.div`
    font-size: 1.2em;
    color: #444444;
    font-weight: bold;
    margin-bottom: 15px;
`

export const StyledBottomPattern = styled.span`
    border-bottom: 3px solid #aa4f50;
    padding-bottom: 10px;
    margin-left: -0.9vw;
    font-family: Teko;

    @media (min-width:1020px) and (max-width: 1400px) {
        margin-left:-8vw;
    }
    ${props =>
        props.regular &&
        css`
            border-bottom: none;
            padding-bottom: none;
        `}
`

export const HideMobile = styled.div`
    display: block;

    @media (max-width: 900px) {
        display: none;
    }
`

export const ShowMobile = styled.div`
    display: none;

    @media (max-width: 900px) {
        display: block;
    }
`

import styled from "styled-components";

export const Container = styled.div`
width: 100%;
max-width: 1268px;
height: 57px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
position: relative;
box-shadow: 0px 5px 15px #0000001A;
background-color: #fff;
margin-top: 50px;
margin-bottom: 17px;
cursor: pointer;
z-index: 10;
@media(max-width:1300px){
  max-width: 1000px;
}

@media(max-width:1040px){
  max-width: 750px;
}
@media(max-width:550px){
  max-width: 333px;
  justify-content: flex-start;
  padding-left: 10px;
}
`;

export const Text = styled.span`
letter-spacing: 0.08px;
color: #333333;
opacity: 0.4;
font-size: 1rem;
z-index: 11;
`;
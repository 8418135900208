import React from 'react';

function CloseIcon({removeItem}) {
    return (
        <svg onClick={() => removeItem()} xmlns="http://www.w3.org/2000/svg" width="10.72" height="10.707" viewBox="0 0 10.72 10.707">
            <path id="icon-cancel" d="M5.185,5.186.714,10,5.185,5.186,0,0,5.185,5.186,10,0,5.185,5.186,10,10Z" transform="translate(0.354 0.354)" fill="none" stroke="#707070" stroke-width="1" />
        </svg>
    );
}

export default CloseIcon;
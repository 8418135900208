/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import "./styles.scss";

//import img from '../../../assets/images/Home/img.png'
import oab from "../../../assets/images/Home/oab.png";
import people from "../../../assets/images/Home/mask.jpg";
//import people2 from '../../../assets/images/Home/people2.png'
import woman from "../../../assets/images/Home/womanNoMark.jpeg";

const Images = [
  {
    name: "foto da oab",
    image: oab,
  },
  {
    name: "mulher",
    image: woman,
  },
  {
    name: "grupo de pessoas",
    image: people,
  },
];

const Slide = () => {
  const [images, setImages] = useState(Images);
  function ShowSlides(props) {
    return (
      <div>
        <img className="images" src={props.item.image} alt={props.item.name} />
      </div>
    );
  }
  return (
    <div id="carousel-home">
      <section class="carousel">
        <main class="carousel-stage">
          <Carousel
            className="carousel-item"
            autoPlay={true}
            navButtonsAlwaysVisible={true}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
                color: "#000",
              },
            }}
            indicators={false}
          >
            {Images.map((item, index) => {
              return <ShowSlides item={item} key={index} />;
            })}
          </Carousel>
        </main>
      </section>
    </div>
  );
};

export default Slide;

import React, { useState, useEffect} from 'react';

import Title from '../../../components/Title/title'

import Select from 'react-select';

import {
    Container,
    Content,
    Article,
    Download,
    ContentCards,
    Card,
    SelectIndicator,
    SelectedItem,
} from './Resolucoes.elements.js'
import "./styles.scss";
import "./selectStyles.scss";

import arrow from "../../../assets/images/Institucional/Resolucoes/Arrow.svg"
import arrowMobile from "../../../assets/images/Institucional/Resolucoes/Arrow-mobile.svg"

import data from './data';

export default function Resolucoes(){
    
    const [flags, setFlags] = useState(true);
    const [selected, setSelected] = useState();
    const [isSelected, setIsSelected] = useState();
    const [filteredCard, setFilteredcard] = useState(data);
    const [optionSelected, setOptionSelected] = useState();

    useEffect(() => {
    if(optionSelected === undefined){
    setFilteredcard(data)
    }else{
    let item = data.filter((item) => (
      item.label === optionSelected?.label
    ))
    setFilteredcard(item)
    }
  }, [optionSelected])

    useEffect(() => {
        document.getElementsByClassName('selected-item')?.item(0)?.classList?.add('slide-out-resolucao')
        setTimeout(function() {
            document.getElementsByClassName('selected-item')?.item(0)?.classList?.remove('slide-out-resolucao')
        }, 500)
    },[selected])

    function setItemSelected({item, index}){
        setSelected(item)
        setIsSelected(index)
        console.log(index)
    }

    const showFlags = () => {
    if (window.innerWidth <= 550) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  });

  window.addEventListener("resize", showFlags);
  
  useEffect(() => {
    const listCards = document.getElementsByClassName('card-container');
    const cards = [].slice.call(listCards);

    cards.map((item, index) => {
      item.addEventListener('mouseover', () => {
        listCards[index].classList.add('scale');
      });
      item.addEventListener('mouseout', () => {
        listCards[index].classList.remove('scale');
      });
    });
  });


  const options = [
  { value: 'ANUIDADE 2021', label: 'ANUIDADE 2021' },
  { value: 'ANUIDADE 2020', label: 'ANUIDADE 2020' },
  { value: 'ANUIDADE 2019', label: 'ANUIDADE 2019' },
  { value: 'ANUIDADE 2018', label: 'ANUIDADE 2018' },
  { value: 'ANUIDADE 2017', label: 'ANUIDADE 2017' },
  { value: 'ANUIDADE 2016', label: 'ANUIDADE 2016' },
  { value: '13/2019 DO CFOAB', label: '13/2019 DO CFOAB' },
];

    return(
        <Container>
            <Title 
            textContent="RESOLUÇÃO" 
            customClass="title-page" 
            link1="A OAB/SE >"
            link2="&nbsp;TESOURARIA >"
            link3="&nbsp;RESOLUÇÃO"
            rota1="/"
            customClassBackground={"background-red-horizontal"}
            title
            /><section className='section-2' id="section-2">
                <Select options={data} onChange={(value) => setOptionSelected(value)} placeholder="O que você deseja encontrar?" />
            </section>
            <Content>
                <Article>
                    { selected === undefined ?
                        <SelectIndicator>
                            Selecione uma opção
                            <img src={arrow} className="arrow" alt="seta apontando em direção a direta onde estão os cards"/>
                            <img src={arrowMobile} className="arrow-mobile" alt="seta apontando para baixo onde estão os cards"/>
                        </SelectIndicator>
                      :
                        <SelectedItem className="selected-item">
                            {selected?.nome}
                            <Download download href={selected?.arquivo}>Clique para baixar o PDF</Download>
                        </SelectedItem>
                    }
                </Article>
                <ContentCards>
                    {filteredCard.map((item, index) => (
                        <Card key={index} onClick={() => setItemSelected({item,index})} selected={isSelected === index ? true : false}>
                            {item.nome}
                        </Card>
                    ))}
                </ContentCards>
            </Content>
        </Container>
    )
}
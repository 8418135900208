import React from 'react';
import "./styles.scss";
// import { Container } from './styles';

function CoordenadoriasEspecificas() {
  return (
    <div id="esa-coordenadorias-especificas-container">
      <div id="diretoria-background" />
      <div id="diretores">
        <div className="title">Nome</div>
        <div className="title">Função</div>

        <div className="elements">FERNANDA SILVA SOUZA</div>
        <div className="elements">COORDENADORA DE FORMAÇÃO CONTINUADA E INGRESSO À ADVOCACIA</div>

        <div className="elements">ERCILIA MANUELA GARCEZ VIEIRA SANTOS</div>
        <div className="elements">COORDENADORA-ADJUNTA DE APOIO À JOVEM ADVOCACIA</div>

        <div className="elements">MAX CARDOSO SANTANA DÓRIA</div>
        <div className="elements">COORDENADOR DE INTERIORIZAÇÃO</div>

        <div className="elements">ANA CAROLINE MENEZES SANTOS</div>
        <div className="elements">COORDENADORA DE EDIORAÇÃO E CULTURA</div>
      </div>
    </div>
  );
}

export default CoordenadoriasEspecificas;
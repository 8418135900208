import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import Title from './../../../components/Title/title';
import "./styles.scss"
import "./slyderStyles.scss"

const data1 = [
    {
        id: 0,
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2020/06/Cartilha_TED.pdf",
        title: "Publicidade & Propaganda na Advocacia"
    },
    {
        id: 1,
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2020/05/Cart_imobiliario_Covid19.pdf",
        title: "Direito Imobiliário Notarial e Registral"
    },
    {
        id: 2,
        arquivo: "https://drive.google.com/file/d/1Oq6rp5iNA8_phzlb3nGFGr_CgleSujsA/view",
        title: "Segurança na WEB"
    },
    {
        id: 3,
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2020/05/cart_consumidor_covid19.pdf",
        title: "Cartilha do consumidor - COVID-19 e o direito do consumidor"
    },
    {
        id: 4,
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2020/08/Cartilha_Bioseguran%C3%A7a-1.pdf",
        title: "Orientação de Biossegurança – à Advocacia para o Retorno das Atividades Presenciais"
    },
    {
        id: 5,
        arquivo: "https://drive.google.com/file/d/1jSWUXcJ2B-7t6ZlHg87dQ9q0cK5mNh0-/view",
        title: "Lei Geral de Proteção de Dados"
    },
]

const data2 = [
    {
        id: 6,
        arquivo: "https://drive.google.com/file/d/1MBea2yWvjavXlcuRTV7k49F79OHeYeLL/view",
        title: "Direitos Autorais"
    },
    {
        id: 7,
        arquivo: "https://drive.google.com/file/d/1znENtVnIWVWtCdF02xD8Lh3Kn8s9xXRu/view",
        title: "Orientações Condomínios e Covid-19"
    },
    {
        id: 8,
        arquivo: "https://drive.google.com/file/d/1S-SKbgSjRc6ggv5hE-6KoPu90fulGKPM/view?usp=sharing",
        title: "Antirracista"
    },
    {
        id: 9,
        arquivo: "https://drive.google.com/file/d/1-nxLneTyjEcDtJ9jlB-OnzuTnx3fTk9W/view?usp=sharing",
        title: "LGPD & Advocacia – Como adequar o seu escritório"
    },
]

export default function Cartilhas(){
    return(
        <>
        <div id='Cartilhas'>
            <section className='section-1'>
                <Title
                textContent='CARTILHAS'
                customClass='title-page'
                link1='A OAB/SE >'
                link2='&nbsp;PUBLICACÕES >'
                link3='&nbsp;CARTILHAS'
                rota1='/'
                customClassBackground='background-red-horizontal'
                title
                />
            </section>
        </div>
        <div id="editais">
            <AwesomeSlider>
            <div className="container-banner">
                {data1.map(item => (
                    <a key={item.id} href={item.arquivo} target="blank" className="cartilha-item">
                        {item.title}
                    </a>
                ))}
            </div>
            <div className="container-banner">
                {data2.map(item => (
                    <a key={item.id} href={item.arquivo} target="blank" className="cartilha-item">
                        {item.title}
                    </a>
                ))}
            </div>
            </AwesomeSlider>
        </div>
        </>
    )
}
import React, { useEffect, useState } from 'react'
import {
    StyledTypography,
    StyledBottomPattern,
    StyledSidePattern,
    StyledSidePattern2,
    ShowMobile,
    HideMobile,
    StyledBoxMobile
} from './Noticias.elements'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import './styles.scss'
import { Link } from 'react-router-dom';
import { api } from '../../services/api.js'

const Noticias = () => {
    const [noticias, setNoticias] = useState([])

    useEffect(() => {
        async function noticiasHome() {
            await api.get('/posts?_embed').then((res) => {
                console.log(res.data)
                setNoticias(res.data)
            }
            ).catch((err) => console.log(err))
        }
        noticiasHome()
    }, [])

    return (
        <>
            <HideMobile>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-2">
                            <Link
                                to="/blog/principal"
                                style={{ textDecoration: 'none' }}
                                id="blog"
                            >
                                {' '}
                                <StyledTypography>
                                    <StyledBottomPattern
                                        style={{ fontSize: '1.2em' }}
                                    >
                                        Notícias
                                    </StyledBottomPattern>
                                    <StyledBottomPattern regular>

                                    </StyledBottomPattern>
                                    <StyledSidePattern>

                                    </StyledSidePattern>
                                </StyledTypography>
                            </Link>
                        </div>

                        <div className="col-10">
                            <div className="row">
                                {noticias.slice(0, 4).map((noticia, index) => (
                                    <div className="col-3">
                                        <Link to={`/blog/${noticia.id}`}>
                                            <div
                                                className="effect-image-1"
                                                style={{
                                                    backgroundImage: `url(${noticia?.acf?.imagem_da_noticia?.url ? noticia?.acf?.imagem_da_noticia?.url :
                                                        noticia._embedded["wp:featuredmedia"]["0"]
                                                        .media_details.sizes.full.source_url})`,
                                                    height: '150px',
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: '50%'
                                                }}
                                            >
                                                {/* <img
                                            src={executivos}
                                            alt="a-1"
                                            className="img-fluid"
                                        /> */}
                                                <div className="overlay-text overlay-text-1">
                                                    <p>{noticia?.acf?.titulo_da_noticia ? noticia?.acf?.titulo_da_noticia : noticia.title.rendered }</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-2">
                            {/*<a href="https://portal-oab-homologacao.web.app/" target="_blank">
                                <div className="effect-image-portal"
                                    style={{
                                        margin: '10px',
                                        height: '110px',
                                        backgroundSize: 'cover',

                                    }}
                                >
                                    <img src={portal} alt=" " className="portal" />
                                    <img src={portal2} alt=" " className="portal2" />
                                </div>
                                </a>*/}
                        </div>
                    </div>
                </div>
            </HideMobile>
            <ShowMobile>
                <div
                    style={{
                        backgroundColor: '#2C627A',
                        padding: '15px'
                    }}
                >
                    <div className="container controlMobile">
                        <Carousel showThumbs={false} showIndicators={false}>
                            <div className="card_relative_mobile">
                                <StyledBoxMobile
                                    className="pt-2"
                                    style={{
                                        textAlign: 'center',
                                        color: 'white'
                                    }}
                                >
                                    {' '}
                                    <div
                                        className="effect-image-1"
                                        style={{
                                            backgroundImage: `url(${noticias[0]?.acf?.imagem_da_noticia?.url ? noticias[0]?.acf?.imagem_da_noticia?.url: 
                                                noticias[0]?._embedded["wp:featuredmedia"]["0"]
                                                ?.media_details.sizes?.full?.source_url}`,
                                            height: '180px',
                                            backgroundSize: 'cover'

                                        }}
                                    >
                                        {/*  <img
                                            src={executivosMobile}
                                            alt="a-1"
                                            className="img-fluid"
                                        /> */}
                                        <div className="overlay-text overlay-text-1">
                                            <p className="texto">
                                                {noticias[0]?.acf?.titulo_da_noticia ? noticias[0]?.acf?.titulo_da_noticia :
                                                noticias[0]?.title.rendered
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <Link
                                        to={`/blog/${noticias[0]?.id}`}
                                        target="_blank"
                                        className="vejaMais"
                                    ><StyledBoxMobile>
                                            Veja Mais
                                        </StyledBoxMobile>
                                    </Link>

                                </StyledBoxMobile>
                            </div>
                            <div className="card_relative_mobile">
                                <StyledBoxMobile
                                    className="pt-2"
                                    style={{
                                        textAlign: 'center',
                                        color: 'white'
                                    }}
                                >
                                    {' '}
                                    <div
                                        className="effect-image-1"
                                        style={{
                                            backgroundImage: `url(${noticias[1]?.acf?.imagem_da_noticia?.url ? noticias[1]?.acf?.imagem_da_noticia?.url: 
                                                noticias[1]?._embedded["wp:featuredmedia"]["0"]
                                                ?.media_details.sizes?.full?.source_url}`,
                                            height: '180px',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        {/*  <img
                                            src={reuniao}
                                            alt="a-1"
                                            className="img-fluid"
                                        /> */}
                                        <div className="overlay-text overlay-text-1">
                                            <p className="texto">
                                                {noticias[1]?.acf?.titulo_da_noticia ? noticias[1]?.acf?.titulo_da_noticia :
                                                noticias[1]?.title.rendered
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <Link
                                        to={`/blog/${noticias[1]?.id}`}
                                        target="_blank"
                                        className="vejaMais"
                                    ><StyledBoxMobile>
                                            Veja Mais
                                        </StyledBoxMobile>
                                    </Link>

                                </StyledBoxMobile>
                            </div>
                            <div className="card_relative_mobile">
                                <StyledBoxMobile
                                    className="pt-2"
                                    style={{
                                        textAlign: 'center',
                                        color: 'white'
                                    }}
                                >
                                    {' '}
                                    <div
                                        className="effect-image-1"
                                        style={{
                                            backgroundImage: `url(${noticias[2]?.acf?.imagem_da_noticia?.url ? noticias[2]?.acf?.imagem_da_noticia?.url: 
                                                noticias[2]?._embedded["wp:featuredmedia"]["0"]
                                                ?.media_details.sizes?.full?.source_url}`,
                                            height: '180px',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        {/*  <img
                                            src={conversa}
                                            alt="a-1"
                                            className="img-fluid"
                                        /> */}
                                        <div className="overlay-text overlay-text-1">
                                            <p className="texto">
                                                {noticias[2]?.acf?.titulo_da_noticia ? noticias[2]?.acf?.titulo_da_noticia :
                                                noticias[2]?.title.rendered
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <Link
                                        to={`/blog/${noticias[2]?.id}`}
                                        target="_blank"
                                        className="vejaMais"
                                    ><StyledBoxMobile>
                                            Veja Mais
                                        </StyledBoxMobile>
                                    </Link>

                                </StyledBoxMobile>
                            </div>
                            <StyledTypography white className="pb-3">
                                <StyledBottomPattern
                                    style={{ fontSize: '1.7em' }}
                                >
                                    Notícias
                                </StyledBottomPattern>
                                <StyledBottomPattern regular>
                                </StyledBottomPattern>
                                <StyledSidePattern2>

                                </StyledSidePattern2>
                                <StyledBoxMobile
                                    className="pt-2"
                                    style={{
                                        textAlign: 'center',
                                        color: 'white'
                                    }}
                                >
                                    <Link
                                        to="/blog/principal"
                                        style={{ textDecoration: 'none' }}
                                        id="blog"
                                        className="vejaMais"
                                    >
                                        Veja Mais
                                    </Link>
                                </StyledBoxMobile>
                            </StyledTypography>
                        </Carousel>
                    </div>
                </div>
                { /*<div>
                    <Portal />
                </div> */}
            </ShowMobile>
        </>
    )
}

export default Noticias

import Styles from 'styled-components';

export const Container = Styles.div`
    width: 70%;
    margin: 0 auto 6rem;
    @media(max-width: 600px){
        width: 90%;
        margin: 0 auto 2rem;
    }
`;

export const Content = Styles.section`
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0 3rem;
    min-height: 400px;
    flex-wrap: wrap;
`;

export const SelectedItem = Styles.div`
    width: 90%;
    color: #fff;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #2C627A;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: 600px){
        height: auto;
        padding: 1.5rem 0;
    }
`;

export const SelectedItemTitle = Styles.h3`
    font-size: 1.8rem;
    font-family: Teko;
    color: #fff;
    font-weight: 400;
    @media(max-width: 600px){
        font-size: 1.2rem;
    }
`;

export const Download = Styles.a`
    color: #fff;
    font-size: 1.5rem;
    font-family: Teko;
    :hover{
        color: #fff;
        text-decoration: none;
    }
`;

export const SelectIndicator = Styles.div`
    width: 50%;
    height: 21rem;
    padding: 2rem;
    position: relative;
    margin-right: 50px;
    color: #fff;
    background-color: #2C627A;
    font-size: 3rem;
    font-family: Teko;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    @media(max-width: 600px){
        height: auto;
        font-size: 1.4rem;
        margin-right: 0;
        width: 53%;
    }
`;

export const Article = Styles.article`
    display: flex;
    width: 48%;
    justify-content: flex-end;
    margin: 0 1rem 0 0;
    @media(max-width: 600px){
        width: 100%;
        justify-content: center;
        margin: 0 auto 1.5rem;
    }
`;

export const ContentCards = Styles.article`
    display: flex;
    align-items: flex-start;
    width: 50%;
    flex-wrap: wrap;
    @media(max-width: 600px){
        width: 100%;
    }
`;

export const Card = Styles.div`
    height: 10rem;
    width: 10rem;
    padding: 2rem;
    color: ${props => (props.selected ? "#fff" : "#2C627A")};
    font-size: 1.5rem;
    font-family: Teko;
    font-variant-caps: all-petite-caps;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 0.5rem 0.5rem;
    cursor: pointer;
    background-color: ${props => (props.selected ? "#2C627A" : "#fff")};
    transition: all 0.5s ease-in-out;
    :hover{
        background-color: ${props => (props.selected ? "#2C627A" : "#1189C3")};
        color: #fff;
        transform: scale(1.2);
        box-shadow: 2px 2px 20px #999;
    }
    @media(max-width: 600px){
        width: 100%;
        font-size: 1rem
        height: auto;
        margin: 0.5rem 0;
        box-shadow: 1px 1px 10px #bfbebe96;
    }
`;
import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavBar = styled.div`
  max-width: 1500px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;

  @media(max-width:1600px){
    max-width: 1300px;
  }
  @media(max-width:1450px){
    max-width: 1100px;
  }
  @media(max-width:1250px){
    max-width: 900px;
  }
  @media(max-width:1050px){
    max-width: 700px;
  }
  @media(max-width:850px){
    max-width: 500px;
  }
  @media(max-width:550px){
    max-width: 100%;
    width: 90%;
  }
`;

export const Logo = styled.picture`
  width: 50%;

  @media(max-width:550px){
    text-align: right;
    width: 70%;
  }
`;

export const Titles = styled.span`
  letter-spacing: 0px;
  color: #444444;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Teko';
  font-weight: 400;
  font-size:1.3rem;
  position:relative;
  @media (max-width: 550px) {
    text-align: center;
    padding: 9px 9px;
  }
`;

export const Menu = styled.div`
  width: 20.938rem;
  min-height: 40rem;
  position: absolute;
  border: 1px solid #AA4F50;
  background-color: white;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media(max-width:550px){
    background-color: #02283a !important;
    border: none;
    min-height: 100vh !important;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding-top: 50px;
  }
`;

export const ContainerMenu = styled.div`
  width: 70%;
  display: flex;
  flex-direction:column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
  margin-top: 35px;
  @media (max-width: 550px) {
    text-align: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
`;

export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  @media (max-width: 550px) {
    text-align: center;
    align-items: center;
    width: 100%;
  }
`;

export const Input = styled.input`
  height: 44px;
  box-shadow:  inset 0 0 1em #00000029, 0 0 1em #00000029;
  background-color: #ffffff;
  overflow: hidden;
  border: none;
  border-style: hidden;
  border-color: white;
`;

export const Icons = styled.picture`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  img{
    cursor: pointer;
  }
`;


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;
import React, { useEffect, useState } from 'react';
import CloseIcon from "../../../assets/images/Tesouraria/taxasEmolumentos/close-icon";
import CloseIconMobile from '../../../assets/images/Tesouraria/taxasEmolumentos/close-icon-mobile';
import V_Icon from '../../../assets/images/Tesouraria/taxasEmolumentos/v-icon';
import { allSet, pagarTaxa, gerarBoleto, validarCPF, validarCNPJ } from './utilities';
import { Button } from 'semantic-ui-react';
import './styles.scss';

const CardDetalhesdeFaturamento = ({ itens, value, callbackParent }) => {
	const storage = window.sessionStorage;
	const [nome, setNome] = useState(storage.getItem("nome") ? storage.getItem("nome") : "");
	const [sobrenome, setSobrenome] = useState(storage.getItem("sobrenome") ? storage.getItem("sobrenome") : "");
	const [telefone, setTelefone] = useState(storage.getItem("telefone") ? storage.getItem("telefone") : "");
	const [email, setEmail] = useState(storage.getItem("email") ? storage.getItem("email") : "");
	const [cep, setCep] = useState(storage.getItem("cep") ? storage.getItem("cep") : "");
	const [rua, setRua] = useState(storage.getItem("rua") ? storage.getItem("rua") : "");
	const [bairro, setBairro] = useState(storage.getItem("bairro") ? storage.getItem("bairro") : "");
	const [numero, setNumero] = useState(storage.getItem("numero") ? storage.getItem("numero") : "");
	const [estado, setEstado] = useState(storage.getItem("estado") ? storage.getItem("estado") : "");
	const [cidade, setCidade] = useState(storage.getItem("cidade") ? storage.getItem("cidade") : "");
	const [cpf, setCpf] = useState(storage.getItem("cpf") ? storage.getItem("cpf") : "");
	const [cnpj, setCnpj] = useState(storage.getItem("cnpj") ? storage.getItem("cnpj") : "");
	const [itensDescription, setItensDescription] = useState("");
	const [pessoa, setPessoa] = useState(true);
	const [pagamento, setpagamento] = useState(false);
	const [total, setTotal] = useState(0);
	const [formaPagamento, setFormaPagamento] = useState("Cartão");

	// ESTADOS DE VALIDAÇÃO DO FORM
	const [validateNome, setValidateNome] = useState("");
	const [validateSobrenome, setValidateSobrenome] = useState("");
	const [validateCpfCnpj, setValidateCpfCnpj] = useState("");
	const [validateCep, setValidateCep] = useState("");
	const [validateEndereco, setValidateEndereco] = useState("");
	const [validateBairro, setValidateBairro] = useState("");
	const [validateCidade, setValidateCidade] = useState("");
	const [validateEmail, setValidateEmail] = useState("");
	//const [validateBairro, setValidateBairro] = useState("");

	const OPTIONS_ESTADOS_BRASIL = (
		<>
			<option value="AC" selected={estado === "AC" || estado === ""}>Acre</option>
			<option value="AL" selected={estado === "AL"}>Alagoas</option>
			<option value="AP" selected={estado === "AP"}>Amapá</option>
			<option value="AM" selected={estado === "AM"}>Amazonas</option>
			<option value="BA" selected={estado === "BA"}>Bahia</option>
			<option value="CE" selected={estado === "CE"}>Ceará</option>
			<option value="DF" selected={estado === "DF"}>Distrito Federal</option>
			<option value="ES" selected={estado === "ES"}>Espírito Santo</option>
			<option value="GO" selected={estado === "GO"}>Goiás</option>
			<option value="MA" selected={estado === "MA"}>Maranhão</option>
			<option value="MT" selected={estado === "MT"}>Mato Grosso</option>
			<option value="MS" selected={estado === "MS"}>Mato Grosso do Sul</option>
			<option value="MG" selected={estado === "MG"}>Minas Gerais</option>
			<option value="PA" selected={estado === "PA"}>Pará</option>
			<option value="PB" selected={estado === "PB"}>Paraíba</option>
			<option value="PR" selected={estado === "PR"}>Paraná</option>
			<option value="PE" selected={estado === "PE"}>Pernambuco</option>
			<option value="PI" selected={estado === "PI"}>Piauí</option>
			<option value="RJ" selected={estado === "RJ"}>Rio de Janeiro</option>
			<option value="RN" selected={estado === "RN"}>Rio Grande do Norte</option>
			<option value="RS" selected={estado === "RS"}>Rio Grande do Sul</option>
			<option value="RO" selected={estado === "RO"}>Rondônia</option>
			<option value="RR" selected={estado === "RR"}>Roraima</option>
			<option value="SC" selected={estado === "SC"}>Santa Catarina</option>
			<option value="SP" selected={estado === "SP"}>São Paulo</option>
			<option value="SE" selected={estado === "SE"}>Sergipe</option>
			<option value="TO" selected={estado === "TO"}>Tocantins</option>
		</>
	);

	useEffect(() => {
		let valor = itens.length > 0 && itens?.map(item => (
			item.value
		)).reduce((acumulador, item) => (
			Number(acumulador) + Number(item)
		))
		let description = itens.length > 0 && itens.map(item => (
			`${item.descricao} ${item.descricao2 && " |" + item.descricao2} ${item.descricao3 && " |" + item.descricao3} ${item.descricao4 && "|" + item.descricao4}`
		)).reduce((acumulador, item) => (
			acumulador + `\n` + item
		))
		setItensDescription(itens.length > 0 ? description : "")
		setTotal(itens.length > 0 ? valor : 0)
		itens.length === 0 && callbackParent(false, false);

	}, [itens])

	function removeItem(item) {
		itens.splice(itens.indexOf(item), 1);
		callbackParent(false, itens)
	}

	return (
		<>
			<div id="titulo">
				<CloseIconMobile onReturn={pagamento ? () => setpagamento(false) : () => callbackParent(false, false)} />
				<p>Finalizar compra</p>
			</div>
			<div id="subtexto">
				Escolha a forma de pagamento que desejar
			</div>
			<div id="card-group-detalhe">
				<div id="card-detalhe">
					<div id="card-header">
						<div id="card-header-text">
							{pagamento ? "Formas de Pagamento" : "Detalhes de Faturamento"}

						</div>
					</div>
					<div id="description-detalhe">
						<div id="subtext-detalhe">
							{pagamento ?
								<div id="opcoes-pagamento">
									<div
										className="opcao"
										style={{ opacity: formaPagamento === "Cartão" ? 1 : .4 }}
									>
										<input
											type="radio"
											id="Cartão"
											name="Cartão"
											value="Cartão"
											onChange={() => setFormaPagamento("Cartão")}
											checked={formaPagamento === "Cartão"}
										/>
										<label for="Cartão">	Cartão de Crédito</label>
									</div>
									<div
										className="opcao"
										style={{ opacity: formaPagamento === "Boleto" ? 1 : .4 }}
									>
										<input
											type="radio"
											id="Boleto"
											name="Boleto"
											value="Boleto"
											onChange={() => setFormaPagamento("Boleto")}
											checked={formaPagamento === "Boleto"}
										/>
										<label for="Boleto">	Boleto </label>
									</div>
								</div>
								:
								<>
									<div id="seu-pedido">
										Seu pedido
									</div>
									<div id="itens-comprados">
										{itens.map(item => (
											<div className="item-comprado-texto">
												<p>
													{`${item.descricao} ${item.descricao2 && "| " + item.descricao2} ${item.descricao3 && "| " + item.descricao3} ${item.descricao4 && "| " + item.descricao4}`}
													<CloseIcon removeItem={() => removeItem(item)} />
												</p>
											</div>
										))}
									</div>
								</>
							}
							<div id="linha-horizontal-detalhe-faturamento"></div>
							<div id="valor">
								<p id="total-texto">Total</p>
								<p id="total-valor">
									R${total}
								</p>
							</div>
							<div id="botoes-limpar">
								<button onClick={() => {
									window.sessionStorage.setItem("nome", nome);
									window.sessionStorage.setItem("sobrenome", sobrenome);
									window.sessionStorage.setItem("cpf", cpf);
									window.sessionStorage.setItem("cnpj", cnpj);
									window.sessionStorage.setItem("cep", cep);
									window.sessionStorage.setItem("rua", rua);
									window.sessionStorage.setItem("bairro", bairro);
									window.sessionStorage.setItem("numero", numero);
									window.sessionStorage.setItem("cidade", cidade);
									window.sessionStorage.setItem("estado", estado);
									window.sessionStorage.setItem("telefone", telefone);
									window.sessionStorage.setItem("email", email);
									callbackParent(false, false)
								}} id="link">
									Retorne à tela anterior
								</button>
								<button onClick={() => { callbackParent(true, false) }} id="limpar-carrinho">
									Limpar o carrinho
								</button>
							</div>
							<div id="compra-no-brasil">
								Esta compra está sendo feita no Brasil
							</div>

						</div>
					</div>
				</div>

				{/* Card da direita, formulário */}
				<div id="card-detalhe-form">
					<div id="v-div" style={{ top: pagamento ? "25px" : 0 }}>
						<V_Icon className={`icon icon-${!pagamento}`} />
						<div id="barra-azul" />
						<V_Icon className={`icon icon-${pagamento}`} />
					</div>
					{pagamento ?
						<div id="pagamento">
							{formaPagamento === "Cartão" ?
								<div className="pagamento-texto">
									<div>
										<h3>Pague com seu cartão</h3>
										Ao clicar em <span>"Finalizar pedido"</span>, você será agora redirecionado para a página do PagSeguro, para prosseguir com o pagamento.
									</div>
								</div>
								: formaPagamento === "Boleto" ?
									<div className="pagamento-texto">
										<h3>Pague com boleto bancário</h3>
										<div>
											Ao clicar em <span>"Finalizar pedido"</span>, você terá acesso ao boleto bancário, que pode ser impresso e pago via internet banking ou em uma casa lotérica.
										</div>
										<span id="nota">Nota: O pedido será confirmado só após a confirmação do pagamento</span>
									</div>
									: null
							}
							<div id="buttons2">
								<Button
									basic
									color='green'
									className="button-form"
									onClick={() => {
										setpagamento(false);
									}}
								>
									Voltar
								</Button>
								<Button
									basic
									color='green'
									type="button"
									className="button-form"
									onClick={
										formaPagamento === "Cartão" ? (() => {
											const body = {
												"itemAmount1": total,
												"senderName": nome + " " + sobrenome,
												"senderAreaCode": telefone.slice(0, 2),
												"senderPhone": telefone.slice(3),
												"senderEmail": email,
												"itemDescription1": itensDescription,
												"senderCPF": pessoa ? cpf : cnpj,
												"shippingAddressPostalCode": cep,
												"shippingAddressStreet": rua,
												"shippingAddressDistrict": bairro,
												"shippingAddressNumber": numero,
												"shippingAddressCity": cidade,
												"shippingAddressState": estado,
												"shippingAddressCountry": "BRA",
												"shippingType": "1"
											}

											pagarTaxa(body).then(() => callbackParent(true, false));
										}
										) : formaPagamento === "Boleto" ? (() => {
											const body = {
												"items": [
													{
														"description": itensDescription,
														"quantity": 1,
														"price_cents": total * 100 // preço em centavos
													}
												],
												"payer": { // dados do pagador
													"address": {
														"street": rua,
														"number": numero,
														"district": bairro,
														"city": cidade,
														"state": estado,
														"zip_code": cep,
														"complement": ""
													},
													"cpf_cnpj": pessoa ? cpf : cnpj, // pode ser tanto cnpj quanto cpf
													"name": nome + " " + sobrenome,
													"phone": telefone
												},
												"email": email
											}
											gerarBoleto(body).then(() => callbackParent(true, false));
										}) : null
									}
								>
									Finalizar pedido
								</Button>
							</div>
						</div>

						: <>
							<form id="form">
								<div className="linha-form">
									<label>
										<p>Nome</p>
										<input
											value={nome}
											onChange={
												(event) =>
													setNome(event.target.value.replace(/[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s.]/g, ''))
											}
											type="text"
											name="nome"
											className="entrada"
										/>
										{validateNome && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
									<label>
										<p>Sobrenome</p>
										<input
											value={sobrenome}
											onChange={
												(event) =>
													setSobrenome(event.target.value.replace(/[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s.]/g, ''))
											}
											type="text"
											name="sobrenome"
											className="entrada"
										/>
										{validateSobrenome && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
								</div>
								<div className="linha-form um">
									<label>
										<p>Tipo de pessoa</p>
										<label id="cpf-cnpj">
											{pessoa ?
												<>
													<p>CPF</p>
													<input
														value={cpf}
														onChange={
															(event) =>
																setCpf(event.target.value.replace(/[^\d\s-/]/g, ""))
														}
														type="text"
														name="cpf"
														className="entrada"
													/>
												</> :
												<>
													<p>CNPJ</p>
													<input
														value={cnpj}
														onChange={
															(event) =>
																setCnpj(event.target.value.replace(/[^\d\s-/]/g, ""))
														}
														type="text"
														name="cnpj"
														className="entrada"
													/>
												</>
											}
											{(validateCpfCnpj !== "") && <span className="error__taxas_emolumentos">{validateCpfCnpj}</span>}
											<div id="tipos-pessoa">
												<button
													type="button"
													onClick={() => setPessoa(true)}
													style={{ backgroundColor: !pessoa ? "#ccc" : "#3e92b7" }}
												>
													Física
												</button>
												<button
													type="button"
													onClick={() => setPessoa(false)}
													style={{ backgroundColor: pessoa ? "#ccc" : "#3e92b7" }}
												>
													Jurídica
												</button>
											</div>
										</label>
									</label>

								</div>
								<div className="linha-form">
									<label>
										<p>CEP</p>
										<input
											value={cep}
											onChange={
												(event) =>
													setCep(event.target.value.replace(/[^\d\s-/]/g, ""))
											}
											type="text"
											name="cep"
											className="entrada"
										/>
										{validateCep && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
									<label>
										<p>Endereço</p>
										<input
											value={rua}
											onChange={
												(event) =>
													setRua(event.target.value)
											}
											type="text"
											name="endereco"
											className="entrada"
										/>
										{validateEndereco && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
								</div>
								<div className="linha-form">
									<label>
										<p>Bairro</p>
										<input
											value={bairro}
											onChange={
												(event) =>
													setBairro(event.target.value)
											}
											type="text"
											name="bairro"
											className="entrada"
										/>
										{validateBairro && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
									<label>
										<p>Número</p>
										<input
											value={numero}
											onChange={
												(event) =>
													setNumero(event.target.value.replace(/[^\d\s-/]/g, ""))
											}
											type="text"
											name="numero"
											className="entrada"
										/>
									</label>
								</div>
								<div className="linha-form">
									<label>
										<p>Cidade</p>
										<input
											value={cidade}
											onChange={
												(event) =>
													setCidade(event.target.value.replace(/[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s.]/g, ''))
											}
											type="text"
											name="cidade"
											className="entrada"
										/>
										{validateCidade && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
									<label>
										<p>Estado</p>
										<select
											id="estados"
											onChange={
												(event) => setEstado(event.target.value)
											}
										>
											{OPTIONS_ESTADOS_BRASIL}
										</select>
									</label>
								</div>
								<div className="linha-form um">
									<label>
										<p>Telefone</p>
										<input
											value={telefone}
											onChange={
												(event) =>
													setTelefone(event.target.value.replace(/[^\d\s-/]/g, ""))
											}
											type="text"
											name="numero"
											className="entrada"
										/>
									</label>
								</div>
								<div className="linha-form um">
									<label>
										<p>Endereço de e-mail</p>
										<input
											value={email}
											onChange={
												(event) =>
													setEmail(event.target.value)
											}
											type="text"
											name="email"
											className="entrada"
										/>
										{validateEmail && <span className="error__taxas_emolumentos">Campo obrigatório</span>}
									</label>
								</div>
							</form>
							<div id="buttons">
								<Button
									basic
									color='green'
									className="button-form"
									onClick={() => {
										window.sessionStorage.clear();
										callbackParent(true, false);
									}}
								>
									Cancelar
								</Button>
								<Button
									basic
									color='green'
									type="submit"
									className="button-form"
									onClick={() => {
										nome === "" ? setValidateNome(true) : setValidateNome(false);
										cep === "" ? setValidateCep(true) : setValidateCep(false);
										pessoa ?
											(
												!validarCPF(cpf) ?
													setValidateCpfCnpj("CPF inválido")
													: setValidateCpfCnpj("")
											)
											: (
												!validarCNPJ(cnpj) ?
													setValidateCpfCnpj("CNPJ inválido")
													: setValidateCpfCnpj("")
											);
										email === "" ? setValidateEmail(true) : setValidateEmail(false);
										sobrenome === "" ? setValidateSobrenome(true) : setValidateSobrenome(false);
										rua === "" ? setValidateEndereco(true) : setValidateEndereco(false);
										bairro === "" ? setValidateBairro(true) : setValidateBairro(false);
										cidade === "" ? setValidateCidade(true) : setValidateCidade(false);
										const bodyValidate = [
											nome,
											cep,
											pessoa ? cpf : cnpj,
											email,
											sobrenome,
											rua,
											bairro,
											cidade
										];
										allSet(bodyValidate) && setpagamento(true)
									}}
								>
									Próximo
								</Button>
							</div>
						</>
					}

				</div>
			</div>
		</>
	)
};

export default CardDetalhesdeFaturamento;

import prov112006 from '../../../assets/pdfs/sociedade/PROVIMENTO-112-2006.pdf'
import prov1692015 from '../../../assets/pdfs/sociedade/PROVIMENTO-169-2015.pdf'
import prov1702016 from '../../../assets/pdfs/sociedade/Provimento-170_2016.pdf'
import prov1872018 from '../../../assets/pdfs/sociedade/PROVIMENTO-187-2018.pdf'
import modaltsocuni from '../../../assets/pdfs/sociedade/Modelo-de-alteração-de-contrato-de-socieda-unipessoal.docx'
import modaltsocadv from '../../../assets/pdfs/sociedade/modelo-de-alteração-contrato-para-sociedades-de-advogados.docx'
import contsocadv from '../../../assets/pdfs/sociedade/Contrato-Social-de-Sociedade-de-Advogados.docx'
import atosocadv from '../../../assets/pdfs/sociedade/Ato-Consitutivo-de-Sociedade-Unipessoal.docx'
import modcontaso from '../../../assets/pdfs/sociedade/Modelo-de-Contrato-de-Associação.docx'
import moddist from '../../../assets/pdfs/sociedade/Modelo-de-distrato-social-da-Sociedade-de-Advogados-denominada.docx'

const info = [
    <div className="sociedade-info">
        <ul>
            <li
            >
                Requerimento direcionado ao Presidente da Seccional solicitando
                registro
            </li>
            <li
            >
                03 vias do contrato constitutivo assinado por todos os sócios e
                testemunhas, que deve observar o disposto no provimento
                112/2006. (Frente única – não são aceitas cópias frente e verso)
            </li>
            <li
            >01 CD com o arquivo em Word do contrato;</li>
            <li
            >Comprovante original de pagamento da taxa de registro.</li>
            <li
            >Comprovante de residência atualizado de todos os sócios;</li>
            <li
            >Cópia da Identidade Profissional de todos os sócios;</li>
            <li
            >Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que todos os sócios estejam inscritos na OAB/SE, ativos
                e em dia com as anuidades ( mínimo 02 Advogados);
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>{' '}
            <li className="dropdown-item">
                <a
                    href={contsocadv}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de Contrato Social para Sociedades de
                    Advogados
                </a>
            </li>
            <li className="dropdown-item">
                <a
                    href={prov112006}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Provimento N°112-2006 Atualizado
                </a>
            </li>
            <li className="dropdown-item">
                <a
                    href={prov1872018}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    {' '}
                    Provimento 187/2018
                </a>
            </li>
        </div>
        <ul
            style={{
                paddingTop: '0',
                marginBottom: '3rem'
            }}
        >
            <li
            >
                A sociedade não paga anuidade, no entanto paga toda alteração
                contratual;
            </li>
            <li
            >A sociedade não pode possuir nome fantasia;</li>
            <li
            >O advogado não pode fazer parte de duas sociedades;</li>
            <li
            >O prazo para análise e emissão do registro é de 30 a 45 dias;
            </li>
            <li
            >A sociedade não pode possuir nome fantasia;</li>
        </ul>
    </div>,
    <div className="sociedade-info">
        <ul>
            <li >
                Requerimento direcionado ao Presidente da Seccional solicitando
                registro
            </li>
            <li>
                03 vias do contrato constitutivo assinado pelo advogado e
                testemunhas, que deve observar o disposto no provimento
                170/2016. (Frente única – não são aceitas cópias frente e verso)
            </li>
            <li
            >01 CD com o arquivo em Word do contrato;</li>
            <li
            >Comprovante original de pagamento da taxa de registro.</li>
            <li
            >Comprovante de residência atualizado;</li>
            <li
            >Cópia da Identidade Profissional;</li>
            <li
            >Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que o advogado esteja inscrito na OAB/SE, ativo e em
                dia com as anuidades;
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>
            <li className="dropdown-item">
                <a
                    href={atosocadv}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de Ato Constitutivo de Sociedade
                    Unipessoal de Advocacia
                </a>
            </li>
            <li className="dropdown-item">
                <a
                    href={prov1702016}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    {' '}
                    Provimento 170/2016
                </a>
            </li>
        </div>
        <ul
            style={{
                paddingTop: '0',
                marginBottom: '3rem',
            }}
        >
            <li>
                A sociedade não paga anuidade, no entanto paga toda alteração
                contratual;
            </li>
            <li>A sociedade não pode possuir nome fantasia;</li>
            <li>O advogado não pode fazer parte de duas sociedades;</li>
            <li>
                O prazo para análise e emissão do registro é de 30 a 45 dias;
            </li>
            <li>
                A sociedade de Advogados é registrada somente na OAB, não é
                necessário registro no Cartório ou Junta Comercial;
            </li>
        </ul>
    </div>,
    <div className="sociedade-info">
        <ul>
            <li>
                Para requerer Distrato da Sociedade de Advogados na OAB/SE é
                necessário protocolar os seguintes documentos listados abaixo:
            </li>
            <li>
                Requerimento direcionado ao Presidente da Seccional solicitando
                Distrato
            </li>
            <li>
                03 vias do Distrato social, que deve observar o disposto no
                provimento 112/2006. (Frente única – não são aceitas cópias
                frente e verso)
            </li>
            <li>01 CD com o arquivo em Word do contrato;</li>
            <li>
                Certidão de quitação de tributos e contribuições sociais e
                federais exigidas em lei, bem como a quitação junto a OAB (art.
                11 – Provimento 112/2006);
            </li>
            <li>Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que todos os sócios estejam inscritos na OAB/SE, ativos
                e em dia com as anuidades
                <br />O prazo para análise e emissão do registro é de 30 a 45 dias;
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>
            <li className="dropdown-item">
                <a
                    href={moddist}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de Distrato Social da Sociedade de
                    Advogados Denominada
                </a>
            </li>
        </div>
    </div>,
    <div className="sociedade-info">
        <ul>
            <li>
                Para requerer Alteração de Sociedade de Advogados na OAB/SE é
                necessário protocolar os seguintes documentos listados abaixo:
            </li>
            <li>Cópia do CNPJ da Sociedade</li>
            <li>
                Requerimento direcionado ao Presidente da Seccional solicitando
                registro;
            </li>
            <li>
                03 vias da alteração e do contrato social consolidado, que deve
                observar o disposto no provimento 112/2006. (Frente única – não
                são aceitas cópias frente e verso)
            </li>
            <li>01 CD com o arquivo em Word do contrato;</li>
            <li>Comprovante original de pagamento da taxa de alteração.</li>
            <li>Comprovante de residência atualizado de todos os sócios;</li>
            <li>Cópia da Identidade Profissional de todos os sócios;</li>
            <li>Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',
                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que todos os sócios estejam inscritos na OAB/SE, ativos
                e em dia com as anuidades ( mínimo 02 Advogados);
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>{' '}
            <li className="dropdown-item">
                <a
                    href={modaltsocadv}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de alteração e consolidação do
                    Contrato Social da Sociedade de Advogados
                    denominada
                </a>
            </li>
            <li className="dropdown-item">
                <a
                    href={modaltsocuni}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de Alteração de Contrato de Sociedade
                    Unipessoal
                </a>
            </li>
        </div>
        <ul
            style={{
                paddingTop: '0',
                marginBottom: '3rem'
            }}
        >
            <li>
                A sociedade não paga anuidade, no entanto paga toda alteração
                contratual;
            </li>
            <li>A sociedade não pode possuir nome fantasia;</li>
            <li>O advogado não pode fazer parte de duas sociedades;</li>
            <li>
                O prazo para análise e emissão do registro é de 30 a 45 dias;
            </li>
            <li>
                A sociedade de Advogados é registrada somente na OAB, não é
                necessãrio registro no Cartório ou na Junta Comercial;
            </li>
        </ul>
    </div>,
    <div className="sociedade-info">
        <ul>
            <li>
                Para requerer Contrato de Associação com Advogado na OAB/SE é
                necessário protocolar os seguintes documentos listados abaixo:
            </li>
            <li>
                Requerimento direcionado ao Presidente da Seccional solicitando
                registro
            </li>
            <li>
                03 vias do contrato, que deve observar o disposto no provimento
                112/2006. (Frente única – não são aceitas cópias frente e verso)
            </li>
            <li>
                Comprovante original de pagamento da taxa de Contrato de
                Associação. Taxas
            </li>
            <li>Comprovante de residência atualizado de todos os sócios;</li>
            <li>Cópia da Identidade Profissional de todos os sócios;</li>
            <li>Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',
                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que todos os sócios estejam inscritos na OAB/SE, ativos
                e em dia com as anuidades;
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>{' '}
            <li className="dropdown-item">
                <a
                    href={modcontaso}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Modelo de Contrato de Associação
                </a>
            </li>
            <li className="dropdown-item">
                <a
                    href={prov1692015}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Provimento 169/2015
                </a>
            </li>
        </div>
        <ul
            style={{
                paddingTop: '0',
                marginBottom: '3rem'
            }}
        >
            <li>
                A sociedade não paga anuidade, no entanto paga toda alteração
                contratual;
            </li>
            <li>A sociedade não pode possuir nome fantasia;</li>
            <li>O advogado não pode fazer parte de duas sociedades;</li>
            <li>
                O prazo para análise e emissão do registro é de 30 a 45 dias;
            </li>
            <li>
                A sociedade de Advogados é registrada somente na OAB, não é
                necessãrio registro no Cartório ou na Junta Comercial;
            </li>
        </ul>
    </div>,
    <div className="sociedade-info">
        <ul>
            <li>
                Para requerer Contrato de Associação com Advogado na OAB/SE é
                necessário protocolar os seguintes documentos listados abaixo:
            </li>
            <li>
                Requerimento direcionado ao Presidente da Seccional solicitando
                registro
            </li>
            <li>
                03 vias do Contrato, que deve observar o disposto no provimento
                112/2006. (Frente única – não são aceitas cópias frente e verso)
            </li>
            <li>
                Comprovante original de pagamento da taxa de Distrato de
                Associação.
            </li>

            <li>Comprovante de residência atualizado de todos os sócios;</li>
            <li>Cópia da Identidade Profissional de todos os sócios;</li>
            <li>Informar no contrato telefones e e-mails.</li>
        </ul>
        <div
            className="obs"
            style={{
                margin: '0',
                padding: '2rem 0 2rem 2rem',
                backgroundColor: '#02354d',
                fontFamily: 'Roboto',
                fontWeight: '100'
            }}
        >
            <div>
                <span
                    style={{
                        fontFamily: 'Teko',
                        fontSize: '20px'
                    }}
                >
                    OBS:
                </span>{' '}
                É necessário que todos os sócios estejam inscritos na OAB/SE, ativos
                e em dia com as anuidades;
            </div>
            <span
                style={{
                    fontFamily: 'Teko',
                    fontSize: '20px'
                }}
            >
                Links para download de documentos:
            </span>{' '}
            <li className="dropdown-item">
                <a
                    href={prov112006}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: "white"
                    }}
                >
                    Provimento N°112-2006 Atualizado
                </a>
            </li>
        </div>
        <ul
            style={{
                paddingTop: '0',
                marginBottom: '3rem'
            }}
        >
            <li>
                A sociedade não paga anuidade, no entanto paga toda alteração
                contratual;
            </li>
            <li>A sociedade não pode possuir nome fantasia;</li>
            <li>O advogado não pode fazer parte de duas sociedades;</li>
            <li>
                O prazo para análise e emissão do registro é de 30 a 45 dias;
            </li>
            <li>
                A sociedade de Advogados é registrada somente na OAB, não é
                necessãrio registro no Cartório ou na Junta Comercial;
            </li>
        </ul>
    </div>
]

export default info

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Title from "./../../../components/Title/title";
import Select from "react-select";
import Carousel from "react-elastic-carousel";
import { getApi } from "../../../services/api.js";

import arrow from "../../../assets/images/Institucional/Legislacao/Arrow.svg";
import arrowMobile from "../../../assets/images/Institucional/Legislacao/Arrow-mobile.svg";

import "./styles.scss";
import "./selectStyles.scss";

import data from "./data";

import {
  Card,
  SelectedItem,
  SelectedItemText,
  SelectedItemTitle,
} from "./Legislacao.elements.js";

const Legislacao = () => {

  const [flags, setFlags] = useState(true);
  const [selected, setSelected] = useState();
  const [isSelected, setIsSelected] = useState();
  const [optionSelected, setOptionSelected] = useState();
  const [filteredCard, setFilteredcard] = useState(data);

  const [res_01_2020, setRes_01_2020] = useState(null);
  const [res_02_2020, setRes_02_2020] = useState(null);
  const [res_01_2021, setRes_01_2021] = useState(null);
  const [portaria2019, setPortaria2019] = useState(null);
  const [res04, setRes04] = useState(null);
  const [res05, setRes05] = useState(null);
  const [res01, setRes01] = useState(null);
  const BuscarDadosLegislacoes = async () => {
    try {
      const res = await getApi.get("pages/50248");

      setRes_01_2020(res.data);
      setRes_02_2020(res.data);
      setRes_01_2021(res.data);
      setPortaria2019(res.data);
      setRes04(res.data);
      setRes05(res.data);
      setRes01(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    BuscarDadosLegislacoes();
  }, []);

  useEffect(() => {
    if (optionSelected === undefined) {
      setFilteredcard(data);
    } else {
      let item = data.filter((item) => item.label === optionSelected?.label);
      setFilteredcard(item);
    }
  }, [optionSelected]);

  useEffect(() => {
    document
      .getElementsByClassName("selected-item")
      ?.item(0)
      ?.classList?.add("slide-out");
    setTimeout(function () {
      document
        .getElementsByClassName("selected-item")
        ?.item(0)
        ?.classList?.remove("slide-out");
    }, 600);
  }, [selected]);

  function setItemSelected({ items, index }) {
    setSelected(items);
    setIsSelected(index);
    console.log(index);
  }

  const showFlags = () => {
    if (window.innerWidth <= 550) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  });

  window.addEventListener("resize", showFlags);

  useEffect(() => {
    const listCards = document.getElementsByClassName("card-container");
    const cards = [].slice.call(listCards);

    cards.map((item, index) => {
      item.addEventListener("mouseover", () => {
        listCards[index].classList.add("scale");
      });
      item.addEventListener("mouseout", () => {
        listCards[index].classList.remove("scale");
      });
    });
  });

  const options = [
    "RESOLUÇÕES E PORTARIAS DO TED/OAB/SE",
    "REGIMENTO INTERNO DA OAB/SE",
    "REGIMENTO INTERNO DO TED - OAB/SE",
    "CÓDIGO DE ÉTICA E DISCIPLINA DA OAB",
    "REGULAMENTO GERAL DO ESTATUTO DA ADVOCACIA E DA OAB",
    "ESTATUTO DA ADVOCACIA E DA OAB E LEGISLAÇÃO COMPLEMENTAR",
    "NOVO CÓDIGO DE ÉTICA E DISCIPLINA DA OAB",
    "PROVIMENTO NÚMERO 185/2018",
  ];
  console.log("data aqui")
  console.log(data);
  return (
    <div id="Legislacao">
      <section className="section-1">
        <Title
          textContent="LEGISLAÇÕES"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;INSTITUCIONAL >"
          link3="&nbsp;LEGISLAÇÃO"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
      </section>
      <section className="section-2">
        <Select
          options={data}
          onChange={(value) => setOptionSelected(value)}
          placeholder="O que você deseja encontrar?"
        />
      </section>
      <section className="section-3">
        <div className="left">
          {isSelected === undefined ? (
            <div className="container-card">
              <h2>
                Selecione <br />
                uma opção
              </h2>
              {!flags ? (
                <img src={arrowMobile} alt="" className="arrow" />
              ) : (
                <img src={arrow} alt="" className="arrow" />
              )}
            </div>
          ) : selected?.label === "RESOLUÇÕES E PORTARIAS DO TED/OAB/SE" ? (
            <SelectedItem>
              <SelectedItemTitle>2021</SelectedItemTitle>
              <SelectedItemText
                href={res_01_2021?.acf?.res_port_ted_oab_se?.res_01_2021?.url}
                target="_blank"
              >
                {res_01_2021?.acf?.res_port_ted_oab_se?.res_01_2021?.title}
              </SelectedItemText>
              <SelectedItemTitle>2020</SelectedItemTitle>
              <SelectedItemText
                href={res_02_2020?.acf?.res_port_ted_oab_se?.res_02_2020?.url}
                target="_blank"
              >
                {res_02_2020?.acf?.res_port_ted_oab_se?.res_02_2020?.title}
              </SelectedItemText>
              <SelectedItemText
                href={res_01_2020?.acf?.res_port_ted_oab_se?.res_01_2020?.url}
                target="_blank"
              >
                {res_01_2020?.acf?.res_port_ted_oab_se?.res_01_2020?.title}
              </SelectedItemText>
              <SelectedItemTitle>2019</SelectedItemTitle>
              <SelectedItemText
                href={
                  portaria2019?.acf?.res_port_ted_oab_se?.portaria_77_2019?.url
                }
                target="_blank"
              >
                {
                  portaria2019?.acf?.res_port_ted_oab_se?.portaria_77_2019
                    ?.title
                }
              </SelectedItemText>
              <SelectedItemText
                href={res05?.acf?.res_port_ted_oab_se?.resolucao_05?.url}
                target="_blank"
              >
                {res05?.acf?.res_port_ted_oab_se?.resolucao_05?.title}
              </SelectedItemText>
              <SelectedItemText
                href={res04?.acf?.res_port_ted_oab_se?.resolucao_04?.url}
                target="_blank"
              >
                {res04?.acf?.res_port_ted_oab_se?.resolucao_04?.title}
              </SelectedItemText>
              <SelectedItemText
                href={res01?.acf?.res_port_ted_oab_se?.resolucao_01?.url}
                target="_blank"
              >
                {res01?.acf?.res_port_ted_oab_se?.resolucao_01?.title}
              </SelectedItemText>
            </SelectedItem>
          ) : (
            <SelectedItem>
              <SelectedItemText href={selected?.arquivo} target="_blank">
                Baixar
              </SelectedItemText>
            </SelectedItem>
          )}
        </div>
        <div className="rigth">
          {flags ? (
            <div className="container-cards">
              {filteredCard.map((items, index) => {
                return (
                  <Card
                    id={items.value}
                    onClick={() => setItemSelected({ items, index })}
                    selected={isSelected === index ? true : false}
                  >
                    {items.label}
                  </Card>
                );
              })}
            </div>
          ) : (
            <div id="carousel" className="carousel">
              <Carousel itemsToShow={3} renderArrow={false} verticalMode>
                {filteredCard.map((items, index) => {
                  return (
                    <Card
                      id={items.value}
                      onClick={() => setItemSelected({ items, index })}
                      selected={isSelected === index ? true : false}
                    >
                      {items.label}
                    </Card>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Legislacao;

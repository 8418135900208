import React, { useState } from 'react';
import { Aba, Item } from "./Abas.elements";

// import { Container } from './styles';

function Abas({ options, renderOptions, menuPadding, textColor }) {
    const [isActive, setIsActive] = useState([true, false, false]);
    console.log(isActive);

    const RenderItem1 = renderOptions[0];
    const RenderItem2 = renderOptions[1];
    const RenderItem3 = renderOptions[2];

    function ativar(id) {
        var newIsActive = [];
        for (var i = 0; i < 3; i++) {
            if (i === id) {
                newIsActive.push(true);
            } else
                newIsActive.push(false);
        }
        setIsActive(newIsActive);
    }

    return (
        <>
            <Aba menuPadding={menuPadding} >
                {options.map(option => (
                    <>
                        <div
                            className={`opcao opcao-${isActive[option.id]}`}
                            key={option.id}
                            onClick={() => ativar(option.id)}
                            style={{ color: textColor }}
                        >
                            {option.opcao}
                            <div className="red-block" />
                        </div>
                        {renderOptions[option.id]}
                    </>
                ))}
            </Aba>
            <Item>
                <RenderItem1 open={isActive[0]} className={`aba-item aba-item-${isActive[0]}`} />
                <RenderItem2 open={isActive[1]} className={`aba-item aba-item-${isActive[1]}`} />
                <RenderItem3 open={isActive[2]} className={`aba-item aba-item-${isActive[2]}`} />
            </Item>
        </>
    );
}

export default Abas;
import React, { useState, useEffect } from "react";
import { Title } from "../../../components";

import { firstCard, secondCard, thirdCard, fourthCard } from './infos'

import Arrow from "../../../assets/images/Secretaria/InscricaoProfissional/Arrow.svg";
import bolinhas from "../../../assets/images/Secretaria/InscricaoProfissional/bolinhas.svg";
import blob1 from "../../../assets/images/Secretaria/InscricaoProfissional/blob1.svg";
import blob2 from "../../../assets/images/Secretaria/InscricaoProfissional/blob2.svg";

import './styles.scss';

const InscricaoProfissional = () => {
  const [card,setCard] = useState(0);
  const [click,setClick] = useState(false);
  const [flags, setFlags] = useState(true);

  const showFlags = () => {
    if (window.innerWidth <= 768) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  const handleCard = (id) => {
    setCard(id);
    setClick(!click);
    let element = document.getElementById('info');
    if(!flags){
      element.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  useEffect(() => {
    showFlags();
  });

  window.addEventListener("resize", showFlags);

  return(
    <div id="inscricao-profissional">
      <section className="section-1">
        <Title 
        textContent='inscrição de profissional'
        customClass='title-page'
        link1='A OAB/SE >'
        link2='&nbsp;secretaria>'
        link3='&nbsp;inscrição de profissional'
        rota1='/'
        customClassBackground='background-red-horizontal'
        title
        />
      </section>
      <section className="section-2">
        <div className="left">
          <span className="title subtitle">Documentação <br />para inscrição</span>
          <p><strong>A OAB/SE disponibiliza</strong> a lista da documentação necessária à inscrição na ordem. <br />
            <strong>Atente-se</strong> para o tipo de inscrição adequado.
          </p>
        </div>
        <div className="right">
          <img className="details blob1" src={blob1} alt="" />
          <img className="details blob2" src={blob2} alt="" />
          <img className="details bolinhas" src={bolinhas} alt="" />
          <span className="title mini-title">Você que é recém formado ou advogado atuante,</span>
          <span className="title">Faça o seu cadastro no link abaixo</span>
          <div className="observacao-card">
            <button className="button" type="button">
              <a href="https://www1.oab.org.br/CNApre?sF2p%2B%2B24yiTmXGMcudVEww%3D%3D" target="_blank" rel="noreferrer">Acesse</a>
            </button>
            <span className="title mini-title">
              <strong>Atenção:</strong> É obrigatório realizar o <br /> pré-cadastro {' '} 
            </span>
          </div>
        </div>
      </section>
      <section className="section-3">
        <div id="info" className={click ? "container-info" : "none"}>
          {card === 1 && firstCard()}
          {card === 2 && secondCard()}
          {card === 3 && thirdCard()}
          {card === 4 && fourthCard()}
        </div>
        <div className={click ? "selecione fechar" : "selecione"}>
          <div className={click ? "container-selecione fechar"  :"container-selecione"}>
            <span>Selecione <br /> uma opção</span>
            <img className="arrow" src={Arrow} alt="" />
          </div>
        </div>
        <div id="content-right">
          <div className="cards">
            <div className={card === 1 && click ? "container-card active" : "container-card"} onClick={() => handleCard(1)}>
              <span className="title-card">INSCRIÇÃO DEFINITIVA</span>
              <div  className="shadow"/>
            </div>
            <div className={card === 2 && click ? "container-card active" : "container-card"} onClick={() => handleCard(2)}>
              <span className="title-card">inscrição definitiva por transparência</span>
            </div>
            <div className={card === 3 && click ? "container-card active" : "container-card"} onClick={() => handleCard(3)}>
              <span className="title-card">inscrição suplementar</span>
            </div>
            <div className={card === 4 && click ? "container-card active" : "container-card"} onClick={() => handleCard(4)}>
              <span className="title-card">inscrição de estagiário</span>
            </div>
          </div>
          <article id="observacao-geral">
            <h3>OBSERVAÇÕES</h3>
            <p>
              Os boletos referentes às taxas de inscrição e Carteira Profissional podem
              ser retirados na Tesouraria da sede da Seccional ou via web, <strong>utilizando nosso
              sistema</strong>. Clique na sessão Taxas e Emolumentos no menu acima em Tesouraria.
              <br /> <br />
              A publicação do Edital de inscrição no Diário Oficial da
              Justiça somente ocorrerá após entrega de todos os formulários
              e documentos na sede da seccional.
            </p>
          </article>
        </div>
      </section>
    </div>
  );
}

export default InscricaoProfissional;
const data = [
  {
    tipo:1,
    titulares:[
      {
        id:1,
        name:"ACÁCIA GARDÊNIA SANTOS LELIS OAB-SE 1513"
      },
      {
        id:2,
        name:"AÍDA MASCARENHAS CAMPOS OAB-SE 1097"
      },
      {
        id:3,
        name:"DALMO DE FIGUEIREDO BEZERRA OAB-SE 4732"
      },
      {
        id:4,
        name:"DOUGLAS ALESSANDRO FARIA DE ANDRADE OAB-SE 2438"
      },
      {
        id:5,
        name:"EDUARDO GOMES RIBEIRO MAIA OAB-SE 5691"
      },
      {
        id:6,
        name:"EDUARDO PEREIRA DE ARAÚJO OAB-SE 6092"
      },
      {
        id:7,
        name:"FERNANDA SILVA SOUSA OAB-SE 3229"
      },
      {
        id:8,
        name:"ISABELLE SANTIAGO ALMEIDA OAB-SE 3763"
      },
      {
        id:9,
        name:"JOÃO MARIA CALDAS OAB-SE 1735"
      },
      {
        id:10,
        name:"JOABY GOMES FERREIRA OAB-SE 1977"
      },
      {
        id:11,
        name:"JOSÉ EDUARDO DE SANTANA MACEDO OAB-SE 1634"
      },
      {
        id:12,
        name:"JOSÉ ROBSON SANTOS DE BARROS OAB-SE 5763"
      },
      {
        id:13,
        name:"KLEIDSON NASCIMENTO DOS SANTOS OAB-SE 4488"
      },
      {
        id:14,
        name:"LEONARDO BARROS CHAGAS OAB-SE 7793"
      },
      {
        id:15,
        name:"LUCIANO LUIS ALMEIDA SILVA OAB-SE 6045"
      },
      {
        id:16,
        name:"LUCIANO VIEIRA NASCIMENTO NETO OAB-SE 4683"
      },
      {
        id:17,
        name:"MANUELLA MARIA VERGNE CARDOSO OAB-SE 5367"
      },
      {
        id:18,
        name:"MARIA DA PURIFICAÇÃO ANDRADE VIEIRA OAB-SE 2115"
      },
      {
        id:19,
        name:"MARIA DO CARMO DÉDA CHAGAS DE MELO OAB-SE 1970"
      },
      {
        id:20,
        name:"MAX CARDOSO SANTANA DÓRIA OAB-SE 4343"
      },
      {
        id:21,
        name:"PATRÍCIA FRANÇA VIEIRA OAB-SE 5405"
      },
      {
        id:22,
        name:"PAULA ARAÚJO DE MELO BRITTO OAB-SE 5955"
      },
      {
        id:23,
        name:"RICARDO SANTANA BISPO OAB-SE 2676"
      },
      {
        id:24,
        name:"RIVALDO SALVINO DO NASCIMENTO FILHO OAB-SE 5655"
      },
      {
        id:25,
        name:"ROBERTA GOLVEIRA DONALD OAB-SE 2892"
      },
      {
        id:26,
        name:"ROQUE CORRADO JUNIOR OAB-SE 5541"
      },
      {
        id:27,
        name:"VANESSA CASTRO DÓREA OAB-SE 4822"
      },
      {
        id:28,
        name:"VICTOR RIBEIRO BARRETO OAB-SE 6161"
      },
    ],
    suplentes: [
      {
        id:1,
        name:"ALEX SANDRO NASCIMENTO CONCEIÇÃO OAB-SE 5643",
      },
      {
        id:2,
        name:"ANTÔNIO CARLOS FRANCISCO ARAÚJO JÚNIOR OAB-SE 2408"
      },
      {
        id:3,
        name:"ARÍCIO DA SILVA ANDRADE FILHO OAB-SE 5371"
      },
      {
        id:4,
        name:"AGAMENON ALVES FREIRE JUNIOR OAB-SE 6027"
      },
      {
        id:5,
        name:"BRUNA MENEZES CARMO OAB-SE 6533"
      },
      {
        id:6,
        name:"CÂNDIDO DORTAS DE ARAÚJO OAB-SE 5929"
      },
      {
        id:7,
        name:"CARINE OLIVEIRA DE SOUSA MONTEIRO OAB-SE 7464"
      },
      {
        id:8,
        name:"CLARISSA MARQUES SANTOS FRANÇA OAB-SE 580-B"
      },
      {
        id:9,
        name:"CLARISSE DE AGUIAR RIBEIRO SIMAS OAB-SE 4324"
      },
      {
        id:10,
        name:"DANIELLE DOS SANTOS FERREIRA OAB-SE 8138"
      },
      {
        id:11,
        name:"EDSON ALEXANDRE DA SILVA OAB-SE 7266"
      },
      {
        id:12,
        name:"ELIANE REIS DE MELO MEJIAS OAB-SE 3295"
      },
      {
        id:13,
        name:"ERCILIA MANUELA GARCEZ VIEIRA SANTOS OAB-SE 7953"
      },
      {
        id:14,
        name:"FERNANDA MARIA DE MELO CARVALHO OAB-SE 5560"
      },
      {
        id:15,
        name:"FLÁVIA REGINA VIEIRA DE CARVALHO GOES OAB-SE 7369"
      },
      {
        id:16,
        name:"GERALDO VINICIUS OLIVEIRA NUNES OAB-SE 7263"
      },
      {
        id:17,
        name:"GABRIELA ANETE DE OLIVEIRA BRASIL OAB-SE 1058-A"
      },
      {
        id:18,
        name:"GLOVER RUBIO DOS SANTOS CASTRO OAB-SE 3705"
      },
      {
        id:19,
        name:"IZADORA GAMA BRITO OAB-SE 6220"
      },
      {
        id:20,
        name:"IZABELLE LINS DUARTE OAB-SE 5252"
      },

      {
        id:21,
        name:"JESSICA MATOS MESQUITA DOS ANJOS OAB-SE 6633"
      },
      {
        id:22,
        name:"JOÃO FRANCISCO DOS SANTOS FILHO OAB-SE 6907"
      },
      {
        id:23,
        name:"LARISSA DOS SANTOS SILVA OAB-SE 6401"
      },
      {
        id:24,
        name:"MÁRLIO DAMASCENO CONCEIÇÃO OAB-SE 2150"
      },
      {
        id:25,
        name:"MERLLINY MATOS MOREIRA OAB-SE 6214"
      },
      {
        id:26,
        name:"MILLA CERQUEIRA FONSECA OAB-SE 6490"
      },
      {
        id:27,
        name:"PÂMELA SALMERON FERREIRA KREISCHER OAB-SE 7372"
      },
      {
        id:28,
        name:"RICARDO MESQUITA BARBOSA OAB-SE 5005"
      },
      {
        id:29,
        name:"ROBÉRIA SILVA SANTOS OAB-SE 2671"
      },
      {
        id:30,
        name:"RODRIGO CASTELLI OAB-SE 661-A"
      },
      {
        id:31,
        name:"SILVANA FARIAS CRUZ OAB-SE 7354"
      },
      {
        id:32,
        name:"VANESSA REILLANE JESUS SANTANA DE ANDRADE OAB-SE 6128"
      },

    ]
  },
  {
    tipo:2,
    titulares:[
      {
        id:1,
        name:"ADÉLIA MOREIRA PESSOA OAB-SE 794"
      },
      {
        id:2,
        name:"MAURÍCIO GENTIL MONTEIRO OAB-SE 2435"
      },
      {
        id:3,
        name:"PAULO RAIMUNDO LIMA RALIN OAB-SE 3686"
      },
    ],
    suplentes:[
      {
        id:1,
        name:"TATIANE GONÇALVES MIRANDA GOLDHAR OAB-SE 4209"
      },
      {
        id:2,
        name:"GLÍCIA THAIS SALMERON DE MIRANDA OAB-SE 1450"
      },
      {
        id:3,
        name:"VITOR LISBOA OLIVEIRA OAB-SE 5910"
      },          
    ]
  }, 
]

export default data;
import  React  from 'react';
import { Title } from '../../../components';
import { IoMdMail } from 'react-icons/io'

import formulario from '../../../assets/arquivos/formulario_alvara_vs4.docx';

import bolinhas2 from '../../../assets/images/Alvaras/bolinhas2.svg';
import bolinhas3 from '../../../assets/images/Alvaras/bolinhas3.svg';
import blob3 from '../../../assets/images/Alvaras/blob3.svg';
import blob4 from '../../../assets/images/Alvaras/blob4.svg';

import './styles.scss';


const Banese = () => {
  return(
    <div id="banese">
      <section className="section-1">
        <Title 
        textContent='banese'
        customClass='title-page'
        link1='A OAB/SE >'
        link2='&nbsp;Alvarás >'
        link3='&nbsp;banese'
        rota1='/'
        customClassBackground='background-red-horizontal'
        title
        />
      </section>
      <section className="section-2">
        <div className="title">
          Advogados podem solicitar recebimento de alvarás judiciais por e-mail no 
          Banese
        </div>
        <img className="details bolinhas2" src={bolinhas2} alt="" />
      </section>
      <section className="section-3">
        <div className="left">
        <img className="details blob3"src={blob3} alt="" />
        <img className="details blob4"src={blob4} alt="" />
        <img className="details bolinhas3" src={bolinhas3} alt="" />
          <span className="title subtitle">
          Os advogados e advogadas podem requerer ao Banco do Estado de Sergipe 
          (Banese) por e-mail o recebimento dos alvarás de saque.
          </span>
          <p>
          A solicitação deve ser feita através de um formulário preenchido e assinado. 
          A medida atende às orientações do Ministério da Saúde em razão da situação 
          de pandemia do coronavírus pela qual passa o País.
          </p>
        </div>
        <div className="right">
          <div className="solicitar">
            <span className="title subtitle">
            Para solicitar o recebimento, basta <br /> enviar e-mail para o endereço eletrônico
            </span>
            <p> <IoMdMail className="icon"/>
              <a href="mailto: pagamento.alvara@banese.com.br">
              pagamento.alvara@banese.com.br
              </a>
            </p>
          </div>
          <div className="form">
            <p>O formulário que pode ser acessado aqui solicita informações como: 
              <strong> dados bancários pra crédito (Banco, Agência, Conta, nome completo do 
              titular da conta, CPF, Tipo de conta)</strong>, os dados do requerente, com local, 
              data, nome completo e OAB/SE; além do envio em anexo de cópia assinada 
              do alvará e da carteira da OAB do advogado.
            </p>
            <button className="button" type="button">
              <a href={formulario} target="_blank" rel="noreferrer">Formulário</a>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}


export default Banese;

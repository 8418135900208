import React, { useState } from "react";
import { Title } from "../../../components";

import Arrow from "../../../assets/images/Secretaria/InscricaoProfissional/Arrow.svg";

import "./styles.scss";

const PisoSalarial = () => {
  const [card, setCard] = useState(0);
  const [click, setClick] = useState(false);

  const handleCard = (id) => {
    setCard(id);
    setClick(!click);
  };

  return (
    <div id="piso-salarial">
      <section className="section-1">
        <Title
          textContent="Piso salarial"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;publicações >"
          link3="&nbsp;Piso salarial"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
      </section>
      <section className="section-2">
        <span>
          Última atualização do piso salarial em <strong>2021</strong>
        </span>
      </section>
      <section className="section-3">
        <div className={click ? "arquivos show" : "arquivos"}>
          <span className="title-arquivo link-em-bg-azul">
            <a
              href={
                card === 1
                  ? "https://oabsergipe.org.br/wp-content/uploads/2021/02/LEI-N-8241-2017-PISO-SALARIAL-DA-ADVOCACIA.pdf"
                  : card === 2
                  ? "https://oabsergipe.org.br/wp-content/uploads/2021/02/PORTARIA-Atualiza%C3%A7%C3%A3o-piso-salarial-2019.pdf"
                  : card === 3
                  ? "https://oabsergipe.org.br/wp-content/uploads/2021/02/PORTARIA-Atualiza%C3%A7%C3%A3o-piso-salarial-2020.pdf"
                  : card === 4 &&
                    "https://oabsergipe.org.br/wp-content/uploads/2021/02/PORTARIA-Atualiza%C3%A7%C3%A3o-piso-salarial-2021.pdf"
              }
              target="_blank"
              rel="noreferrer"
            >
              Clique para baixar o PDF
            </a>
          </span>
        </div>
        <div className="selecione">
          <div
            className={
              click ? "container-selecione fechar" : "container-selecione"
            }
          >
            <span>
              Selecione <br /> uma opção
            </span>
            <img className="Arrow" src={Arrow} alt="" />
          </div>
        </div>
        <div className="cards">
          <div
            className={
              card === 1 && click ? "container-card active" : "container-card"
            }
            onClick={() => handleCard(1)}
          >
            <span className="title-card">
              Lei Nº 8.241 de 05 de julho de 2017 – Piso Salarial 2017.
            </span>
          </div>
          <div
            className={
              card === 2 && click ? "container-card active" : "container-card"
            }
            onClick={() => handleCard(2)}
          >
            <span className="title-card">
              Atualização do Piso Salarial 2019.
            </span>
          </div>
          <div
            className={
              card === 3 && click ? "container-card active" : "container-card"
            }
            onClick={() => handleCard(3)}
          >
            <span className="title-card">
              Atualização do Piso Salarial 2020.
            </span>
          </div>
          <div
            className={
              card === 4 && click ? "container-card active" : "container-card"
            }
            onClick={() => handleCard(4)}
          >
            <span className="title-card">
              Atualização do Piso Salarial 2021.
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PisoSalarial;

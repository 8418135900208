export const data = {
    trienio: "2019/2021",
    presidente:{
        nome: "HERMOSA MARIA SOARES FRANÇA",
        codigo: "1917",
    },
    vicePresidente: {
        nome: "SUSAN MANUELA SILVA MENZES CRUZ",
        codigo: "4438",
    },
    secretarioGeral: {
        nome: "RAFAEL SARMENTO LYRIO",
        codigo: "6136",
    },
    secretarioGeralAdjunto: {
        nome: "JOSÉ EDMILSON DA SILVA JUNIOR",
        codigo: "5060",
    },
    tesouraria: {
        nome: "SILVANIA DOS SANTOS",
        codigo: "4455",
    },
    suplentes: [
        {
            nome: "ALFREDO MOREIRA DA CUNHA JUNIOR",
            codigo: "3492",
        },
        {
            nome: "DAVID GUIMARÃES SANTOS",
            codigo: "6037",
        },
        {
            nome: "LENIEVERSON SANTANA DE MENEZES CORREIA",
            codigo: "1715",
        },
        {
            nome: "CARLOS ALGUSTO LIMA NETO",
            codigo: "4951",
        },
        {
            nome: "ROBERTA LUCAS ARAUJO",
            codigo: "8537",
        }
    ]
        
}
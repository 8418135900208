import React, { useState, useEffect } from 'react';

import { Title } from '../../../../components';
import { getApi } from "../../../../services/api";
import Select from '../../../Tesouraria/Transparencia/Select/Select';
import bolinhas2 from '../../../../assets/images/Alvaras/bolinhas2.svg';
import './styles.scss';

const anos = () => {
    const final = new Date().getFullYear();
    const initial = 2016;
    const optionsQuantity = final - initial;

    const years = [
        {
            value: "",
            option: "Selecione"
        }
    ];

    for (var i = optionsQuantity; i >= 0; i--) {
        years.push(
            {
                value: (initial + i).toString(),
                option: (initial + i).toString()
            }
        )
    }

    return years;
}

const meses = [
    {
        value: "",
        option: "Selecione",
    },
    {
        value: "Janeiro",
        option: "Janeiro",
    },
    {
        value: "Fevereiro",
        option: "Fevereiro",
    },
    {
        value: "Março",
        option: "Março",
    },
    {
        value: "Abril",
        option: "Abril",
    },
    {
        value: "Maio",
        option: "Maio",
    },
    {
        value: "Junho",
        option: "Junho",
    },
    {
        value: "Julho",
        option: "Julho",
    },
    {
        value: "Agosto",
        option: "Agosto",
    },
    {
        value: "Setembro",
        option: "Setembro",
    },
    {
        value: "Outubro",
        option: "Outubro",
    },
    {
        value: "Novembro",
        option: "Novembro",
    },
    {
        value: "Dezembro",
        option: "Dezembro",
    },

]

function DiarioOab() {
    const [ano, setAno] = useState("");
    const [mes, setMes] = useState("");
    const [edicoes, setEdicoes] = useState();

    useEffect(() => {
        async function editais() {
            await getApi
                .get("/pages/50017").then(res => {
                    console.log(res.data);
                    setEdicoes(res.data.acf.diarios)
                })
                .catch(err => console.log(err));
        }
        editais();
    }, [])

    return (
        <div id="diario-oab">

            <section id="section-1">
                <Title
                    textContent='SOBRE A OAB/SE'
                    customClass='title-page'
                    link1='A OAB/SE >'
                    link2='&nbsp;PUBLICAÇÕES >'
                    link3='&nbsp;DIÁRIO OAB/SE'
                    rota1='/'
                    customClassBackground='background-red-horizontal'
                    title
                />
            </section>

            <section id="section-2">
                <img className="details" src={bolinhas2} alt="" />
                <div id="release-title">
                    <div id="bar" />
                    Comunicado
                </div>
                <div id="release-text">
                    A Lei n. 13.688, de 3 de julho de 2018, publicada no DOU Seção 1 de 04/07/2018, p. 1, instituiu o Diário Eletrônico
                    da Ordem dos Advogados do Brasil – DEOAB. A referida lei entra em vigor no dia 31/12/2018 e será de uso obrigatório
                    no tocante à legalidade das publicações da OAB em todo o território brasileiro. O DEOAB foi regulamentado pelo Conselho
                    Federal da Ordem dos Advogados do Brasil por intermédio do Provimento n. 182/2018-CFOAB, publicado no DOU Seção 1 de
                    31/10/2018, p. 126, com as alterações do Provimento n. 184/2018-CFOAB, publicado no DOU Seção 1 de 16/11/2018, p. 184.
                    O art. 6º-B do referido provimento determina que a criação do DEOAB e a sua entrada em vigor sejam objeto de ampla
                    divulgação, o que se faz por intermédio do presente comunicado e de sua publicação nos sítios eletrônicos da Instituição.
                    Portanto, a partir de 31/12/2018, os atos, as notificações e as decisões dos órgãos da Ordem dos Advogados do Brasil,
                    salvo quando reservados ou de administração interna, serão publicados no DEOAB, a ser disponibilizado na internet e
                    acessado no seguinte endereço eletrônico: <a href="https://deoab.oab.org.br" className="link-em-bg-branco">https://deoab.oab.org.br</a>.
                    <br />
                    <br />
                    —————————————————————
                    <br />
                    <br />
                    A Ordem dos Advogados do Brasil – Seccional Sergipe OAB/SE instituiu no dia 01 de julho,
                    por meio da resolução nº 05/2016 publicada no Diário Oficial Eletrônico deste Estado, o Diário Oficial
                    Eletrônico da OAB/SE.
                    <br />
                    <br />
                    Com a resolução, as publicações veiculadas no Diário Oficial Eletrônico da OAB/SE
                    substituirão qualquer outro meio de publicação oficial, para quaisquer efeitos legais,
                    salvo os casos em que a lei exija a intenção ou vista pessoal, conforme estabelece o art. 4º.
                </div>
            </section>

            <div id="editions-title">
                <div id="title">
                    Edições disponíveis:
                </div>
            </div>
            <section id="section-3">
                <div id="left">
                    <div id="container-selects">
                        <Select
                            label="Ano"
                            options={anos()}
                            onChange={(event) => setAno(event.target.value)}
                        />
                        <Select
                            label="Mês"
                            options={meses}
                            onChange={(event) => setMes(event.target.value)}
                        />
                    </div>
                </div>

                <div id="right">
                    <div id="item">
                        <div className="selected-item">
                            {mes !== "" && ano !== "" ?
                                <>
                                    <div id="selected-item-title">{mes}/{ano}</div>
                                    {
                                        edicoes?.find(element => element.ano === ano) &&
                                            edicoes?.find(element => element.ano === ano).mes_rep.find(element => element.mes === mes) ?
                                            edicoes?.find(element => element.ano === ano).mes_rep.find(element => element.mes === mes).edicoes.map(edicao =>
                                                <div className="selected-item-link" id={`${edicoes?.find(element => element.ano === ano).ano}`}>
                                                    {edicao.edicao}
                                                    <a href={edicao.publicacao}>Baixar</a>
                                                </div>
                                            )
                                            : <div className="selected-item-link">
                                                Não há nenhuma publicação no período selecionado
                                            </div>
                                    }
                                </>
                                :
                                <div>
                                    Selecione um período (mês e ano) nas opções ao lado para ter acesso aos diários
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default DiarioOab;
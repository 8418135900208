import React, {useState, useEffect, useRef} from 'react'
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { api } from '../../../../services/api.js';
import './styles.scss'

export default function CarrosselNoticias(){
    const [noticias, setNoticias] = useState([])
    const [loading, setLoading] = useState(true)
    const [right, setRight] = useState(false)
    const [noticiaAtual, setNoticiaAtual] = useState(1)
    const carrossel = useRef(null)

    useEffect(()=>{
        setLoading(true);
        async function getNoticias(){
             await api.get(`/posts?_embed`)
             .then((res) => {
                 setNoticias(res.data);
                 setLoading(false);
              })
             .catch((err)=>console.log(err))
        }
        getNoticias()
     },[])

     function handleLeft(){
        setRight(false);
        carrossel.current.scrollLeft -=carrossel.current.offsetWidth
     }

     function handleRight(){
        setRight(true);
        carrossel.current.scrollLeft +=carrossel.current.offsetWidth
     }

     function handleLeftMobile(){
        noticiaAtual > 1 && setNoticiaAtual(noticiaAtual-1)
        carrossel.current.scrollLeft -= 316
    }

     function handleRightMobile(){
        noticiaAtual < 5 && setNoticiaAtual(noticiaAtual+1)
        carrossel.current.scrollLeft += 316
     }
    return(
        <div id="CarrosselNoticias">
            {loading ?
            <></>
            :
            <>
            <div id="veja-tambem-container">
                <div/>
                <p>Veja também</p>
            </div>
            <div className="carrossel">
                <div className="arrowLeft nomobile">
                    <BsArrowBarLeft onClick={()=> handleLeft()} />
                </div>
                <div id={right ? "animationRight" : "animationLeft"} className="pagesNumbers" ref={carrossel}>
                    {noticias?.slice(0,6).map((item) => (
                        <Link to={`/blog/${item.id}`} key={item.id} style={{textDecoration: "none"}}>
                            <div className="noticia">
                                <div 
                                    style={{
                                        backgroundImage: `url(${item._embedded["wp:featuredmedia"]["0"]
                                        .media_details.sizes.full.source_url})`
                                    }}
                                >
                                    <p>{new Date(item?.date).toLocaleDateString("pt-BR")}</p>
                                </div>
                                <p className="title">
                                    <div/>
                                    {
                                        item?.title?.rendered
                                        .split(new RegExp(`&#8211;`, "i"))
                                        .join("")
                                    }
                                </p>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="pagination">
                        <div className="arrowLeft">
                            <BsArrowBarLeft onClick={()=> handleLeftMobile()} />
                        </div>
                        <div className="arrowRight">
                            <BsArrowBarRight onClick={()=>handleRightMobile()} />
                        </div>
                </div>
                <div className="arrowRight nomobile">
                    <BsArrowBarRight onClick={()=> handleRight()} />
                </div>
            </div>
            </>
            }
        </div>
    )
}
import foto1 from "../../assets/images/Memorial/Desktop/01.png"
import foto2 from "../../assets/images/Memorial/Desktop/01-small.png"
import foto3 from "../../assets/images/Memorial/Desktop/02(2).png"
import foto4 from "../../assets/images/Memorial/Desktop/02(2)@2x.png"
import foto5 from "../../assets/images/Memorial/Desktop/06@2x.png"
import foto6 from "../../assets/images/Memorial/Desktop/07.png"
import foto7 from "../../assets/images/Memorial/Desktop/01-small.png"
import foto8 from "../../assets/images/Memorial/Desktop/06.png"
import foto9 from "../../assets/images/Memorial/Desktop/08.png"
import foto10 from "../../assets/images/Memorial/Desktop/03.png"

export const carousel = [
  {
    id: 1,
    foto: foto1
  },
  {
    id: 2,
    foto: foto2
  },
  {
    id: 3,
    foto: foto3
  },
  {
    id: 4,
    foto: foto4
  },
  {
    id: 5,
    foto: foto5
  },
  {
    id: 6,
    foto: foto6
  },
  {
    id: 7,
    foto: foto7
  },
  {
    id: 8,
    foto: foto8
  },
  {
    id: 9,
    foto: foto9
  },
  {
    id: 10,
    foto: foto10
  },
]
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Title from './../../../components/Title/title';
import Carousel from 'react-elastic-carousel';
import Card from "../../../shared/components/Card/Card";
import { FaChevronLeft } from 'react-icons/fa'

import arrow from '../../../assets/images/Institucional/Legislacao/Arrow.svg';
import arrowMobile from '../../../assets/images/Institucional/Legislacao/Arrow-mobile.svg';

import './styles.scss';
import {
  SelectedItem,
  SelectedItemTitleH2,
  MembersTextBold,
  MembersText,
  MembersContent,
  MembersTextRight,
  MembersTitle
} from './Comissao.elements.js';

import './selectStyles.scss';

import Select from 'react-select';

const Comissao = () => {
  const [flags, setFlags] = useState(true);
  const [selected, setSelected] = useState();
  const [isSelected, setIsSelected] = useState();
  const [data, setData] = useState([]);
  const [filteredCard, setFilteredcard] = useState(data);
  const [optionSelected, setOptionSelected] = useState();
  const options = []

  useEffect(() => {
    axios.get("https://www.oab.org.br/api/comissoes/26?")
    .then((res) => {
      setData(res.data);
      setFilteredcard(res.data);
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err)
    })
  },[])

  useEffect(() => {
    data.map(item => {
      options.push({label: item.NomeSetor, value: item.CodigoSetor})
    })
  }, [options])

  useEffect(() => {
    if(optionSelected === undefined){
    setFilteredcard(data)
    }else{
    let item = data.filter((item) => (
      item.NomeSetor === optionSelected?.label
    ))
    setFilteredcard(item)
    }
  }, [optionSelected])

  useEffect(() => {
    document.getElementsByClassName('selected-item')?.item(0)?.classList?.add('slide-out')
    setTimeout(function() {
        document.getElementsByClassName('selected-item')?.item(0)?.classList?.remove('slide-out')
    }, 600)
  },[selected])

  function setItemSelected({items, index}){
      setSelected(items)
      setIsSelected(index)
  }

  function resetItemSelected(){
    setSelected(undefined)
    setIsSelected(undefined)
}

  const showFlags = () => {
    if (window.innerWidth <= 550) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  });

  window.addEventListener('resize', showFlags);
  useEffect(() => {
    const listCards = document.getElementsByClassName('card-container');
    const cards = [].slice.call(listCards);

    cards.map((item, index) => {
      item.addEventListener('mouseover', () => {
        listCards[index].classList.add('scale');
      });
      item.addEventListener('mouseout', () => {
        listCards[index].classList.remove('scale');
      });
    });
  });

  return (
    <div id='Comissao'>
      <section className='section-1'>
        <Title
          textContent='COMISSÕES'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;INSTITUCIONAL >'
          link3='&nbsp;COMISSÕES'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      { selected === undefined ? 
      <>
      <section className='section-2'>
        <Select options={options} onChange={(value) => setOptionSelected(value)} placeholder="O que você deseja encontrar?" />
      </section>
      <section className='section-3'>
        <div className="left">
          <div className="container-card">
            <h2>Selecione <br/>uma opção</h2>
            {!flags ? <img src={arrowMobile} alt="" className="arrow" /> : <img src={arrow} alt="" className="arrow"/> }
          </div>
        </div>
        <div className="rigth">
        {/*flags ? (*/
          <div className='container-cards'>
            {filteredCard.map((items, index) => {
              return (
                <Card  id={items.CodigoSetor} onClick={() => setItemSelected({items,index})} selected={isSelected === index ? true : false}  >
                {items.NomeSetor}
                </Card>
              );
            })}
          </div>
        /*) : (
          <div id='carousel' className='carousel'>
            <Carousel itemsToShow={3} renderArrow={false} verticalMode>
            {filteredCard.map((items, index) => {
              return (
                  <Card  id={items.CodigoSetor} onClick={() => setItemSelected({items,index})} selected={isSelected === index ? true : false}>
                  {items.NomeSetor}
                  </Card>
              );
            })}
            </Carousel>
          </div>
          )*/}
        </div>
      </section>
      </>
      :
      <section className='section-3' style={{flexDirection: 'column'}}>
        <SelectedItemTitleH2  onClick={() => resetItemSelected()}>
          <FaChevronLeft
          size={18}
          style={{marginRight: 10, marginBottom: 2, cursor: 'pointer'}}
          />
          {selected?.NomeSetor}
          </SelectedItemTitleH2>
        <SelectedItem>
          <MembersContent>
            {selected?.Membros.map((items) => {
              return (
                items.Cargo !== "Membro" && (
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <MembersTextBold>
                    {items.Cargo} <br/>
                  </MembersTextBold>
                  <MembersTextRight>
                    {items.Nome} <br/>
                  </MembersTextRight>
                </div>
                )
              )
            })}
          </MembersContent>
          <MembersTitle>MEMBROS</MembersTitle>
          <MembersContent>
            <MembersText>
            {selected?.Membros.map((items) => {
              return (
                items.Cargo === "Membro" && <>{items.Nome} <br/></>
              );
            })}
            </MembersText>
          </MembersContent>
        </SelectedItem>
      </section>
      }
    </div>
  );
};

export default Comissao;

import React from 'react';
import { Title } from '../../../components';

import TabelaHonorariosPDF from '../../../assets/arquivos/Tabela_de_Honorarios_11-12-20.pdf';

import bolinhas5 from "../../../assets/images/Tesouraria/tabela/bolinhas5.svg";
import bolinhas6 from "../../../assets/images/Tesouraria/tabela/bolinhas6.svg";
import blob5 from "../../../assets/images/Tesouraria/tabela/blob5.svg";
import blob6 from "../../../assets/images/Tesouraria/tabela/blob6.svg";

import './styles.scss';

const TabelaHonorarios = () => {
  return (
    <div id="tabela-honorarios">
      <section className="section-1">
        <Title
          textContent='Tabela de Honorários'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;Tesouraria >'
          link3='&nbsp;Tabela de Honorários'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      <section className="section-2">
        <div className="redSquare"></div>
        <img className="details bolinhas5" src={bolinhas5} alt="" />
        <img className="details bolinhas6" src={bolinhas6} alt="" />
        <img className="details blob5" src={blob5} alt="" />
        <img className="details blob6" src={blob6} alt="" />
        <span>Acesse a nossa tabela de honorários clicando no botão abaixo</span>
        <button className="button" type="button">
          <a href={TabelaHonorariosPDF} target="_blank" rel="noreferrer">Baixar</a>
        </button>
        <p>
          Ao clicar em "baixar" você será redirecionado para uma página
          de leitor de PDF
        </p>
      </section>
    </div>
  );
}

export default TabelaHonorarios;
const infoTile = [
    {
        title1: 'Primeira Turma',
        title2: 'Segunda Turma',
        text1: `Processo ético-disciplinar. Anuidade. Inadimplência. Ausência de quitação. Suspensão. Dentre os deveres do advogado está a obrigação de pagar seu órgão de classe, sob pena de suspensão pelo período de 30 (trinta) dias, perdurando até quitar a inadimplência. Precedentes. Infração disciplinar antevista no art. 34, inciso XXIII, do EOAB. PROCEDÊNCIA DA REPRESENTAÇÃO.(TED OAB/SE, Primeira Turma; Proc. TED nº. 303/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 13/12/2016; Publicação: 25/01/2017)
	
	INADIMPLÊNCIA. ANUIDADE. COMPATIBILIDADE DA SUSPENSÃO COM A ORDEM CONSTITUCIONAL. DIFICULDADE FINANCEIRA HODIERNA. AUSÊNCIA DE MEDIDA CAUTELAR NO RE 647885. PRECEDENTE FAVORÁVEL DA SUPREMA CORTE – RP 1481. SUSPENSÃO. REPRESENTAÇÃO PROCEDENTE.
	
	1 - A suspensão disciplinar por inadimplência está harmonizada com os ditames constitucionais e apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).
	
	2 - Apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).
	
	3 - Embora o STF tenha reconhecido a Repercussão Geral no RE 6478885, não há nenhuma cautelar que autorize a suspensão do processo que deverá ser julgado por esta Corte, sob pena de ofensa à inafastabilidade da jurisdição. Ademais, já existe precedente na Suprema Corte favorável à tese da constitucionalidade da suspensão disciplinar (Representação de Inconstitucionalidade de nº. 1481).

	4 - Representação procedente. Decisão unanime.(TED OAB/SE, Primeira Turma; Proc. TED nº. 299/2016 e 307/2016; Relator(a): RAFAEL ALMEIDA BRITO; Julgamento: 13/12/2016; Publicação: 25/01/2017)


	Processo ético-disciplinar. Anuidade. Inadimplência. Ausência de quitação. Suspensão. Dentre os deveres do advogado está a obrigação de pagar seu órgão de classe, sob pena de suspensão pelo período de 30 (trinta) dias, perdurando até quitar a inadimplência. Precedentes.(TED OAB/SE, Primeira Turma; Proc. TED nº. 255/2016, 263/2016, 271/2016 e 287/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	INADIMPLÊNCIA. ANUIDADE. COMPATIBILIDADE DA SUSPENSÃO COM A ORDEM CONSTITUCIONAL. DIFICULDADE FINANCEIRA HODIERNA. SUSPENSÃO. REPRESENTAÇÃO PROCEDENTE.

	1 - A suspensão disciplinar por inadimplência está harmonizada com os ditames constitucionais e apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).

	2 - Apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).

	3 - Representação procedente. Decisão unânime.(TED OAB/SE, Primeira Turma; Proc. TED nº. 259/2016, 267/2016, 275/2016; Relator(a): RAFAEL ALMEIDA BRITO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de três anuidades vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 101/2016 e 261/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de duas anuidades vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 277/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidades 2012, 2013, 2014 e 2015. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de UMA anuidade vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 069/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 085/2016, 093/2016, 117/2016 e 253/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


	Processo ético-disciplinar. Anuidade. Inadimplência. Ausência de quitação. Suspensão. Dentre os deveres do advogado está a obrigação de pagar seu órgão de classe, sob pena de suspensão pelo período de 30 (trinta) dias, perdurando até quitar a inadimplência. Precedentes. PROCEDÊNCIA DA REPRESENTAÇÃO.(TED OAB/SE, Primeira Turma; Proc. TED nº. 127/2016, 135/2016, 143/2016, 167/2016, 175/2016, 183/2016, 191/2016, 199/2016, 207/2016, 215/2016, 223/2016, 231/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	INADIMPLÊNCIA. ANUIDADE. MARCO INICIAL DA PRETENSÃO. DIA ÚTIL SUBSEQUENTE AO VENCIMENTO DA COTA ÚNICA. COMPATIBILIDADE DA SUSPENSÃO COM A ORDEM CONSTITUCIONAL. DIFICULDADE FINANCEIRA HODIERNA. SUSPENSÃO. REPRESENTAÇÃO PROCEDENTE.

	1 - A anuidade não possui natureza tributária, por isso é exigível desde o dia útil subsequente ao do vencimento da cota única, em obediência à inteligência do Enunciado de nº. 06 da Súmula do CFOAB;

	2 - A suspensão disciplinar por inadimplência está harmonizada com os ditames constitucionais e apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).

	3 - Representação procedente. Decisão unânime.(TED OAB/SE, Primeira Turma; Proc. TED nº. 131/2016, 139/2016, 171/2016, 187/2016, 195/2016, 211/2016, 219/2016, 227/2016 e 235/2016; Relator(a): RAFAEL ALMEIDA BRITO; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR FINANCEIRO. ANUIDADE. FALTA DE PAGAMENTO. REITERAÇÃO. SUSPENSÃO ATÉ QUITAÇÃO DA DÍVIDA INTEGRAL E SEUS ACRÉSCIMOS DIFICULDADE FINANCEIRA ALEGADA: DESCABIMENTO. INTELIGÊNCIA DOS ARTIGOS 34, XXIII, 35, II E 37, § 2º, TODOS DO EAOAB.(TED OAB/SE, Primeira Turma; Proc. TED nº. 129/2016, 137/2016, 161/2016, 169/2016, 177/2016, 185/2016, 193/2016, 201/2016, 233/2016, 241/2016 e 249/2016; Relator: CLAY ANDERSON RAMOS PEREIRA; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de três anuidades vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 181/2016 e 197/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de DUAS anuidades vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 141/2016, 165/2016, 213/2016 e 221/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de uma anuidade vigente. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 173/2016 e 229/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	Processo Ético-Disciplinar. Anuidade. Inadimplência. Ausência de Quitação. Reiteração. Inexistência de processos anteriores com a mesma infração Disciplinar Financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados. Representação procedente. (OAB/SE, Primeira Turma; Proc. TED nº. 157/2016, 189/2016 e 205/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 27/10/2016; Publicação: 07/12/2016)


	Processo ético-disciplinar. Anuidade. Inadimplência. Ausência de quitação. Suspensão. Dentre os deveres do advogado está a obrigação de pagar seu órgão de classe, sob pena de suspensão pelo período de 30 (trinta) dias, perdurando até quitar a inadimplência. Precedentes. PROCEDÊNCIA DA REPRESENTAÇÃO. (TED OAB/SE, Primeira Turma; Proc. TED nº. 055/2015, 071/2016, 087/2016, 103/2016 e 121/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 18/10/2016; Publicação: 07/12/2016)


	INADIMPLÊNCIA. CONTROLE DIFUSO DE CONSTITUCIONALIDADE DA PENALIDADE DISCIPLINAR. POSSIBILIDADE. COMPATIBILIDADE DA SUSPENSÃO COM A ORDEM CONSTITUCIONAL. INAFASTABILIDADE DIANTE DA DIFICULDADE FINANCEIRA HODIERNA. SUSPENSÃO. REPRESENTAÇÃO PROCEDENTE.

	1 - A suspensão disciplinar por inadimplência está harmonizada com os ditames constitucionais, seja pela expressa admissão de limitações legais ao livre exercício do trabalho diante do inserto na parte final do inciso XIII do art. 5º da CF/88, seja porque o livre exercício da advocacia é assegurado pela intervenção protetiva da OAB, a qual é financiada pela arrecadação das contribuições devidas à instituição. Portanto, não é razoável e nem proporcional que os inscritos que colaboram para o custeio desta atuação institucional restem desamparados pela insuficiência de recursos para a sua concretização.

	2 - Apenas situações excepcionais devidamente comprovadas, que sejam capazes de ocasionar a incapacidade laborativa, autorizam a atenuação da penalidade com base no art. 40 do EAOAB (Precedentes desta Corte).

	3 - A suspensão pela inadimplência perante a OAB possui nítido caráter cominatório e não se confunde com aquela prevista no §1º do art. 37 do Estatuto da Advocacia. Portanto, não admite a fixação de um prazo determinado.

	4 - Representação procedente. Decisão unânime.(TED OAB/SE, Primeira Turma; Proc. TED nº. 067/2016, 075/2016, 083/2016, 091/2016 e 107/2016; Relator(a): RAFAEL ALMEIDA BRITO; Julgamento: 18/10/2016; Publicação: 07/12/2016)


	ANUIDADE E OUTRAS CONTRIBUIÇÕES. LABOR EM COMISSÃO OU ORGÃO DA OAB/SE – SERVIÇO GRATUITO. INAPLICABILIDADE DO PROVIMENTO 111/2006 DO CFOAB. AUSÊNCIA DE REINCIDÊNCIA. NATUREZA COMINATÓRIA DA SUSPENSÃO. SÚMULA 03 DO CFOAB. PROCEDÊNCIA DA REPRESENTAÇÃO.

	1 - O labor a serviço da instituição é gratuito, como se extrai do contido no art. 48 da Lei 8.906/94 e no art. 109 do Regulamento Geral, sendo as únicas isenções admitidas são aquelas previstas no Provimento de nº. 111/2006 do Conselho Federal.

	2 - No caso da infração por falta de pagamento das contribuições devidas à OAB, a única circunstância capaz de agravar a penalidade e autorizar a aplicação da multa prevista do art. 39 do Estatuto é a reincidência, a qual não se verificou no caso sem análise.

	3 - A suspensão pela inadimplência perante a OAB possui nítido caráter cominatório e não se confunde com aquela prevista no §1º do art. 37 do Estatuto da Advocacia. Portanto, não admite a fixação de um prazo determinado. (TED OAB/SE, Primeira Turma; Proc. TED nº. 027/2016; Relator(a): RAFAEL ALMEIDA BRITO; Julgamento: 30/03/2016; Publicação: 06/05/2016)


	Processo Ético-Disciplinar. Anuidade 2014. Inadimplência. Ausência de quitação. Reiteração. Existência de dois processos anteriores com a mesma infração disciplinar financeira. Suspensão. Dentre os deveres do advogado está o de pagar a anuidade do seu Órgão de Classe. Pena de Suspensão Disciplinar até o adimplemento dos débitos devidamente atualizados e de multa de duas anuidades vigentes. Representação procedente. (TED OAB/SE, Primeira Turma; Processo Disciplinar de nº. 029/2016; Relator: ALISSON FONTES DE ARAGÃO; Julgamento: 30/03/2016; Publicação: 06/05/2016)

	`,
        text2: `DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 308/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 06/12/2016; Publicação: 25/01/2017)
	

	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão por 60 (sessenta) dias, conforme artigo 34, XXIII c/c art. 37, I, e artigo 39, todos da Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 298/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 06/12/2016; Publicação: 25/01/2017)
	

	Disciplinar Financeiro – Ausência de pagamento de anuidades 2014 e 2015 – Procedente – Suspensão por 60 (sessenta) dias, conforme artigo 34, XXIII c/c art. 37, I, todos da Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 306/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 06/12/2016; Publicação: 25/07/2017)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB.(TED OAB/SE, Segunda Turma; Proc. TED nº. 290/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 06/12/2016; Publicação: 25/01/2017)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB.(TED OAB/SE, Segunda Turma; Proc. TED nº. 304/2016; Relator: EVALDO RUI ELIAS; Julgamento: 06/12/2016; Publicação: 25/01/2017)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 296/2016; Relator: EVALDO RUI ELIAS; Julgamento: 06/12/2016; Publicação: 25/01/2017)

	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 60 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 286/2016 e 302/2016; Relator: AGAMENON ALVES FREIRE JUNIOR; Julgamento: 06/12/2016; Publicação: 25/01/2017)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão por 90 (noventa) dias cumulada com multa, conforme artigo 34, XXIII c/c art. 37, I, e artigo 39, todos da Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 250/2016, 258/2016 e 266/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos e multa. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 260/2016 e 180/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO.Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 236/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO.Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 252/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 240/2016 e 248/2016; Relator: EVALDO RUI ELIAS; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 264/2016; Relator: EVALDO RUI ELIAS; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 232/2016; Relator: EVALDO RUI ELIAS; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 60 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 246/2016 e 262/2016; Relator: AGAMENON ALVES FREIRE JUNIOR; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 30 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 254/2016; Relator: AGAMENON ALVES FREIRE JUNIOR; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE EXCLUSÃO DOS QUADROS DA OAB, COM FULCRO NO ARTIGO 38, INC. I DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 238/2016; Relator: AGAMENON ALVES FREIRE JUNIOR; Julgamento: 22/11/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 162/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 170/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 218/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94. (TED OAB/SE, Segunda Turma; Proc. TED nº. 202/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão, conforme artigo 34, XXIII c/c art. 37, I, da Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 130/2016, 138/2016, 178/2016, 186/2016, 194/2016 e 201/2016 e 226/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos e multa. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 204/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos e multa. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 180/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 196/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos e multa. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 132/2016, 140/2016 e 172/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos e multa. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 148/2016, 188/2016, 212/2016, 220/2016, 228/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. ARGUIÇÃO DE PRELIMINAR DE NULIDADE. NÃO ACOLHIMENTO ANTE O TEOR DA SÚMULA 06/2014/OEP. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 156/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 312/2015; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS MAIS MULTA. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º e 39 TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 128/2016 e 216/2016; Relator: EVALDO RUI ELIAS; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS MAIS MULTA. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º e 39 TODOS DO EAOAB.(TED OAB/SE, Segunda Turma; Proc. TED nº. 144/2016; Relator: EVALDO RUI ELIAS; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS MAIS MULTA. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º e 39 TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 176/2016; Relator: EVALDO RUI ELIAS; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 136/2016, 152/2016, 192/2016, 200/2016 e 208/2016; Relator: EVALDO RUI ELIAS; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 60 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, CUMULADA COM MULTA DE UMA ANUIDADE – ART. 37, §2º C/C ART. 39 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 182/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 60 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994. (TED OAB/SE, Segunda Turma; Proc. TED nº. 166/2016 e 190/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 30 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994. (TED OAB/SE, Segunda Turma; Proc. TED nº. 134/2016, 142/2016, 150/2016 e 158/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 04/10/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 098/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão cumulada com multa, conforme artigo34, XXIII c/c art. 37, I, e artigo 39, Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 058/2016, 082/2016, 090/2016 e 114/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	Disciplinar Financeiro – Ausência de pagamento de anuidade 2015 – Procedente – Suspensão, conforme artigo 34, XXIII c/c art. 37, I, da Lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 066/2016, 106/2016, 122/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 30/08/2016; Publicação: 07/12/2016)
	

	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 108/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 092/2016 e 108/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	DISCIPLINAR – ANUIDADE. FALTA DE PAGAMENTO. Suspensão até quitação integral da dívida e seus acréscimos. Dificuldade financeira alegada: irrelevância. Inconstitucionalidade dos arts. 34, XXIII e 37, I, § 2º, EAOAB, alegada: incompetência deste Tribunal para decidir a matéria. Procedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 068/2016 e 088/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB.(TED OAB/SE, Segunda Turma; Proc. TED nº. 064/2016 e 088/2016; Relator: EVALDO RUI ELIAS; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS MAIS MULTA. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º e 39 TODOS DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 056/2016; 072/2016;080/2016;104/2016 e 112/2016; Relator: EVALDO RUI ELIAS; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 60 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 070/2016 e 094/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 30 DIAS E ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994.(TED OAB/SE, Segunda Turma; Proc. TED nº. 078/2016 e 102/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 30/08/2016; Publicação: 07/12/2016)


	REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO – SUSPENSÃO ATÉ QUITAÇÃO INTEGRAL DA DÍVIDA E SEUS ACRÉSCIMOS. DIFICULDADE FINANCEIRA ALEGADA. IRRELAVÂNCIA. ARTIGO 34, XXIII C/C ART. 37, I, §2º TODOS DO EAOAB.(TED OAB/SE, Segunda Turma; Proc. TED nº. 362/2015; Relator: EVALDO RUI ELIAS; Julgamento: 05/07/2016; Publicação: 07/12/2016)


	INFRAÇÃO DISCIPLINAR – FALTA DE PAGAMENTO DE CONTRIBUIÇÕES DEVIDA À OAB/SE – INFRAÇÃO PREVISTA NO INCISO XXIII, DO ARTIGO 34 DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL ATÉ QUE SEJA INTEGRALMENTE QUITADA A DÍVIDA, COM FULCRO NO §2º, DO ARTIGO 37 DA LEI 8.906/1994. (TED OAB/SE, Segunda Turma; Proc. TED nº. 296/2014; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 29/03/2016; Publicação: 06/05/2016)`
    },
    {
        title1: ``,
        title2: `Segunda Turma`,
        text1: ``,
        text2: `PROCESSO ADMISTRATIVO DISCIPLINAR – INFRAÇÃO CAPITULADA NO ART. 34, INCISO XXV DA LEI Nº 8.906/1994 – CONDUTA INCOMPATÍVEL COM A ADVOCACIA – MATERIALIDADE E AUTORIA COMPROVADAS – APLICAÇÃO DA PENALIDADE DE SUSPENSÃO CUMULADA COM MULTA.(OAB/SE, Segunda Turma; Proc. TED nº. 034/2015; Relator: EVALDO RUI ELIAS; Julgamento: 06/12/2016; Publicação: 25/01/2017)
	
	“PRÁTICA DE CONDUTA INCOMPATÍVEL COM A ADVOCACIA. UTILIZAÇÃO DE LINGUAGEM INAPROPRIADA”. Emprego de expressões ordinárias, desprezíveis pelo advogado, ferindo o dever de urbanidade entre colegas, comportamento condenável. Representação procedente – suspensão do advogado por 30 (trinta) dias, nos moldes do inciso, XXV, art. 34, da Lei 8.906/94 e penalidade prevista no art. 37, inciso I, da mesma Lei.(TED OAB/SE, Segunda Turma; Proc. TED nº. 042/2013; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 05/07/2016; Publicação: 07/12/2016)`
    },
    {
        title1: `Primeira Turma`,
        title2: `Segunda Turma`,
        text1: `PACTO DE HONORÁRIOS VERBAL. ACORDO EM AUDIÊNCIA DE INSTRUÇAO. CONFLITO APARENTE NA RESOLUÇÃO DE Nº. 07/2011 – ESPECIALIDADE. FIXAÇÃO ACIMA DA TABELA DA OAB – VALIDADE. ANÁLISE DOS FATOS. CONTRATAÇÃO VIA SINDICATO. INDÍCIOS DE AUTORIA E MATERIALIDADE DE OUTRA INFRAÇÃO. INSTAURAÇÃO DE OFÍCIO. COMPETÊNCIA DE JULGAMENTO DA OUTRA TURMA. IMPROCEDÊNCIA DA REPRESENTAÇÃO E ABERTURA DE NOVO PROCEDIMENTO.
	
	1 - A ausência de prova da infração combinada com a autocomposição perante o Conselheiro-Instrutor autoriza a aplicação restritiva da presunção em favor do cliente nos contratos verbais.

	2 - No aparente conflito entre os critérios estabelecidos no texto da resolução que acompanha a Tabela da OAB e os honorários explicitados em seu anexo, prevalecerá os valores contidos nos anexos da resolução com base no princípio hermenêutico da especialidade.

	3 - A Tabela da OAB representa o mínimo ético e, portanto, é válida e até recomendável a fixação em patamares superiores àqueles nela previstos, desde que obedecidos aos critérios estabelecidos no Código de Ética e Disciplina.

	4 - O órgão fracionado deve instaurar de ofício um novo procedimento disciplinar quando, durante o julgamento, for reconhecida a presença de indícios de materialidade e autoria relativa à infração diversa daquela apreciada (art. 50, I do Código de Ética em vigor).

	5 - No caso de processo disciplinar instaurado de ofício por esta turma, o feito deve ser julgado obrigatoriamente pelo outro órgão fracionado. (TED OAB/SE, Primeira Turma; Proc. TED nº. 019/2016; Relator: RAFAEL ALMEIDA BRITO; Julgamento: 30/03/2016; Publicação: 06/05/2016)

	`,
        text2: `DISCIPLINAR – IMPROCEDÊNCIA. ARQUIVAMENTO. Cobrança de honorários fixados com moderação. Incidência sobre as parcelas concedidas mediante liminar e sobre o valor do crédito retroativo. Afastamento da Súmula nº 111/STJ. (TED OAB/SE, Segunda Turma; Proc. TED nº. 292/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 06/12/2016; Publicação: 25/01/2017)`
    },
    {
        title1: ``,
        title2: `Segunda Turma`,
        text1: ``,
        text2: `INFRAÇÃO DISCIPLINAR – EXERCER A PROFISSÃO, IMPEDIDO DE FAZÊ-LO – INFRAÇÃO PREVISTA NO ARTIGO 34, INCISO I DA LEI 8.906/1994 – REINCIDÊNCIA – APLICAÇÃO DA PENA DE SUSPENSÃO DO EXERCÍCIO PROFISSIONAL POR 30 DIAS. (TED OAB/SE, Segunda Turma; Proc. TED nº. 022/2016; Relator: AGAMENON ALVES FREIRE JÚNIOR; Julgamento: 29/03/2016; Publicação: 06/05/2016)
		
		Acusação de prática de infração prevista no artigo 34, I, da lei 8.906/94 – Advogados regularmente inscritos quando das ocorrências – Improcedência. (TED OAB/SE, Segunda Turma; Proc. TED nº. 018/2016; Relator: HORINO JOAQUIM DO CARMO; Julgamento: 26/04/2016; Publicação: 06/05/2016)`
    },
    {
        title1: `Primeira Turma`,
        title2: `Segunda Turma`,
        text1: `Processo ético-disciplinar. Ausência de provas. Suposta infração disciplinar antevista no art. 34, inciso IX e XXV, do EOAB. IMPROCEDÊNCIA DA REPRESENTAÇÃO.(TED OAB/SE, Primeira Turma; Proc. TED nº. 295/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 13/12/2016; Publicação: 25/01/2017)

		Processo ético-disciplinar. Insuficiência de provas. Não propositura da demanda por falta de documentos. Culpa exclusiva do Representante. Art. 11 da Resolução 02/2015 CFOAB. IMPROCEDÊNCIA DA REPRESENTAÇÃO. (TED OAB/SE, Primeira Turma; Proc. TED nº. 319/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 13/12/2016; Publicação: 25/01/2017)

		REPRESENTAÇÃO. IMPROCEDÊNCIA. ARQUIVAMENTO. Alegações do Representante que não se confirmam durante a instrução, por quaisquer meios de prova que lhe forem ofertados, bem como coerência de argumentos e robustez de prova documental de defesa do Representado, o que conduziu o parecer pelo arquivamento pelo Douto Conselheiro Seccional, parecer o qual não há como não se seguir, frente à COMPROVADA ausência de qualquer falta disciplinar pelo Representado. (TED OAB/SE, Primeira Turma; Proc. TED nº. 017/2016; Relator: CLAY ANDERSON RAMOS PEREIRA; Julgamento: 30/03/2016; Publicação: 06/05/2016)
		`,
        text2: `DISCIPLINAR – IMPROCEDÊNCIA. ARQUIVAMENTO. Ausência de comprovação dos fatos alegados na representação. O exercício da advocacia é atividade de meio e não de resultado. Ausência de prejuízo ao Representante. (TED OAB/SE, Segunda Turma; Proc. TED nº. 284/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 22/11/2016; Publicação: 07/12/2016)`
    },
    {
        title1: `Primeira Turma`,
        title2: `Segunda Turma`,
        text1: `PACTO QUOTA LITIS. RETENÇÃO DE VALORES. AUSÊNCIA DE AUTORIZAÇÃO PRÉVIA DO CLIENTE. LOCUPLETAMENTO. IMPOSSIBILIDADE DE ASSEGURAR O PAGAMENTO DOS HONORÁRIOS. ATIPICIDADE DO ART. 34, XXV DO EAOAB. REPRESENTAÇÃO PARCIALMENTE PROCEDENTE.

		1 - Incide na infração descrita no inciso XX do art. 34 do EOAB pelo locupletamento o patrono que realiza a compensação ou desconto de honorários contratados sem a expressa anuência prévia do seu cliente.

		2 - Nos contratos de risco, o êxito da causa só se aperfeiçoa com a satisfação do pedido mediato e, portanto, o montante a ser recebido deve ser proporcional ao proveito econômico efetivo do cliente, ressalvada disposição contratual em contrário que não ultrapasse o teto previsto no Código de Ética e Disciplina.

		3 - É vedado ao advogado reter valores com o objetivo de assegurar o cumprimento da parcela de honorários que aguardam a atendimento de condição suspensiva para a sua eficácia no mundo jurídico.

		4 - A manutenção de conduta incompatível à advocacia exige um comportamento reiterado do infrator que reclama a aplicação de uma penalidade mais rigorosa do que a censura prevista pelo legislador, sob pena de atipicidade.

		5 - Representação parcialmente procedente.(TED OAB/SE, Primeira Turma; Proc. TED nº. 035/2016; Relator: RAFAEL ALMEIDA BRITO; Julgamento: 14/11/2016; Publicação: 07/12/2016)


		Representação por conduta incompatível com a advocacia. Confissão de retenção de valores pertencentes ao seu cliente. Arguição de nulidade pelo não comparecimento da parte na audiência de instrução – Porém, na audiência de instrução não houve alegação de nada além do que já havia na representação. Procedência da Representação. Inteligência dos arts. 34, incisos XIX, XX e XXV e art. 37, inciso I e § 1º. e 2º. do EAOAB. Pena de suspensão de 30 dias, prorrogáveis até que satisfaça integralmente a obrigação, inclusive com a correção monetária.(TED OAB/SE, Primeira Turma; Proc. TED nº. 055/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 18/10/2016; Publicação: 07/12/2016)


		PROCESSO ÉTICO DISCIPLINAR – REPRESENTAÇÃO – SUPOSTA INFRAÇÃO DISCIPLINAR – INCISOS IX, XX E XXV DO ART. 34 DO EAOAB – INOCORRÊNCIA – INEXISTÊNCIA DE PROVAS – RECIBO DE PAGAMENTO DE HONORÁRIOS SEM ASSINATURA DO REPRESENTADO – AUSÊNCIA DE INSTRUMENTO DE CONTRATO DE HONORÁRIOS – PRAZO PARA INDICAÇÃO DE OUTRAS PROVAS SEM MANIFESTAÇÃO – IMPROCEDÊNCIA DA REPRESENTAÇÃO. (OAB/SE, Primeira Turma; Proc. TED nº. 045/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 06/07/2016; Publicação: 07/12/2016)`,
        text2: `“RETENÇÃO DE VALORES”. Retenção de valores pelo advogado, refletindo no patrimônio do representante e desídia do profissional, comportamento condenável. Representação procedente – suspensão do advogado 180 (cento e oitenta) dias, prorrogáveis até o pagamento do valor atualizado monetariamente e multa de cinco anuidades, nos moldes dos incisos, XX, XXI e XXV, art. 34, da Lei 8.906/94 e penalidades previstas no art. 37, inciso I, §2º e art. 39 e parágrafo único do art. 40, “a” e “b”, da mesma Lei. (TED OAB/SE, Segunda Turma; Proc. TED nº. 044/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 05/07/2016; Publicação: 07/12/2016)

		
		RETENÇÃO DE VALORES. Retenção de valores por advogado(a) pelo período de 8 (oito) meses, refletindo no patrimônio do representante e desídia do profissional, comportamento condenável. Representação procedente – suspensão do advogado por 30 (trinta) dias nos moldes do inciso XXI e XXV, art. 34, da Lei 8.906/94 e penalidades prevista no art. 37, inciso I, da mesma Lei. (TED OAB/SE, Segunda Turma; Proc. TED nº. 012/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 29/03/2016; Publicação: 06/05/2016)


		RETENÇÃO DE VALORES. Retenção de valores por advogado(a) pelo período de aproximadamente 3 (três) anos, refletindo no patrimônio do representante e desídia do profissional, comportamento condenável. Representação procedente – suspensão do advogado por 120 (cento e vinte) dias nos moldes do inciso XXI e XXV, art. 34, da Lei 8.906/94 e penalidades prevista no art. 37, inciso I, e art. 39 e parágrafo único do art. 40, ‘a’ e ‘b’ da mesma Lei. (TED OAB/SE, Segunda Turma; Proc. TED nº. 009/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 29/03/2016; Publicação: 06/05/2016)
		

		REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – ATO LESIVO AO CLIENTE – INOCORRÊNCIA – AUSÊNCIA DE ELEMENTOS E PROVAS SUFICIENTES – IMPROCEDÊNCIA DA REPRESENTAÇÃO. (TED OAB/SE, Segunda Turma; Proc. TED nº. 008/2016; Relator: EVALDO RUI ELIAS; Julgamento: 29/03/2016; Publicação: 06/05/2016)


		REPRESENTAÇÃO – INFRAÇÃO DISCIPLINAR – INCISOS IX, XX E XXV DO ART. 34 DO EAOAB – INOCORRÊNCIA – PROVA INSUFICIENTE – IMPROCEDÊNCIA DA REPRESENTAÇÃO. (OAB/SE, Segunda Turma; Proc. TED nº. 494/2014; Relator: EVALDO RUI ELIAS; Julgamento: 29/03/2016; Publicação: 06/05/2016)
		`
    },
    {
        title1: `Primeira Turma`,
        title2: `Segunda Turma`,
        text1: `PROCESSO ÉTICODISCIPLINAR INSTAURADO. REPRESENTAÇÃO. ARGUIÇÃO DE NÃO INFORMAÇÃO DO DIA DA PERÍCIA. INCABIDA. CIÊNCIA DA REALIZAÇÃO DA PERÍCIA. ARGUIÇÃO DE INTERPOSIÇÃO DE RECURSO INTEMPESTIVO. DESOBRIGAÇÃO EM RECORRER. INEXISTÊNCIA DE CONTRATO DE HONORÁRIOS QUE OBRIGASSE O REPRESENTADO. SERVIÇOS PRESTADOS ATRAVÉS DA SECRETARIA DE ASSISTÊNCIA JUDICIÁRIA GRATUITA DO MUNICÍPIO DE NOSSA SENHORA DO SOCORRO EM CONJUNTO COM DIVERSOS CAUSÍDICOS COM ROL CONSIGNADO NA PROCURAÇÃO. (OAB/SE, Primeira Turma; Proc. TED nº. 033/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 13/12/2016; Publicação: 25/01/2017)


		Representação. Improcedência. Arquivamento. Impossibilidade de êxito na demanda civil. Comprovação de que o Representante não era servidor do ente federativo. Sapiência em não recorrer da decisão, evitando a possibilidade de pagamentos desnecessários pelo Representante. Representado arcou com os honorários sucumbenciais, a fim de evitar prejuízos ao Representante. (TED OAB/SE, Primeira Turma; Proc. TED nº. 031/2016; Relator(a): TARCISIO ANDRE TARGINO MATOS; Julgamento: 27/04/2016; Publicação: 06/05/2016)
		
		`,
        text2: `Representação – Negativa de substabelecimento – Improcedente, não ocorrência de infração prevista no inciso IX do artigo 34, lei 8.906/94.(TED OAB/SE, Segunda Turma; Proc. TED nº. 274/2016; Relator(a): HORINO JOAQUIM DO CARMO; Julgamento: 22/11/2016; Publicação: 07/12/2016)`
    },
    {
        title1: `Primeira Turma`,
        title2: `Segunda Turma`,
        text1: `PRESCRIÇÃO INTECORRENTE. ARQUIVAMENTO. O prazo da prescrição intercorrente se inicia a partir da ciência do fato pela instituição (Súmula nº. 1 do CFOAB). Ciência do fato ocorrida entre o dia 09/09/2010 (fls. 06 dos autos).(TED OAB/SE, Primeira Turma; Proc. TED nº. 311/2016; Relator: TARCISIO ANDRE TARGINO MATOS; Julgamento: 13/12/2016; Publicação: 25/01/2017)
		
		
		PRESCRIÇÃO INTECORRENTE. ARQUIVAMENTO. O prazo da prescrição intercorrente se inicia a partir da ciência do fato pela instituição (Súmula nº. 1 do CFOAB). Ciência do fato ocorrida entre o dia 18/08/2010 (fls. 02 dos autos). (OAB/SE, Primeira Turma; Proc. TED nº. 317/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 13/12/2016; Publicação: 25/01/2017)
		
		
		PRESCRIÇÃO INTECORRENTE. ARQUIVAMENTO. O prazo da prescrição intercorrente se inicia a partir da ciência do fato pela instituição (Súmula nº. 1 do CFOAB). Ciência do fato ocorrida entre o dia 07/07/2011 (fls. 04 dos autos).(OAB/SE, Primeira Turma; Proc. TED nº. 237/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)
		
		
		PRESCRIÇÃO INTECORRENTE. ARQUIVAMENTO. O prazo da prescrição intercorrente se inicia a partir da ciência do fato pela instituição (Súmula nº. 1 do CFOAB). Ciência do fato ocorrida entre o dia 22/09/2010 (fls. 02 dos autos).(OAB/SE, Primeira Turma; Proc. TED nº. 245/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 14/11/2016; Publicação: 07/12/2016)
		
		
		PRESCRIÇÃO INTERCORRENTE. ARQUIVAMENTO. O prazo da prescrição intercorrente se inicia a partir da ciência do fato pela instituição (Súmula nº 1 do CFOAB). Ciência do fato ocorrida entre o dia 09/08/2011 e 26/09/2011 (fls. 04 e 05 dos autos).(OAB/SE, Primeira Turma; Proc. TED nº. 053/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 18/10/2016; Publicação: 07/12/2016)`,
        text2: `“PRESCRIÇÃO DA PRETENSÃO PUNITIVA”. Observada a notificação válida para apresentação de defesa prévia em 02/12/2010, até o momento não ocorreu qualquer decisão condenatória recorrível. Reconhecimento de ofício. Ensinamentos do art. 43, EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 276/2016; Relator: GLOVER RUBIO DOS SANTOS CASTRO; Julgamento: 22/11/2016; Publicação: 07/12/2016)`
    },
    {
        title1: `Primeira Turma`,
        title2: ``,
        text1: `AUSÊNCIA DE PROCURAÇÃO. SUBSCRIÇÃO DA REPRESENTANTE NO ACORDO. ENTENDIMENTO SEM CONSENTIMENTO DO CLIENTE – INOCORRÊNCIA. INDEPENDÊNCIA DA INSTÂNCIA DISCIPLINAR – SOBRESTAMENTO INVIÁVEL. AUSÊNCIA DE CONDUTA INCOMPATÍVEL COM A ÉTICA PROFISSIONAL. IMPROCEDÊNCIA DA REPRESENTAÇÃO.
		
		1 - A falta de procuração pode ser suprida pela confirmação do patrocínio assinado pelo cliente ou seu representante legal.
		
		2 - Representação improcedente. (TED OAB/SE, Primeira Turma; Proc. TED nº. 011/2016; Relator: RAFAEL ALMEIDA BRITO; Julgamento: 30/03/2016; Publicação: 06/05/2016)`,
        text2: ``
    },
    {
        title1: 'Primeira Turma',
        title2: 'Segunda Turma',
        text1: `PROCESSO ÉTICO DISCIPLINAR INSTAURADO POR FORÇA DA SOLICITAÇÃO DE PROVIDÊNCIAS DO CONSELHO FEDERAL DA OAB – INEXISTÊNCIA DE INTIMAÇÃO PARA DEVOLUÇÃO DOS AUTOS – NÃO CONFIGURAÇÃO DAS CONDUTAS APONTADAS DIANTE DA ANÁLISE MAIS MINUNCIOSA DOS FATOS.(OAB/SE, Primeira Turma; Proc. TED nº. 025/2016; Relator: ALISSON FONTES ARAGÃO; Julgamento: 13/12/2016; Publicação: 25/01/2017)
		
		Retenção abusiva dos autos. Necessário que fique comprovado o excesso temporal, de permanência com os autos, ocasionando o prejuízo, pois o processo já havia transitado em julgado e já estava arquivado. Improcedência da representação ante a falta de intimação pessoal do advogado para devolução dos autos em momento propício. Quando ocorreu, houve a devida devolução (TED OAB/SE, Primeira Turma; Proc. TED nº. 001/2016; Relator: TARCISIO ANDRE TARGINO MATOS; Julgamento: 27/04/2016; Publicação: 06/05/2016)`,
        text2: `REPRESENTAÇÃO – RETENÇÃO ABUSIVA DOS AUTOS APESAR DE DEVIDAMENTE NOTIFICADA – REINCIDÊNCIA DA CONDUTA – ART. 34, XXII DO EAOAB – PENA DE SUSPENSÃO E MULTA – ART. 37, I, §1º C/C ART. 39 E 40 DO EAOAB. (TED OAB/SE, Segunda Turma; Proc. TED nº. 024/2016; Relator: EVALDO RUI ELIAS; Julgamento: 29/03/2016; Publicação: 06/05/2016)`
    }
]

export default infoTile

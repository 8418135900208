import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import '../Input/styles.scss';

const InputArea = ({label, margin, name, smallContent, small, placeholder, ...rest}) => {
  const inputRef = useRef(null);
  const {registerField , fieldName, defaultValue, error} = useField(name);


  useEffect(() => {
    registerField({
      name:fieldName,
      ref: inputRef.current,
      path:'value'
    })
  },[fieldName, registerField])

  return (
    <div id='input'>
      <div
        className='form-group'
      >
          <label className='label'>
            {label}
          </label>
        <textarea
          className='form-control-area'
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        > 
        </textarea>
        {small && <small className="small">{smallContent}</small>}
        {error && <span className="error"> {error}</span>}
      </div>
    </div>
  );
};

export default InputArea;

import React, { useState, useEffect } from "react";

import { Title } from "../../../components";
import { getApi } from "../../../services/api.js";
import "./styles.scss";
const Eleicoes = () => {
  const [mostrar, setMostrar] = useState(false);
  const [mostrar2, setMostrar2] = useState(false);
  const [mostrar3, setMostrar3] = useState(false);
  const [mostrar4, setMostrar4] = useState(false);
  const [mostrar5, setMostrar5] = useState(false);
  const [mostrar6, setMostrar6] = useState(false);

  const [edital, setEdital] = useState(null);
  const [resolucao, setResolucao] = useState(null);
  const [portaria, setPortaria] = useState(null);
  const [chapa, setChapa] = useState(null);
  const [votantes_estancia, setVotantesEstancia] = useState(null);
  const [votantes_gloria, setVotantesGloria] = useState(null);
  const [votantes_itabaiana, setVotantesItabaiana] = useState(null);
  const [votantes_lagarto, setVotantesLagarto] = useState(null);
  const [votantes_propria, setVotantesPropria] = useState(null);

  const BuscarDadosEleicoes = async () => {
    try {
      const response = await getApi.get("pages/50173");
      const response2 = await getApi.get("pages/50173");
      const response3 = await getApi.get("pages/50173");
      const response4 = await getApi.get("pages/50173");
      const response5 = await getApi.get("pages/50173");
      const response6 = await getApi.get("pages/50173");
      const response7 = await getApi.get("pages/50173");
      const response8 = await getApi.get("pages/50173");
      const response9 = await getApi.get("pages/50173");
      setEdital(response.data);
      setResolucao(response2.data);
      setPortaria(response3.data);
      setChapa(response4.data);
      setVotantesEstancia(response5.data);
      setVotantesGloria(response6.data);
      setVotantesItabaiana(response7.data);
      setVotantesLagarto(response8.data);
      setVotantesPropria(response9.data);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    BuscarDadosEleicoes();
  }, []);

  return (
    <section id="section-1">
      <div className="title-eleicoes">
        <Title
          textContent="ELEIÇÕES 2021"
          customClass="title-page"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
      </div>
      <div className="container-eleicoes">
        <div className="div-eleicoes">
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar(!mostrar);
            }}
          >
            Edital
          </button>
          {mostrar && (
            <a
              href={edital?.acf?.edital?.url}
              target="_blank"
              className="link-eleicoes"
            >
              {edital?.acf?.edital?.title}
            </a>
          )}
        </div>
        <div>
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar2(!mostrar2);
            }}
          >
            Resoluções
          </button>
          {mostrar2 && (
            <a
              href={resolucao?.acf?.resolucoes?.url}
              target="_blank"
              className="link-eleicoes"
            >
              {resolucao?.acf?.resolucoes?.title}
            </a>
          )}
        </div>
        <div>
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar3(!mostrar3);
            }}
          >
            Portarias
          </button>
          {mostrar3 && (
            <a
              href={portaria?.acf?.portarias?.url}
              target="_blank"
              className="link-eleicoes"
            >
              {portaria?.acf?.portarias?.title}
            </a>
          )}
        </div>
        <div>
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar4(!mostrar4);
            }}
          >
            Chapas
          </button>
          {mostrar4 && (
            <a
              href={chapa?.acf?.chapas?.url}
              target="_blank"
              className="link-eleicoes"
            >
              {chapa?.acf?.chapas?.title}
            </a>
          )}
        </div>
        <div className="div-eleicoes">
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar5(!mostrar5);
            }}
          >
            Votantes Regionais
          </button>
          {mostrar5 && (
            <div className="text-eleicoes-adv">
              LISTA DE ADVOGADOS DAS REGIONAIS DO INTERIOR <br />
              <div className="votantes">
                <div>
                  <a
                    href={
                      votantes_estancia?.acf?.votantes_regionais
                        ?.regional_estancia?.url
                    }
                    target="_blank"
                    className="link-eleicoes"
                  >
                    {
                      votantes_estancia?.acf?.votantes_regionais
                        ?.regional_estancia?.title
                    }
                  </a>
                </div>
                <div>
                  <a
                    href={
                      votantes_gloria?.acf?.votantes_regionais?.regional_gloria
                        ?.url
                    }
                    target="_blank"
                    className="link-eleicoes"
                  >
                    {
                      votantes_gloria?.acf?.votantes_regionais?.regional_gloria
                        ?.title
                    }
                  </a>
                </div>
                <div>
                  <a
                    href={
                      votantes_itabaiana?.acf?.votantes_regionais
                        ?.regional_itabaiana?.url
                    }
                    target="_blank"
                    className="link-eleicoes"
                  >
                    {
                      votantes_itabaiana?.acf?.votantes_regionais
                        ?.regional_itabaiana?.title
                    }
                  </a>
                </div>
                <div>
                  <a
                    href={
                      votantes_lagarto?.acf?.votantes_regionais
                        ?.regional_lagarto?.url
                    }
                    target="_blank"
                    className="link-eleicoes"
                  >
                    {
                      votantes_lagarto?.acf?.votantes_regionais
                        ?.regional_lagarto?.title
                    }
                  </a>
                </div>
                <div>
                  <a
                    href={
                      votantes_propria?.acf?.votantes_regionais
                        ?.regional_propria?.url
                    }
                    target="_blank"
                    className="link-eleicoes"
                  >
                    {
                      votantes_propria?.acf?.votantes_regionais
                        ?.regional_propria?.title
                    }
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="div-eleicoes">
          <button
            type="button"
            className="button-eleicoes"
            onClick={() => {
              setMostrar6(!mostrar6);
            }}
          >
            Justificativa Eleição
          </button>
          {mostrar6 && (
            <div className="justificativa">
              <p className="text-eleicoes">
                Conforme o art. 6º da Resolução 10/2021, o voto é obrigatório
                para todos os advogados regularmente inscritos da OAB, sob pena
                de multa equivalente a 20% (vinte por cento) sobre o valor da
                anuidade (art. 134, do Regulamento Geral da Advocacia e da OAB),
                salvo ausência justificada por escrito,{" "}
                <b>
                  apresentada em até 30 (trinta) dias após a realização do
                  pleito, a ser apreciada pela Diretoria do Conselho Seccional.
                </b>{" "}
                <br />
                Para fazer a sua justificativa Eleitoral{" "}
                <a
                  href="https://www6.oab.org.br/sgd/livre/justificativa/eleitoral/db81163c-b52d-4b23-84d4-1e28e1a2f47f"
                  target="_blank"
                  className="link-eleicoes"
                >
                  clique aqui
                </a>
                <br />
                Observação: <br />
                Para anexar mais de um documento, faz-se necessário junta-los em
                único arquivo.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default Eleicoes;

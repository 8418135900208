import React, { useState } from "react";

import Carousel from 'react-elastic-carousel';
import { BsArrowBarLeft, BsArrowBarRight } from 'react-icons/bs'

import {
  Modal,
  Grid
} from "@material-ui/core"

import { carousel } from "../data"

export const Galery = () => {

  return (
    <div id="galery">
      <Carousel
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div id="rowDiv">
              {pages.map(page => {
                const isActivePage = activePage === page
                return (
                  <button
                    key={page}
                    onClick={() => onClick(page)}
                    active={isActivePage}
                    id={isActivePage ? "buttonPages-active" : "buttonPages"}
                  >
                    {page + 1}
                  </button>
                )
              })}

            </div>
          )
        }}
      >
        <div id="pagina1">
          <Grid container spacing={5}>
            <Grid item xs={12} md={8}>
              <img src={carousel[0].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[1].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[2].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[3].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[4].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[5].foto} alt="" />
            </Grid>
          </Grid>
        </div>
        <div id="pagina2">
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <img src={carousel[2].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[3].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[4].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[5].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[0].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[1].foto} alt="" />
            </Grid>

          </Grid>
        </div>
        <div id="pagina3">
          <Grid container spacing={5}>

            <Grid item xs={12} md={8}>
              <img src={carousel[8].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[9].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[6].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[7].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={8}>
              <img src={carousel[4].foto} alt="" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src={carousel[5].foto} alt="" />
            </Grid>
          </Grid>
        </div>
      </Carousel>
    </div>
  )
}
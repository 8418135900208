import axios from 'axios';

export async function pagarTaxa(body) {
    await axios.post("https://portaldoadvogado.herokuapp.com/pagseguro", body)
        .then((res) => {
            alert("Você será agora redirecionado para a página do PagSeguro, para prosseguir com o pagamento!");
            window.sessionStorage.clear();
            window.open(`https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${res.data.token}`, "_blank")
        })
        .catch((err) => {
            console.log(err)
        })
}

export async function gerarBoleto(body) {
    await axios.post("https://portaldoadvogado.herokuapp.com/iugu", body)
        .then((res) => {
            let prosseguir
                = window.confirm(`Boleto gerado com sucesso!\n\nCopie o código de barras: ${res.data.identification}\n\nou prossiga para baixar o pdf`);
            if (prosseguir === true) {
                window.open(res.data.pdf)
            }
            window.sessionStorage.clear();

        })
        .catch((err) => {
            if (err.response.status === 422) {
                console.log(err.response)
            } else if (err.response.status === 500) {
                window.alert("Estamos com problemas. Por favor tente mais tarde!")
            } else {
                window.alert("Não foi possível gerar seu boleto. Por favor tente novamente!")
            }
        })
}

export function allSet(body) {
    for (const value of body) {
        if (value === "") return false;
    }
    return true;
}

export function validarCPF(cpf) {
    var Soma;
    var Resto;
    var i;
    Soma = 0;
    if (cpf === '') return false;
    else if (cpf.length !== 11) return false;
    else if (cpf === "00000000000") return false;
    else {
        for (i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(cpf.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(cpf.substring(10, 11))) return false;

    }
    return true;
}

export function validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;
    else if (cnpj.length !== 14) return false;
    // Elimina CNPJs invalidos conhecidos
    else if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;
    else {
        var tamanho = cnpj.length - 2;
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(0)) return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(1)) return false;
    }
    return true;

}
//export const cpfRegex = new RegExp(/^([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/)
//export const cnpjRegex = new RegExp(/^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})/)

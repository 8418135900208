import React from 'react';
import "./styles.scss";
// import { Container } from './styles';

function Diretoria() {
    return (
        <div id="esa-diretoria-container">
            <div id="diretoria-background" />
            <div id="diretores">
                <div className="title title-start">Nome</div>
                <div className="title title-end">Função</div>

                <div className="elements element-start">KLEIDSON NASCIMENTO DOS SANTOS</div>
                <div className="elements element-end">DIRETOR GERAL</div>

                <div className="elements element-start">SILVANIA FARIAS CRUZ</div>
                <div className="elements element-end">VICE-DIRETORA</div>
            </div>
        </div>
    );
}

export default Diretoria;
import React from 'react'
import { Link } from 'react-router-dom';
import {
    StyledTypography,
    StyledBottomPattern,
    HideMobile,
    ShowMobile
} from './Saibamais.elements'
import executivos from '../../assets/images/Home/homeSaibaMaisHands.png'
import conversa from '../../assets/images/Home/homeSaibaMaisPeople.png'
import justica from '../../assets/images/Home/homeSaibaMaisScale.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import img1 from '../../assets/images/Home/arrow-left.png'
import img2 from '../../assets/images/Home/arrow-left-right.png'
import img3 from '../../assets/images/Home/arrow-right.png'
import './styles.scss'

const Saibamais = () => {
    return (
        <>
            <HideMobile>
                <div className="container">
                    <div className="card-group">
                        <div className="card card_no_border noOverflow scaleToRight">
                            <img
                                className="card-img-top"
                                src={conversa}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        E
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        SA
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                                <p className="textoComplementar">
                                    Dê um passo a frente
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <a
                                    href="https://esa-hg.web.app/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </a>
                            </div>
                        </div>
                        <div className="card card_no_border noOverflow scaleCenter">
                            <img
                                className="card-img-top"
                                src={executivos}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        CAA
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        /SE
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                                <p className="textoComplementar">
                                    Caixa de Assistência dos Advogados de
                                    Sergipe
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <a
                                    href="https://caase.com.br/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </a>
                            </div>
                        </div>
                        <div className="card card_no_border noOverflow scaleToLeft">
                            <img
                                className="card-img-top"
                                src={justica}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        Tribu
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        nal de Ética
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                                <p className="textoComplementar">
                                    "Zelar pelo cumprimento do Estatuto da
                                    Advocacia"
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <Link
                                    to="/institucional/tribunal-de-etica"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </HideMobile>
            <ShowMobile>
                <div className="container">
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        autoPlay={false}
                    >
                        <div className="card_relative_mobile">
                        <div><img src={img1} className="pb-3" alt="" /></div>
                            <div className="card card_no_border noOverflowMobile scaleToRight">
                            <img
                                className="card-img-top"
                                src={conversa}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        E
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        SA
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                                <p className="textoComplementar">
                                    Dê um passo a frente
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <a
                                    href="https://esa-hg.web.app/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </a>
                            </div>
                        </div>
                        </div>

                        <div className="card_relative_mobile">
                        <div><img src={img2} className="pb-3" alt="" /></div>
                            <div className="card card_no_border noOverflowMobile scaleCenter">
                            <img
                                className="card-img-top"
                                src={executivos}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        CAA
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        /SE
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                                <p className="textoComplementar">
                                    Caixa de Assistência dos Advogados de
                                    Sergipe
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <a
                                    href="https://caase.com.br/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </a>
                            </div>
                        </div>
                        </div>
                        <div className="card_relative_mobile">
                            <div><img src={img3} className="pb-3" alt="" /></div>
                            <div className="card card_no_border noOverflowMobile scaleToLeft">
                            <img
                                className="card-img-top"
                                src={justica}
                                alt="aa"
                            />
                            <div className="card-img-overlay">
                            
                                <StyledTypography>
                                    <StyledBottomPattern regular>
                                        Tribu
                                    </StyledBottomPattern>
                                    <StyledBottomPattern>
                                        nal de Ética
                                    </StyledBottomPattern>
                                </StyledTypography>{' '}
                            
                                <p className="textoComplementar">
                                    "Zelar pelo cumprimento do Estatuto da
                                    Advocacia"
                                </p>
                            </div>
                            <div
                                className="card-above"
                                style={{ textAlign: 'center', color: 'white' }}
                            >
                                <Link
                                    to="/institucional/tribunal-de-etica"
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    Saiba Mais
                                </Link>
                            </div>
                        </div>
                        </div>
                    </Carousel>
                </div>
            </ShowMobile>
        </>
    )
}

export default Saibamais

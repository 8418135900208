const data = [
    {
        nome: "Acre",
        value: "1",
        label: "Acre"
    },
    {
        nome: "Alagoas",
        value: "2",
        label: "Alagoas"
    },
    {
        nome: "Amapá",
        value: "3",
        label: "Amapá"
    },
    {
        nome: "Amazonas",
        value: "4",
        label: "Amazonas"
    },
    {
        nome: "Bahia",
        value: "5",
        label: "Bahia"
    },
    {
        nome: "Ceará",
        value: "6",
        label: "Ceará"
    },
    {
        nome: "Distrito Federal",
        value: "7",
        label: "Distrito Federal"
    },
    {
        nome: "Espírito Santo",
        value: "8",
        label: "Espírito Santos"
    },
    {
        nome: "Goiás",
        value: "9",
        label: "Goiás"
    },
    {
        nome: "Maranhão",
        value: "10",
        label: "Maranhão"
    },
    {
        nome: "Mato Grosso",
        value: "11",
        label: "Mato Grosso"
    },
    {
        nome: "Mato Grosso do Sul",
        value: "12",
        label: "Mato Grosso do Sul"
    },
    {
        nome: "Minas Gerais",
        value: "13",
        label: "Minas Gerais"
    },
    {
        nome: "Pará",
        value: "14",
        label: "Pará"
    },
    {
        nome: "Paraíba",
        value: "15",
        label: "Paraíba"
    },
    {
        nome: "Paraná",
        value: "16",
        label: "Paraná"
    },
    {
        nome: "Pernambuco",
        value: "17",
        label: "Pernambuco"
    },
    {
        nome: "Piauí",
        value: "18",
        label: "Piauí"
    },
    {
        nome: "Rio de Janeiro",
        value: "19",
        label: "Rio de Janeiro"
    },
    {
        nome: "Rio Grande do Norte",
        value: "20",
        label: "Rio Grande do Norte"
    },
    {
        nome: "Rio Grande do Sul",
        value: "21",
        label: "Rio Grande do Sul"
    },
    {
        nome: "Rondônia",
        value: "22",
        label: "Rondônia"
    },
    {
        nome: "Roraima",
        value: "23",
        label: "Roraima"
    },
    {
        nome: "Santa Catarina",
        value: "24",
        label: "Santa Catarina"
    },
    {
        nome: "São Paulo",
        value: "25",
        label: "São Paulo"
    },
    {
        nome: "Sergipe",
        value: "26",
        label: "Sergipe"
    },
    {
        nome: "Tocantins",
        value: "27",
        label: "Tocantins"
    },
]

export default data;
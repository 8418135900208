import React from 'react';

function V_Icon({ className }) {
    return (
        <svg className={className} id="check-path" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
            <g id="Elipse_6" data-name="Elipse 6" fill="#fff" stroke="#3e92b7" stroke-width="5">
                <circle cx="21" cy="21" r="21" stroke="none" />
                <circle cx="21" cy="21" r="18.5" fill="none" />
            </g>
            <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7.6,20.936.328,13.663a1.119,1.119,0,0,1,0-1.582L1.91,10.5a1.119,1.119,0,0,1,1.582,0l4.9,4.9L18.884,4.9a1.119,1.119,0,0,1,1.582,0l1.582,1.582a1.119,1.119,0,0,1,0,1.582L9.182,20.936A1.119,1.119,0,0,1,7.6,20.936Z" transform="translate(9.658 8.08)" fill="#3e92b7" />
        </svg>
    );
}

export default V_Icon;
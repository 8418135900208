import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import { Title } from "./../../../components";

import "./styles.scss";
import Input from "../Components/Input";
import InputSelect from "../Components/InputSelect";
import InputArea from "./../Components/InputArea/index";

const Ouvidoria = () => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucess, setIsSucess] = useState(false);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    fetch("https://getinfomail.herokuapp.com/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dest: "marcus.poletti@getinfo.net.br",
        sub: "Ouvidoria - OAB/SE",
        html: `
                    <p><strong>Nome: </strong>
                     ${data.nome}</p>
                     <p><strong>E-mail: </strong>
                     ${data.email}</p>
                     <p><strong>Assunto: </strong>
                     ${data.assunto}</p>
                     <p><strong>Mensagem: </strong>
                     ${data.mensagem}</p>`,
      }),
    })
      .then(() => {
        alert(
          "Formulário enviado com sucesso! Entraremos em contato em breve!"
        );
      })
      .catch(() => {
        setIsLoading(false);
        // eslint-disable-next-line no-alert
        alert(
          "Desculpe, mas parece que houve algum problema ao enviar o seu formulário. Tente novamente mais tarde."
        );
      });
  };

  const assuntos = [
    { value: "Reclamação", label: "Reclamação" },
    { value: "Denúncia", label: "Denúncia" },
    { value: "Pedido de Informação", label: "Pedido de Informação" },
    { value: "Sugestão", label: "Sugestão" },
    { value: "Elogio", label: "Elogio" },
    { value: "Comentário", label: "Comentário" },
  ];

  return (
    <div id="ouvidoria">
      <section className="section-1">
        <Title
          textContent="ouvidoria"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;Fale conosco >"
          link3="&nbsp;ouvidoria"
          rota1="/"
          customClassBackground="background-red-horizontal"
          title
        />
      </section>
      <section className="section-2">
        <div className="container-form">
          <div className="container-contact">
            <span>Envie e-mail para:</span>
            <a href="mailto:ouvidoria@oabsergipe.org.br">
              ouvidoria@oabsergipe.org.br
            </a>
            <span>
              ou se preferir <br /> utilize o formulário
            </span>
            <p>
              Todos os campos de envio são obrigatórios. Por favor, preencha
              cada área com o que é requisitado.
            </p>
          </div>
          <Form ref={formRef} className="form" onSubmit={handleSubmit}>
            <Input label="Nome" name="nome" type="text" required />
            <Input label="E-mail" name="email" type="text" required />
            <InputSelect
              placeholder="Selecione"
              label="Assunto"
              name="assunto"
              options={assuntos}
              className="retangle"
              required
            />
            <InputArea label="Mensagem" name="mensagem" type="text" required />
            <button className="button" type="submit">
              Enviar
            </button>
          </Form>
        </div>
      </section>
    </div>
  );
};

export default Ouvidoria;

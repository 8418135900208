import React, { useState } from "react";
import "./styles.scss";

const Card = ({ children, height, fontsize, colorHoverClass, paddingCard, onClick }) => {
  const [color, setColor] = useState(false);

  return (
    <>
      <div
        onClick={onClick}
        className={
          color
            ? "pointer card-component color-two"
            : `${colorHoverClass ?? "color-one"} pointer card-component`
        }
      >
        <div
          className={`${paddingCard ?? "body-style-web"} card-body`}
          // class="card-body body-style-web"
          
        >
          <span
            className="card-style-web"
            style={{ fontSize: fontsize ?? "1.2em" }}
          >
            {children}
          </span>
        </div>
      </div>
    </>
  );
};

export default Card;

import React from 'react'
import Title from '../../../components/Title/title'
import { MdTouchApp } from 'react-icons/md'
import Map from '../Map/Map'
import './styles.scss'

const LocTel = () => {
    const cardsContatos = [
        'ATENDIMENTO GERAL E PROTOCOLO',
        'OUVIDORIA',
        'SECRETARIA',
        'TESOURARIA',
        'CARTÓRIO',
        'SECRETARIA DO TED',
        'ASSESSORIA JURÍDICA',
        'GERÊNCIA ADMINISTRATIVA',
        'ASCOM',
        'PRESIDÊNCIA'
    ]
    const dropDownText = [
        <p>(79)3301-9100</p>,
        <p>
            (79)3301-9115
            <br />
            <br />
            ouvidoria@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9102
            <br />
            (79)3301-9103
            <br />
            (79)3301-9104
            <br />
            (79)3301-9105
            <br />
            (79)3301-9106
            <br />
            <br />
            secretaria.geral@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9107
            <br />
            (79)3301-9108
            <br />
            (79)3301-9109
            <br />
            <br />
            tesouraria@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9113
            <br />
            <br />
            cartorio@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9111 <br /> <br />
            ted@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9117 <br /> <br />
            juridico@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9121 <br />
            (79)3301-9122
        </p>,
        <p>
            (79)3301-9119 <br /> <br />
            ascom@oabsergipe.org.br
        </p>,
        <p>
            (79)3301-9125
            <br />
            <br /> presidente@oabsergipe.org.br
        </p>
    ]

    const salaAdv = [
        {
            local: 'Justiça Federal Aracaju',
            tel: '(79)3216-2285'
        },
        {
            local: '18 do Forte',
            tel: '(79)3234-5466'
        },
        {
            local: 'Gumersindo Bessa',
            tel: '(79)3226-3513'
        },
        {
            local: 'Fóruns Integrados III',
            tel: '(79)3234-5512'
        },
        {
            local: 'Estância',
            tel: '(79)3522-2297'
        },
        {
            local: 'São Cristóvão',
            tel: `(79)3261-9400\n(79)3261-9417`
        },
        {
            local: 'Tribunal de Justiça – Centro',
            tel: '(79)3226-3896'
        }
    ]

    const mapStyles = {
        width: '100%',
        height: '628px',
        position: 'relative'
    }

    const container = {
        width: '100%',
        height: '628px',
        position: 'relative'
    }
    const showText = ind => {
        document.getElementById(`drop${ind}`).classList.toggle('showDropText')
    }

    return (
        <div id="loctel">
            <div className="section-1 ">
                <Title
                    textContent="LOCALIZAÇÃO E TELEFONES"
                    customClass="title-page"
                    link1="A OAB/SE >"
                    link2="&nbsp;FALE CONOSCO >"
                    link3="&nbsp;LOCALIZAÇÃO E TELEFONES"
                    rota1="/"
                    customClassBackground="background-red-horizontal"
                    title
                />
            </div>
            <div className="section-2">
                <section className="contatos">
                    <section className="presentationContatos">
                        <p className="title">Contatos</p>
                        <div className="blueRecContatos" />
                        <div className="hand">
                            <MdTouchApp />
                        </div>
                        <p className="descriptionPresentationContatos">
                            Toque nas caixas para ativa-las ou desativa-las
                        </p>
                    </section>
                    <section className="cardsContatos">
                        {cardsContatos.map((card, index) => (
                            <article
                                className="tileSetor"
                                key={index}
                                onClick={() => showText(index)}
                            >
                                <div className="smallBlueRecContato"></div>
                                <p>{card}</p>
                                <div
                                    className="dropDownInfo"
                                    id={`drop${index}`}
                                >
                                    <p className="dropDownText">
                                        {dropDownText[index]}
                                    </p>
                                </div>
                            </article>
                        ))}
                    </section>
                    <section className="infoLoc">
                        <div className="regionais">
                            <p>Regionais</p>
                            <div className="regionaisFlex">
                                <div>
                                    <span>Regional Lagarto</span>
                                    <span>(79)3631-4769</span>
                                </div>
                                <div>
                                    <span>Regional Itabaiana</span>
                                    <span>(79)3431-4114</span>
                                </div>
                            </div>
                        </div>
                        <div className="stroke" />
                        <div className="salaAdvogados">
                            <p>Salas dos Advogados</p>
                            <div className="salaAdvogadosFlex">
                                {salaAdv.map((item, index) => (
                                    <div key={index}>
                                        <span>{item.local}</span>
                                        <span>{item.tel}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </section>
                <section className="mapa">
                    <div>
                        <p className="mapaTitle">Encontre-nos no Google Maps</p>
                    </div>
                    <div>
                        <p className="mapaText">
                            Você pode nos encontrar no Google Maps digitando
                            nosso endereço ou apenas "OAB-Sergipe" no campo de
                            rotas.
                        </p>
                    </div>
                    <div className="mapaMap">
                        <Map />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default LocTel

import axios from 'axios';

export const api = axios.create({
    //baseURL: 'https://oab-hg.getinfo.aju.br/wp-json/wp/v2/',//adcionar rota da api
    baseURL: 'https://oabsergipe.org.br/wp-json/wp/v2', //rota da api
});

export const getApi = axios.create({
    baseURL: 'https://oab-hg.getinfo.aju.br/wp-json/wp/v2/',
})

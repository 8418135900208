/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import Title from '../../../components/Title/title'
import Select from './Select/Select'

import {
    Container,
    Content,
    Article,
    ContentCards,
    Card,
    SelectIndicator,
    SelectedItem,
    SelectedItemTitle
} from './Transparencia.elements.js';
import "./styles.scss";

import arrow from "../../../assets/images/Institucional/Resolucoes/Arrow.svg"
import arrowMobile from "../../../assets/images/Institucional/Resolucoes/Arrow-mobile.svg"

const DATA = [
    {
        nome: "DEMONSTRAÇÕES CONTÁBEIS",
    },
    {
        nome: "CONTRATOS",
    },
    {
        nome: "ORÇAMENTO",
    },
    {
        nome: "ADMINISTRATIVO",
    }
]

const anos = [
    {
        value: "",
        option: "Selecione"
    },
    {
        value: "2018",
        option: "2018"
    },
    {
        value: "2019",
        option: "2019"
    },
    {
        value: "2020",
        option: "2020"
    },
    {
        value: "2021",
        option: "2021"
    }
]

const trimestres = [
    {
        value: "",
        option: "Selecione"
    },
    {
        value: "1°",
        option: "1°"
    },
    {
        value: "2°",
        option: "2°"
    },
    {
        value: "3°",
        option: "3°"
    },
    {
        value: "4°",
        option: "4°"
    },
    {
        value: "Anual",
        option: "Anual"
    }
]

const balancetes = [
    {
        year: "2018",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-1%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-2%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-3%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-4%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-anual-2018.pdf"
    },
    {
        year: "2019",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-1%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-2%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-3%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-4%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-anual-2019.pdf"
    },
    {
        year: "2020",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-1%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-2%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/Balancete-3%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/Balancete-4%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/Balancete-2020.pdf"
    },
    {
        year: "2021",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/Balancete-1%C2%B0-trim-2021.pdf"
    },
    {
        year: "2021",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/Balancete-2%C2%B0-trim-2021.pdf"
    },
]

const BalancosFinanceiros = [
    {
        year: "2018",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-1%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-2%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-3%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-4%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-anual-2018.pdf"
    },
    {
        year: "2019",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-1%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-2%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-3%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-4%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-anual-2019.pdf"
    },
    {
        year: "2020",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-1%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoFinanceiro-2%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoFinanceiro-3%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoFinanceiro-4%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoFinanceiro-2020.pdf"
    },
    {
        year: "2021",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoFinanceiro-1%C2%BA-trim-2021.pdf"
    },
    {
        year: "2021",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoFinanceiro-2%C2%BA-trim-2021.pdf"
    }
]

const BalancosPatrimoniais = [
    {
        year: "2018",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-1%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-2%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-3%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-4%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-anual-2018.pdf"
    },
    {
        year: "2019",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-1%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-2%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-3%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-4%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-anual-2019.pdf"
    },
    {
        year: "2020",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-1%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/BalancoPatrimonial-2%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoPatrimonial-3%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoPatrimonial-4%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoPatrimonial-2020.pdf"
    },
    {
        year: "2021",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoPatrimonial-1%C2%B0-trim-2021.pdf"
    },
    {
        year: "2021",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/BalancoPatrimonial-2%C2%B0-trim-2021.pdf"
    },

]

const VariacoesPatrimoniais = [
    {
        year: "2018",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-1%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-2%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-3%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-4%C2%B0-trim-2018.pdf"
    },
    {
        year: "2018",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-anual-2018.pdf"
    },
    {
        year: "2019",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-1%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-2%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-3%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-4%C2%B0-trim-2019.pdf"
    },
    {
        year: "2019",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-anual-2019.pdf"
    },
    {
        year: "2020",
        trimestre: "1°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-1%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/02/VariacoesPatrimoniais-2%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "3°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/VariacoesPatrimoniais-3%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "4°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/VariacoesPatrimoniais-4%C2%B0-trim-2020.pdf"
    },
    {
        year: "2020",
        trimestre: "Anual",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/VariacoesPatrimoniais-2020.pdf"
    },
    {
        year: "2021",
        trimestre: "1º",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/VariacoesPatrimoniais-1%C2%B0-trim-2021.pdf"
    },
    {
        year: "2021",
        trimestre: "2°",
        arquivo: "https://oabsergipe.org.br/wp-content/uploads/2021/09/VariacoesPatrimoniais-2%C2%B0-trim-2021.pdf"
    },
]


export default function Resolucoes(){
    const [selected, setSelected] = useState();
    const [isSelected, setIsSelected] = useState();

    //estado dos selects
	const [disabledBalancetes, setDisabledBalancetes] = useState(true);
	const [selectedYearBalancetes, setSelectedYearBalancetes] = useState('');
	const [selectedMonthBalancetes, setSelectedMonthBalancetes] = useState('');
	const [pdfBalancetes, setPdfBalancetes] = useState('');
	
	const [disabledFinanceiro, setDisabledFinanceiro] = useState(true);
	const [selectedYearFinanceiro, setSelectedYearFinanceiro] = useState('');
	const [selectedMonthFinanceiro, setSelectedMonthFinanceiro] = useState('');
	const [pdfFinanceiro, setPdfFinanceiro] = useState('');
	
	const [disabledPatrimonial, setDisabledPatrimonial] = useState(true);
	const [selectedYearPatrimonial, setSelectedYearPatrimonial] = useState('');
	const [selectedMonthPatrimonial, setSelectedMonthPatrimonial] = useState('');
	const [pdfPatrimonial, setPdfPatrimonial] = useState('');
	
	const [disabledVariacoes, setDisabledVariacoes] = useState(true);
	const [selectedYearVariacoes, setSelectedYearVariacoes] = useState('');
	const [selectedMonthVariacoes, setSelectedMonthVariacoes] = useState('');
	const [pdfVariacoes, setPdfVariacoes] = useState('');
	
    useEffect(() => {
        document.getElementsByClassName('selected-item')?.item(0)?.classList?.add('slide-out-transparencia')
        setTimeout(function() {
            document.getElementsByClassName('selected-item')?.item(0)?.classList?.remove('slide-out-transparencia')
        }, 600)
    },[selected])

    function setItemSelected({item, index}){
        setSelected(item)
        setIsSelected(index)
    }

	//habilitar select do Trimestre do balancete
	useEffect(() => {
		if(selectedYearBalancetes !== ""){
			setDisabledBalancetes(false)
		}else{
			setDisabledBalancetes(true)
			setSelectedMonthBalancetes("")
		}
	}, [selectedYearBalancetes])

	useEffect(() => {
		let pdf = balancetes?.find(item => (
			item.year=== selectedYearBalancetes && item.trimestre === selectedMonthBalancetes
		))
        console.log(pdf)
        console.log(selectedMonthBalancetes)
        console.log(selectedYearBalancetes)
        console.log(pdf?.arquivo)
		setPdfBalancetes(pdf?.arquivo)
	}, [selectedMonthBalancetes, balancetes ,selectedYearBalancetes])

	//habilitar select do Financeiro
	useEffect(() => {
		if(selectedYearFinanceiro !== ""){
			setDisabledFinanceiro(false)
		}else{
			setDisabledFinanceiro(true)
			setSelectedMonthFinanceiro("")
		}
	}, [selectedYearFinanceiro])

	useEffect(() => {
		let pdf = BalancosFinanceiros?.find(item => (
			item.year=== selectedYearFinanceiro && item.trimestre === selectedMonthFinanceiro
		))
		setPdfFinanceiro(pdf?.arquivo)
	}, [selectedMonthFinanceiro, BalancosFinanceiros,selectedYearFinanceiro])


	//habilitar select do Trimestre de Patrimonial a pagar
	useEffect(() => {
		if(selectedYearPatrimonial !== ""){
			setDisabledPatrimonial(false)
		}else{
			setDisabledPatrimonial(true)
			setSelectedMonthPatrimonial("")
		}
	}, [selectedYearPatrimonial])

	useEffect(() => {
		let pdf = BalancosPatrimoniais?.find(item => (
			item.year=== selectedYearPatrimonial && item.trimestre === selectedMonthPatrimonial
		))
		setPdfPatrimonial(pdf?.arquivo)
	}, [selectedMonthPatrimonial, BalancosPatrimoniais, selectedYearPatrimonial])

	//habilitar select do Trimestre de Variacoes Cronológica
	useEffect(() => {
		if(selectedYearVariacoes !== ""){
			setDisabledVariacoes(false)
		}else{
			setDisabledVariacoes(true)
			setSelectedMonthVariacoes("")
		}
	}, [selectedYearVariacoes])

    useEffect(() => {
		let pdf = VariacoesPatrimoniais?.find(item => (
			item.year=== selectedYearVariacoes && item.trimestre === selectedMonthVariacoes
		))
		setPdfVariacoes(pdf?.arquivo)
	}, [selectedMonthVariacoes, VariacoesPatrimoniais,selectedYearVariacoes])
    return(
        <Container>
            <Title 
            textContent="PORTAL DA TRANSPARÊNCIA" 
            customClass="title-page" 
            link1="A OAB/SE >"
            link2="&nbsp;TESOURARIA >"
            link3="&nbsp;PORTAL DA TRANSPARÊNCIA"
            rota1="/"
            customClassBackground={"background-red-horizontal"}
            title
            />
            <Content>
                <Article>
                    { selected === undefined ?
                        <SelectIndicator>
                            Selecione uma opção
                            <img src={arrow} className="arrow" alt="seta apontando em direção a direta onde estão os cards"/>
                            <img src={arrowMobile} className="arrow-mobile" alt="seta apontando para baixo onde estão os cards"/>
                        </SelectIndicator>
                      : selected?.nome === "DEMONSTRAÇÕES CONTÁBEIS" ?
                        <SelectedItem className="selected-item">
                            <SelectedItemTitle>{selected?.nome}</SelectedItemTitle>
                            <div id="contentReceitas">
                                <h3 className="select__categoria">Balancetes</h3>
                                <div className="container-selects">
                                    <Select
                                        label="Ano"
                                        onChange={(event) => setSelectedYearBalancetes(event.target.value)}
                                        options={anos}
                                    />
                                    <Select
                                        label="Trimestre"
                                        disabled={disabledBalancetes}
                                        onChange={(event) => setSelectedMonthBalancetes(event.target.value)}
                                        options={trimestres}
                                    >
                                        <div className="baixar-arquivos select"						
                                        style={{display: selectedMonthBalancetes === "" ? 'none': 'flex'}}>
                                            <a href={pdfBalancetes} target="blank" className="tema">Visualizar</a>
                                        </div>
                                    </Select>
                                </div>

                                <h3 className="select__categoria">Balanço Financeiro</h3>
                                <div className="container-selects">
                                    <Select
                                        label="Ano"
                                        onChange={(event) => setSelectedYearFinanceiro(event.target.value)}
                                        options={anos}
                                    />
                                    <Select
                                        label="Trimestre"
                                        disabled={disabledFinanceiro}
                                        onChange={(event) => setSelectedMonthFinanceiro(event.target.value)}
                                        options={trimestres}
                                    >
                                        <div className="baixar-arquivos select"						
                                        style={{display: selectedMonthFinanceiro === "" ? 'none': 'flex'}}>
                                            <a href={pdfFinanceiro} target="blank" className="tema">Visualizar</a>
                                        </div>
                                    </Select>
                                </div>

                                <h3 className="select__categoria">Balanço Patrimonial</h3>
                                <div className="container-selects">
                                    <Select
                                        label="Ano"
                                        style={{opacity: selectedYearPatrimonial === "" ? 0.8 : 1}}
                                        onChange={(event) => setSelectedYearPatrimonial(event.target.value)}
                                        options={anos}
                                    />
                                    <Select
                                        label="Trimestre"
                                        disabled={disabledPatrimonial}
                                        style={{opacity: selectedYearPatrimonial === "" ? 0.5 : selectedMonthPatrimonial === "" ? 0.8 : 1}}
                                        onChange={(event) => setSelectedMonthPatrimonial(event.target.value)}
                                        options={trimestres}
                                    >
                                        <div className="baixar-arquivos select"						
                                        style={{display: selectedMonthPatrimonial === "" ? 'none': 'flex'}}>
                                            <a href={pdfPatrimonial} target="blank" className="tema">Visualizar</a>
                                        </div>
                                    </Select>
                                </div>

                                <h3 className="select__categoria">Variações Patrimoniais</h3>
                                <div className="container-selects">
                                    <Select
                                        label="Ano"
                                        style={{opacity: selectedYearVariacoes === "" ? 0.8 : 1}}
                                        onChange={(event) => setSelectedYearVariacoes(event.target.value)}
                                        options={anos}
                                    />
                                    <Select
                                        label="Trimestre"
                                        disabled={disabledVariacoes}
                                        style={{opacity: selectedYearVariacoes === "" ? 0.5 : selectedMonthVariacoes === "" ? 0.8 : 1}}
                                        onChange={(event) => setSelectedMonthVariacoes(event.target.value)}
                                        options={trimestres}
                                    >
                                        <div className="baixar-arquivos select"						
                                        style={{display: selectedMonthVariacoes === "" ? 'none': 'flex'}}>
                                            <a href={pdfVariacoes} target="blank" className="tema">Visualizar</a>
                                        </div>
                                    </Select>
                                </div>
                            </div>
                        </SelectedItem>
                    : selected?.nome === "CONTRATOS" ?
                    <SelectedItem style={{alignItems: "flex-start", padding: "2rem"}}>
                        <SelectedItemTitle>
                            2020
                        </SelectedItemTitle>
                    </SelectedItem>
                    : selected?.nome === "ORÇAMENTO" ?
                    <SelectedItem style={{alignItems: "flex-start", padding: "2rem"}}>
                        <SelectedItemTitle>
                            Proposta Orçamentária
                        </SelectedItemTitle>
                        <h3 className="select__categoria">2020</h3>
                    </SelectedItem>
                    : //Administrativo
                    <SelectedItem style={{alignItems: "flex-start", padding: "2rem"}}>
                        <SelectedItemTitle>
                            Quadro de Advogados
                        </SelectedItemTitle>
                    </SelectedItem>
                    }
                </Article>
                <ContentCards>
                    {DATA.map((item, index) => (
                        <Card key={index} onClick={() => setItemSelected({item,index})} selected={isSelected === index ? true : false}>
                            {item.nome}
                        </Card>
                    ))}
                </ContentCards>
            </Content>
        </Container>
    )
}
import styled, { css } from 'styled-components'

export const StyledImage = styled.img`
    height: 400px;
`

export const StyledTypography = styled.p`
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
`

export const StyledBottomPattern = styled.span`
    border-bottom: 3px solid #fff;
    padding-bottom: 5px;

    ${props =>
        props.regular &&
        css`
            border-bottom: none;
            padding-bottom: none;
        `}
    ${props =>
        props.black &&
        css`
            color: black;
            border-bottom: 3px solid #000;
            padding-bottom: 5px;
        `}
`

export const HideMobile = styled.div`
    display: block;

    @media (max-width: 1000px) {
        display: none;
    }
`

export const ShowMobile = styled.div`
    display: none;

    @media (max-width: 1000px) {
        display: block;
    }
`

export const StyledBox = styled.div`
    background-color: #aa4f50;
    position: relative;
    margin: auto;
    top: -5%;
    width: 40%;
    height: 45px;
`

import React, { useEffect, useState } from "react";
import Title from "../../../components/Title/title";
import "./styles.scss";

import squareLeft from '../../../assets/images/Institucional/TribunalEtica/square-left.svg';
import squareRight from '../../../assets/images/Institucional/TribunalEtica/square-right.svg';

window.localStorage.setItem("aberto", 0);
window.localStorage.setItem("abrir", 0);

const TribunalEtica = () => {

  const [flags, setFlags] = useState(true);

  useEffect(() => {
    const listCards = document.getElementsByClassName("card-container");
    const Cards = [].slice.call(listCards);
    const ContainerCard = document.getElementsByClassName("container-card");
    const Card = document.getElementsByClassName("card");

    const Headers = document.querySelectorAll('a[href="#cards"]');

    ContainerCard[0].classList.add("show");
    Card[0].classList.add("scale");
    listCards[0].classList.add("margin");

    const listCollapse = document.getElementsByClassName("collapse-card");
    const Collapses = [].slice.call(listCollapse);
    const collapse = document.getElementsByClassName("collapse-container-card");

    function showCard(index) {

      ContainerCard[index].classList.add("show");
      Card[index].classList.add("scale");
      listCards[index].classList.add("margin");
      window.localStorage.setItem("aberto", index);
    }

    function hideCard(index) {
      ContainerCard[index].classList.remove("show");
      Card[index].classList.remove("scale");
      listCards[index].classList.remove("margin");
    }

    Cards.map((item, index) => {
      item.addEventListener("mouseover", () => {
        let aberto = window.localStorage.getItem("aberto");
        if (aberto !== index) {
          hideCard(aberto);
          window.localStorage.setItem("abrir", index);
        }
        showCard(index);
      });

      return 0;
    });

    Headers.forEach((item, index) => {
      item.addEventListener("click", () => {

        window.localStorage.setItem("aberto", 0);
        window.localStorage.setItem("abrir", index);
      });
    })

    let aberto = window.localStorage.getItem("aberto");
    let abrir = window.localStorage.getItem("abrir");
    hideCard(aberto);
    showCard(abrir);

    Collapses.map((item, index) => {
      item.addEventListener("mouseover", () => {
        collapse[index].classList.add("show-collapse");
      });
      item.addEventListener("mouseout", () => {
        collapse[index].classList.remove("show-collapse");
      });
      return 0;
    });
  });

  const showFlags = () => {
    if (window.innerWidth <= 768) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  });

  window.addEventListener("resize", showFlags);

  const Table = () => {
    return (
      <>
        <table>
          <thead>
            <tr>
              <th>MÊS</th>
              <th><a className="link-em-bg-branco" href="#cards">1ª Turma de Julgamento</a></th>
              <th><a className="link-em-bg-branco" href="#cards">2ª Turma de Julgamento</a></th>
              <th><a className="link-em-bg-branco" href="#cards">3ª Turma de Julgamento</a></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Agosto</td>
              <td>18 e 25</td>
              <td>17 e 24</td>
              <td>19 e 26</td>
            </tr>
            <tr>
              <td>Setembro</td>
              <td>22 e 29</td>
              <td>14 e 28</td>
              <td>16 e 30</td>
            </tr>
            <tr>
              <td>Outubro</td>
              <td>13 e 27</td>
              <td>19 e 26</td>
              <td>14 e 28</td>
            </tr>
            <tr>
              <td>Novembro</td>
              <td>10 e 24</td>
              <td>09 e 23</td>
              <td>18 e 25</td>
            </tr>
            <tr>
              <td>Dezembro</td>
              <td>01 e 15</td>
              <td>07 e 14</td>
              <td>09 e 16</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }


  const Cards = () => {
    return (
      <div className="cards" id="cards">
        <div className={"card-container"}>
          <div className="card">
            <h5>1ª TURMA <br /> (SESSÕES ÀS QUARTAS-FEIRAS)</h5>
          </div>
          <div className="container-card">
            <h4>Presidente</h4>
            <p>Dr. Carlos Henrique Magalhães de Melo Filho</p>
            <h4>Composição</h4>
            <p>Drª. Claudia Regina Machado Aguiar Amorim</p>
            <p>Dr. Fillipe Oliveira Correia</p>
            <p>Dr. Marcelo Doria de Araujo</p>
            <p>Dr. Claudio Rodrigues Abranches</p>
            <p>Dr. Lucas Borges Silva</p>
          </div>
        </div>
        <div className="card-container">
          <div className="card">
            <h5>2ª TURMA <br /> (SESSÕES ÀS TERÇAS-FEIRAS)</h5>
          </div>
          <div className="container-card">
            <h4>Presidente</h4>
            <p>Drª. Carmen Margarida Moreno Jacintho</p>
            <h4>Composição</h4>
            <p>Drª. Ana Rachel de Melo Arimatea Rosa</p>
            <p>Dr. Jademir de Andrade Camara da Fonseca</p>
            <p>Dr. Alisson Fontes de Aragão</p>
            <p>Dr. Anderson de Albuquerque Fernandes</p>
            <p>Drª. Claudia Maria Costa Dantas</p>
            <p>Dr. Marcelo Victor Andrade Melo</p>
          </div>
        </div>
        <div className="card-container">
          <div className="card">
            <h5>3ª TURMA <br /> (SESSÕES ÀS QUINTAS-FEIRAS)</h5>
          </div>
          <div className="container-card">
            <h4>Presidente</h4>
            <p>Dr. Josadach Alves de Albuquerque Júnior</p>
            <h4>Composição</h4>
            <p>Dr. Leandro dos Santos Camara</p>
            <p>Dr. Edson José Sá Junior</p>
            <p>Drª. Kely Regina Maria de Souza</p>
            <p>Dr. Josan Santos Souza</p>
            <p>Dr. Wesley Santana Santos</p>
            <p>Dr. Mauricio José dos Santos Bezerra</p>
          </div>
        </div>
      </div>
    );
  }
  const CollapseCard = () => {
    return (
      <div className="Meses">

        <div className="collapse-card">
          <h4>Agosto</h4>
        </div>
        <div className="collapse-container-card">
          <h6>1ª Turma de Julgamento</h6>
          <p>18 e 25</p>
          <h6>2ª Turma de Julgamento</h6>
          <p>17 e 24</p>
          <h6>3ª Turma de Julgamento</h6>
          <p>19 e 26</p>
        </div>
        <div className="collapse-card">
          <h4>Setembro</h4>
        </div>
        <div className="collapse-container-card">
          <h6>1ª Turma de Julgamento</h6>
          <p>22 e 29</p>
          <h6>2ª Turma de Julgamento</h6>
          <p>14 e 28</p>
          <h6>3ª Turma de Julgamento</h6>
          <p>16 e 30</p>
        </div>
        <div className="collapse-card">
          <h4>Outubro</h4>
        </div>
        <div className="collapse-container-card">
          <h6>1ª Turma de Julgamento</h6>
          <p>13 e 27</p>
          <h6>2ª Turma de Julgamento</h6>
          <p>19 e 26</p>
          <h6>3ª Turma de Julgamento</h6>
          <p>14 e 28</p>
        </div>
        <div className="collapse-card">
          <h4>Novembro</h4>
        </div>
        <div className="collapse-container-card">
          <h6>1ª Turma de Julgamento</h6>
          <p>10 e 24</p>
          <h6>2ª Turma de Julgamento</h6>
          <p>09 e 23</p>
          <h6>3ª Turma de Julgamento</h6>
          <p>18 e 25</p>
        </div>
        <div className="collapse-card">
          <h4>Dezembro</h4>
        </div>
        <div className="collapse-container-card">
          <h6>1ª Turma de Julgamento</h6>
          <p>01 e 15</p>
          <h6>2ª Turma de Julgamento</h6>
          <p>07 e 14</p>
          <h6>3ª Turma de Julgamento</h6>
          <p>09 e 16</p>
        </div>
      </div>
    );
  }

  return (
    <div id="tribunal-etica">
      <section className="section-1">
        <Title
          textContent="TRIBUNAL DE ÉTICA"
          customClass="title-page"
          link1="A OAB/SE >"
          link2="&nbsp;INSTITUCIONAL >"
          link3="&nbsp;TRIBUNAL DE ÉTICA"
          rota1="/"
          customClassBackground={!flags ? "background-red-horizontal" : "background-red-vertical"}
          title
        />
      </section>
      <section className="section-2">
        <div className="container">
          {flags &&
            <div className="square-right">
              <img src={squareRight} alt="" />
            </div>
          }
          <div className="square-left">
            <img src={!flags ? squareRight : squareLeft} alt="" />
          </div>
          <div className="grid">
            <h4>Presidente</h4>
            <p>Dr. Leão Magno Brasil Junior</p>
            <h4>Vice-presidente</h4>
            <p>Dr. Carlos Henrique Magalhães de Melo Filho</p>
            <h4>Secretário</h4>
            <p>Drª. Carmen Margarida Moreno Jacintho</p>
          </div>
          <div className="membros">
            <h4>Membros</h4>
            <p>Dr. Fillipe Oliveira Correia</p>
            <p>Drª. Ana Rachel de Melo Arimatea Rosa</p>
            <p>Drª. Claudia Regina Machado Aguiar Amorim</p>
            <p>Dr. Marcelo Dória de Araújo</p>
            <p>Dr. Jademir de Andrade Camara da Fonseca</p>
            <p>Dr. Anderson de Albuquerque Fernandes</p>
            <p>Dr. Claudio Rodrigues Abranches</p>
            <p>Dr. Wesley Santana Santos</p>
            <p>Dr. Alisson Fontes de Aragão</p>
            <p>Dr. Maurício José dos Santos Bezerra</p>
            <p>Dr. Marcelo Victor Andrade Melo</p>
            <p>Dr. Lucas Borges Silva</p>
            <p>Dr. Thiago Ramos Matos Oliveira</p>
            <p>Dr. Edson José Sá Junior</p>
            <p>Dr. Josan Santos Souza</p>
            <p>Drª. Kely Regina Maria de Souza</p>
            <p>Dr.ª Claudia Maria Costa do Nascimento</p>
          </div>
          <div className="container-trienio">
            {!flags ? <h3>TRIÊNIO  2019/2021</h3> : <h3>TRIÊNIO <br /> 2019/2021</h3>}
          </div>
        </div>
      </section>
      <section className="section-3" />
      <section className="section-6">
        <Cards />
      </section>
      <section className="section-4">
        <h3>CALENDÁRIO DAS SESSÕES DE JULGAMENTO DO ANO DE 2021</h3>
        {!flags ? <CollapseCard /> : <Table />}
      </section>
      <section className="section-5">
        <div className="obs">
          <h5>OBSERVAÇÕES:</h5>
          <ul>
            <li>• As sessões iniciarão às 15h de forma virtual ou na sala do TED.</li>
            <li>• Os processos que não forem julgados na referida sessão serão automaticamente incluídos na pauta da sessão subsequente, independente de nova publicação.</li>
          </ul>
        </div>
      </section>

    </div>
  );
}

export default TribunalEtica;
import React from 'react';
import { Carrossel } from '../../../../components';

import inacio from '../../../../assets/images/CarrosselSobre/Inácio Krauss.jpg';
import { exPresidentes } from "../diretoria";

import './styles.scss';

const Presidente = ({ aberto, className }) => {

  return (
    <div id="section-presidente">
      <section className='section-1'>
        <div className='container'>
          <ul>
            <li>
              Inácio José Krauss de Menezes, inscrito na OAB/SE 2.872, é
              graduado em Direito pela Universidade Tiradentes.
            </li>
            <li>
              Vice-presidente da entidade máxima da advocacia em Sergipe no
              triênio 2016/2018 e presidente da Caixa de Assistência dos
              Advogados do Estado de 2011 a 2015, Inácio é bacharel em Direito,
              possui especialização em Direito e Processo Civil e atua como
              advogado há quase 20 anos.
            </li>
            <li>
              Entre outras funções exercidas no Conselho Federal da OAB, na
              OAB/SE e na CAA/SE, Inácio também foi conselheiro e diretor da
              Associação Sergipana dos Advogados Trabalhistas (ASSAT).
            </li>
          </ul>
          <div className='container-card'>
            <img src={inacio} alt='Presidente' />
            <div>
              <h3>INÁCIO JOSÉ KRAUSS DE MENEZES</h3>
            </div>
          </div>
        </div>
      </section>

      <section className='section-2'>
        <div className="title">
          <h1>EX-PRESIDENTES</h1>
          <h3>Conheça os mestres que passaram pela casa e contribuíram para que ela seja a referência que é hoje</h3>
        </div>
        <Carrossel aberto={aberto} diretores={exPresidentes} />
      </section>
    </div>
  );
}

export default Presidente;
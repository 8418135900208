import ana from "../../../assets/images/CarrosselSobre/Ana Lúcia Dantas.jpg";
import inacio from "../../../assets/images/CarrosselSobre/Inácio Krauss.jpg";
import andrea from "../../../assets/images/CarrosselSobre/Andrea Leite.jpg";
import aurelio from "../../../assets/images/CarrosselSobre/Aurélio Belém.jpg";
import david from "../../../assets/images/CarrosselSobre/David Dia.jpg";
import edison from "../../../assets/images/CarrosselSobre/exPresidentes/1.jpg";
import evangelino from "../../../assets/images/CarrosselSobre/exPresidentes/2.jpg";
import alfredo from "../../../assets/images/CarrosselSobre/exPresidentes/3.jpg";
import afonso from "../../../assets/images/CarrosselSobre/exPresidentes/4.jpg";
import goncalo from "../../../assets/images/CarrosselSobre/exPresidentes/5.jpg";
import heribaldo from "../../../assets/images/CarrosselSobre/exPresidentes/6.jpg";
import carlos from "../../../assets/images/CarrosselSobre/exPresidentes/7.jpg";
import alvaro from "../../../assets/images/CarrosselSobre/exPresidentes/8.jpg";
import osman from "../../../assets/images/CarrosselSobre/exPresidentes/9.jpg";
import pedro from "../../../assets/images/CarrosselSobre/exPresidentes/10.jpg";
import baldino from "../../../assets/images/CarrosselSobre/exPresidentes/11.jpg";
import joaoMoreira from "../../../assets/images/CarrosselSobre/exPresidentes/12.jpg";
import luizCarlos from "../../../assets/images/CarrosselSobre/exPresidentes/13.jpg";
import manuel from "../../../assets/images/CarrosselSobre/exPresidentes/14.jpg";
import laurindo from "../../../assets/images/CarrosselSobre/exPresidentes/15.jpg";
import joseFrancisco from "../../../assets/images/CarrosselSobre/exPresidentes/16.jpg";
import adroaldo from "../../../assets/images/CarrosselSobre/exPresidentes/17.jpg";
import lisses from "../../../assets/images/CarrosselSobre/exPresidentes/18.jpg";
import osmario from "../../../assets/images/CarrosselSobre/exPresidentes/19.jpg";
import joseGilton from "../../../assets/images/CarrosselSobre/exPresidentes/20.jpg";
import osorio from "../../../assets/images/CarrosselSobre/exPresidentes/21.jpg";
import joseAugusto from "../../../assets/images/CarrosselSobre/exPresidentes/22.jpg";
import joseSilverio from "../../../assets/images/CarrosselSobre/exPresidentes/23.jpg";
import carlosAlberto from "../../../assets/images/CarrosselSobre/exPresidentes/24.jpg";
import clovis from "../../../assets/images/CarrosselSobre/exPresidentes/25.jpg";
import jorge from "../../../assets/images/CarrosselSobre/exPresidentes/26.jpg";
import edson from "../../../assets/images/CarrosselSobre/exPresidentes/27.jpg";
import raimundo from "../../../assets/images/CarrosselSobre/exPresidentes/28.jpg";
import manuelMenezes from "../../../assets/images/CarrosselSobre/exPresidentes/29.jpg";
import henri from "../../../assets/images/CarrosselSobre/exPresidentes/30.jpg";
import carlosAugusto from "../../../assets/images/CarrosselSobre/exPresidentes/31.jpg";


export const diretoriaAtual = [
    {
        nome: "Andrea Leite de Souza",
        cargo: "Secretária Geral Adjunta",
        foto: andrea,
    },
    {
        nome: "Ana Lúcia Dantas Souza Aguiar",
        cargo: "Vice-Presidente",
        foto: ana,
    },
    {
        nome: "Inácio José Krauss de Menezes",
        cargo: "Presidente",
        foto: inacio,
    },
    {
        nome: "Aurélio Belém do Espírito Santo ",
        cargo: "Secretária Geral",
        foto: aurelio,
    },
    {
        nome: "David Dias Garcez de Castro Dória",
        cargo: "Tesoureiro",
        foto: david,
    },
];

export const exPresidentes = [
    {
        nome: "Edson de Oliveira Ribeiro",
        cargo: "1935",
        foto: edison,
    },
    {
        nome: "Evangelino José de Faro",
        cargo: "1935-1936",
        foto: evangelino,
    },
    {
        nome: "Alfredo Rollemberg Leite",
        cargo: "1937-1938",
        foto: alfredo,
    },
    {
        nome: "Alfonso Ferreira Dos Santos",
        cargo: "1939",
        foto: afonso,
    },
    {
        nome: "Gonçalo Rollemberg Leite",
        cargo: "1940-1942",
        foto: goncalo,
    },
    {
        nome: "Heribaldo Dantas Vieira",
        cargo: "1943-1945",
        foto: heribaldo,
    },
    {
        nome: "Carlos Alberto Rôlla",
        cargo: "1946-1949",
        foto: carlos,
    },
    {
        nome: "Ávaro Fontes da Silva",
        cargo: "1950-1952 / 1952-1954 / 1954 - 1956",
        foto: alvaro,
    },
    {
        nome: "Osman Hora Fontes",
        cargo: "1956 - 1959",
        foto: osman,
    },
    {
        nome: "Pedro Barreto de Andrade",
        cargo: "1959-1961",
        foto: pedro,
    },
    {
        nome: "Baldino Ramalho",
        cargo: "1961-1962",
        foto: baldino,
    },
    {
        nome: "João Moreira Filho",
        cargo: "1962 - 1963",
        foto: joaoMoreira,
    },
    {
        nome: "Luiz Carlos Rollemberg Dantas",
        cargo: "1964 - 1965",
        foto: luizCarlos,
    },
    {
        nome: "Manuel Achilles Lima",
        cargo: "1966 - 1967",
        foto: manuel,
    },
    {
        nome: "Laurindo Alves Campos",
        cargo: "1967 - 1968",
        foto: laurindo,
    },
    {
        nome: "José Francisco da Rocha",
        cargo: "1968 - 1969",
        foto: joseFrancisco,
    },
    {
        nome: "Adroaldo Campos Filho",
        cargo: "1970",
        foto: adroaldo,
    },
    {
        nome: "Lisses Alves Campos",
        cargo: "1970 - 1971",
        foto: lisses,
    },
    {
        nome: "Osmário Vila Nova de Carvalho",
        cargo: "1971 - 1972 / 1973 - 1974",
        foto: osmario,
    },
    {
        nome: "José Gilton Pinto Garcia",
        cargo: "1975 - 1976 / 1977 - 1978",
        foto: joseGilton,
    },
    {
        nome: "Osório de Araújo Ramos",
        cargo: "1979 - 1981",
        foto: osorio,
    },
    {
        nome: "José Augusto de Azeredo Lobão",
        cargo: "1981 - 1983",
        foto: joseAugusto,
    },
    {
        nome: "José Silvério Leite Fontes",
        cargo: "1983 - 1984 / 1987 - 1988",
        foto: joseSilverio,
    },
    {
        nome: "Carlos Alberto Menezes",
        cargo: "1985 - 1986",
        foto: carlosAlberto,
    },
    {
        nome: "Clóvis Barbosa de Melo",
        cargo: "1989 - 1990 / 1993 - 1994",
        foto: clovis,
    },
    {
        nome: "Jorge Aurélio Silva",
        cargo: "1991 - 1993",
        foto: jorge,
    },
    {
        nome: "Edson Ulisses de Melo",
        cargo: "1994 - 1995 / 1995 - 1997",
        foto: edson,
    },
    {
        nome: "Raimundo César Britto Aragão",
        cargo: "1998 - 2000",
        foto: raimundo,
    },
    {
        nome: "Manuel Meneses Cruz",
        cargo: "2001 - 2003",
        foto: manuelMenezes,
    },
    {
        nome: "Henri Clay Santos Andrade",
        cargo: "2004 - 2006 / 2007 - 2009",
        foto: henri,
    },
    {
        nome: "Carlos Augusto Monteiro Nascimento",
        cargo: "2010 - 2012 / 2013 - 2015",
        foto: carlosAugusto,
    },
]
import React, { useState, useEffect } from 'react';

import Title from '../../../components/Title/title'

import {
    Container,
    Content,
    TextContent,
    Article,
    Download,
    ContentCards,
    Card,
    SelectIndicator,
    SelectedItem
} from './Entrega.elements.js'
import "./styles.scss";

import arrow from "../../../assets/images/Institucional/Resolucoes/Arrow.svg"
import arrowMobile from "../../../assets/images/Institucional/Resolucoes/Arrow-mobile.svg"

const DATA = [
    {
        nome: "RESULTADO PRELIMINAR DO XXXI EXAME",
        text: "à Definir"
    },
    {
        nome: "SOLICITAÇÃO DE INSCRIÇÃO ANTES DO RECURSO",
        text: "12/01/2021 a 22/01/2021"
    },
    {
        nome: "1° PUBLICAÇÃO DO EDITAL",
        text: "à Definir"
    },
    {
        nome: "RESULTADO DEFINITIVO DO XXXI EXAME",
        text: "à Definir"
    },
    {
        nome: "SOLICITAÇÃO DE INSCRIÇÃO PARA APROVADOS APÓS RECURSO",
        text: "27/01/2021 a 05/02/2021"
    },
    {
        nome: "PUBLICAÇÃO DO EDITAL",
        text: "25/01/2021"
    },
    {
        nome: "ASSINATURA DE DOCUMENTOS",
        text: "À Definir"
    },
    {
        nome: "REUNIÃO COM BACHARÉIS",
        text: "23 de fevereiro de 2021 – Início às 17h30min – Através da Plataforma ZOOM (online)"
    },
    {
        nome: "CURSOS COM OS NOVOS ADVOGADOS",
        text: " 23 de fevereiro de 2021 – Início do curso às 18h – Através da Plataforma ZOOM (online) \n \n 24 e 25 de fevereiro de 2021 – Continuação do curso  às 18h – Através da Plataforma ZOOM (online)"
    },
    {
        nome: "SOLENIDADE DE COMPROMISSOS DOS NOVOS ADVOGADOS",
        text: "25 de fevereiro de 2021 – às 09h – na Sede de Itabaiana/SE \n \n 02, 03 e 04 de março de 2021 – às 9h e 17h – Na Sede de Aracaju – no Plenário da OAB/SE (turmas formadas por ordem de pedido de inscrição)."
    },
]
export default function Resolucoes(){
    const [selected, setSelected] = useState();
    const [isSelected, setIsSelected] = useState();

    useEffect(() => {
        document.getElementsByClassName('selected-item')?.item(0)?.classList?.add('slide-out')
        setTimeout(function() {
            document.getElementsByClassName('selected-item')?.item(0)?.classList?.remove('slide-out')
        }, 600)
    },[selected])

    function setItemSelected({item, index}){
        setSelected(item)
        setIsSelected(index)
    }

    return(
        <Container>
            <Title 
            textContent="CRONOGRAMA DE ENTREGA DE CARTEIRAS" 
            customClass="title-page" 
            link1="A OAB/SE >"
            link2="&nbsp;SECRETARIA >"
            link3="&nbsp;CRONOGRAMA DE ENTREGA DE CARTEIRAS"
            rota1="/"
            customClassBackground={"background-red-horizontal"}
            title
            />
            <Content>
                <TextContent>
                O pré-cadastro online, realizado previamente a entrega de documentos, é obrigatório.
                Requerimentos de inscrição com pendências ou fora dos prazos aqui divulgados, não serão incluídos na Solenidade do XXXI EXAME DE ORDEM.
                </TextContent>
                <Article>
                    { selected === undefined ?
                        <SelectIndicator>
                            Selecione uma opção
                            <img src={arrow} className="arrow" alt="seta apontando em direção a direta onde estão os cards"/>
                            <img src={arrowMobile} className="arrow-mobile" alt="seta apontando para baixo onde estão os cards"/>
                        </SelectIndicator>
                      :
                        <SelectedItem className="selected-item">
                            <Download>{selected?.text}</Download>
                        </SelectedItem>
                    }
                </Article>
                <ContentCards>
                    {DATA.map((item, index) => (
                        <Card key={index} onClick={() => setItemSelected({item,index})} selected={isSelected === index ? true : false}>
                            {item.nome}
                        </Card>
                    ))}
                </ContentCards>
            </Content>
        </Container>
    )
}
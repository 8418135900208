import React from 'react';
import { Title } from '../../../components';
import Carousel from 'react-elastic-carousel';

import bolinhas2 from '../../../assets/images/Alvaras/bolinhas2.svg';

import './styles.scss'

const Artigos = () => {
  return(
    <div id="artigos">
      <section className="section-1">
        <Title 
        textContent='artigos'
        customClass='title-page'
        link1='A OAB/SE >'
        link2='&nbsp;publicações >'
        link3='&nbsp;artigos'
        rota1='/'
        customClassBackground='background-red-horizontal'
        title
        />
      </section>
      <section className="section-2">
        <img className="details" src={bolinhas2} alt="" />
        <div className="carousel">
          <Carousel
          showArrows={true}
          showStatus={false}
          >
            <div className="container">
            <span className="title retangle">A humanidade contra a Pandemia</span>
            <p>A população mundial está mergulhada em um novo tipo de guerra. 
              Pouco mais de um mês após ser decretada a Pandemia do COVID-19, 
              temos serce de 2 milhoes de casos e mais de 120 mil mortes humanas 
              em todo planeta [...]
            </p>
            <div className="escrito">
              <span className="title subtitle">Escrito por: <br /> 
              <p>Flaviano C. Cardoso</p> 
              </span>
              <button className="button" type="button">
                <a href="https://oabsergipe.org.br/wp-content/uploads/2020/04/A-humanidade-contra-a-Pandemia.pdf" target="_blank" rel="noreferrer">Baixar</a>
              </button>
            </div>
            </div>
{/* 
            <div className="container">
            <span className="title retangle">A humanidade contra a Pandemia</span>
            <p>A população mundial está mergulhada em um novo tipo de guerra. 
              Pouco mais de um mês após ser decretada a Pandemia do COVID-19, 
              temos serce de 2 milhoes de casos e mais de 120 mil mortes humanas 
              em todo planeta [...]
            </p>
            <div className="escrito">
              <span className="title subtitle">Escrito por: <br /> 
              <p>Flaviano C. Cardoso</p> 
              </span>
              <button className="button" type="button">
                <a href={artigoPDF} target="_blank" rel="noreferrer">Baixar</a>
              </button>
            </div>
            </div> */}

          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default Artigos;
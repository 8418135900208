import React, { useEffect, useState } from 'react';

import './styles.scss';

import data from './data';

const Conselheiro = ({ className }) => {
  const [flags, setFlags] = useState(true);

  const showFlags = () => {
    if (window.innerWidth <= 550) {
      setFlags(false);
    } else {
      setFlags(true);
    }
  };

  useEffect(() => {
    showFlags();
  });

  window.addEventListener('resize', showFlags);

  return (
    <div id='section-conselheiro'>
      <section className='section-1'>
        <div className='container'>
          {data.map(Tipos => {
            if (Tipos.tipo === 1) {
              return (
                <div className='titulares-suplentes'>
                  <div className='titulares'>
                    <ul>
                      conselheiros titulares
                      {Tipos.titulares.map((item) => {
                        return <li key={item.id}>{item.name}</li>;
                      })}
                    </ul>
                  </div>
                  <div className='suplentes'>
                    <ul>
                      conselheiros suplentes
                      {Tipos.suplentes.map((item) => {
                        return <li key={item.id}>{item.name}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              );
            }
            if (Tipos.tipo === 2) {
              return (
                <div className='conselho-federal'>
                  <span>conselho federal</span>
                  <div className='titulares-suplentes'>
                    <div className='titulares'>
                      <ul>
                        titulares
                        {Tipos.titulares.map((item) => {
                          return <li key={item.id}>{item.name}</li>;
                        })}
                      </ul>
                    </div>
                    <div className='suplentes'>
                      <ul>
                        suplentes
                        {Tipos.suplentes.map((item) => {
                          return <li key={item.id}>{item.name}</li>;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            }
            return 0;
          })}
        </div>
      </section>
      {flags && (
        <section className='section-2'>
          <div className='retangle'></div>
        </section>
      )}
    </div>
  );
};

export default Conselheiro;

import React, { useRef } from "react";
import { HideMobile, ShowMobile } from "./Carrossel.elements";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow } from "swiper/core";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
import "swiper/swiper.min.css";
// import leftarrow from "../../../assets/images/CarrosselSobre/left arrow.svg";
// import rightarrow from "../../../assets/images/CarrosselSobre/right arrow.svg";
import "./styles.scss";

SwiperCore.use([EffectCoverflow]);

const Carrossel = ({ aberto, diretores }) => {
  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)
  console.log(aberto)
  return (
    <>
      {aberto &&
        <>

          <HideMobile>
            <div style={{ width: "100vw", height: "700px" }}>
              <Swiper
                style={{ height: "100%" }}
                effect="coverflow"
                initialSlide={diretores ? diretores?.length / 2 : 0}
                spaceBetween={0}
                grabCursor={false}
                slideToClickedSlide={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                // navigation={{
                //   nextEl: ".swiper-button-next",
                //   prevEl: ".swiper-button-prev",
                // }}
                // onInit={(swiper) => {
                //   swiper.params.navigation.prevEl = navigationPrevRef.current;
                //   swiper.params.navigation.nextEl = navigationNextRef.current;
                //   swiper.navigation?.init();
                //   swiper.navigation?.update();
                // }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 800,
                  modifier: 1,
                  slideShadows: false,
                }}
              >
                {diretores?.map(diretor =>
                  <SwiperSlide key={diretor.nome}>
                    <div style={{ cursor: "pointer" }}>
                      <h1 className="slide-style">{diretor.cargo}</h1>
                      <img src={diretor.foto} alt="" className="img-fluid" />
                      <div className="pt-5 slide-card-style">
                        {diretor.nome}
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
              {/* <div className="arrows">
          <div  ref={navigationNextRef}>
            <img
                src={leftarrow}
                alt=""
              />
              </div>
          <div ref={navigationPrevRef} > 
          <img
                src={rightarrow}
                alt=""
              />
              </div>
          </div> */}
            </div>
          </HideMobile>
          <ShowMobile>
            <div className="mt-5" style={{ width: "100vw", height: "800px" }}>
              <Swiper
                style={{ height: "100%" }}
                effect="coverflow"
                spaceBetween={0}
                initialSlide={diretores ? diretores?.length / 2 : 0}
                grabCursor={false}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 800,
                  modifier: 1,
                  slideShadows: false,
                }}
              >
                {diretores?.map(diretor =>
                  <SwiperSlide key={diretor.nome}>
                    <div style={{ cursor: "pointer" }}>
                      <h1 className="slide-style">{diretor.cargo}</h1>
                      <img src={diretor.foto} alt="" className="img-fluid" />
                      <div className="pt-5 slide-card-style">
                        {diretor.nome}
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </ShowMobile>
        </>
      }
    </>
  );
};

export default Carrossel;

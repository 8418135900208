import styled from "styled-components";

export const Aba = styled.div`
    padding: 0 ${props => props.menuPadding};    
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0;

    .opcao{
        z-index: 11;
        text-align: center;
        transition: 300ms;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        font-family: Teko;
        font-size: 20px;
        font-weight: 450;

        .red-block{
            background-color: #FF7778;
            opacity: 0.5;
            margin-top: -15px;
            width: 110%;
            height: 12px;
            transition: 300ms;
        }

        &.opcao-false{
            animation: desativar 0.3s ease;
            opacity: 0.6;
            margin-right:15px;
            margin-left:15px;

            .red-block{
                width: 0;
                margin-top: 0;
                margin-left: 100%;
            }
        }

        &.opcao-true {
            opacity: 1;
            animation: ativar 0.3s ease;
            margin-left:6px;
            .red-block {
            }
        }
    }

    @media (max-width: 374px) {
        flex-wrap: wrap;
        width: 100%;
        padding: 10px;
    }

    @keyframes desativar {
        from {
            opacity: 1;
        }
        to {
            opacity: 0.6;
        }
    }

    @keyframes ativar {
        from {
            opacity: 0.6;
        }
        to {
            opacity: 1;
        }
    }
`;

export const Item = styled.div`
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    .aba-item-true{
        width: 100%;
        opacity: 1;
        animation: abrir 0.7s ease;
        &#diretoria-atual{
            display: flex;
        }
    }

    .aba-item-false{
        opacity: 0;
        animation: fechar 0.7s ease;
        width: 0;
        display: none;
        &#diretoria-atual{
            display: flex;
        }
    }

    .aba-item:hover{
        box-shadow: none;
    }

    @keyframes abrir {

        0% {
            width: 0;
            opacity: 0;
        }
        0.1% {
            width: 0;
            opacity: 0;
        }
        100% {
            opacity: 1;
            width: 100%;
        }
    }

    @keyframes fechar {
        0% {
            width: 100%;
            opacity: 1;
        }
        99.9% {
            width: 0;
            opacity: 0;
        }
        100% {
            width: 0;
            opacity: 0;
        }

    }
`;
import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Title } from '../../../components';
import InputSelect from '../Components/InputSelect';
import Input from './../Components/Input';
import InputArea from '../Components/InputArea';
import * as Yup from "yup";

import pergaminho from '../../../assets/images/FaleConosco/DefesaPrerrogativas/pergaminho.png';

import './styles.scss';

const DefesaPrerrogativas = () => {
  const formRef = useRef(null);

  const denuncias = [
    { value: "Reclamação", label: "Reclamação" },
    { value: "Denúncia", label: "Denúncia" },
    { value: "Pedido de Informação", label: "Pedido de Informação" },
    { value: "Sugestão", label: "Sugestão" },
    { value: "Elogio", label: "Elogio" },
  ];

  const respostas = [
    { value: "e-mail", label: "e-Mail" },
    { value: "Telefone", label: "Telefone" },
    { value: "Não obter resposta", label: "Não obter resposta" },
  ];

  const handleSubmit = async (data, { reset }) => {
    console.log(data);
    console.log(process.env.REACT_APP_EMAIL_SERVER_ENDPOINT)
    try {
      const schema = Yup.object().shape({
        denuncia: Yup.string().required('campo obrigatório'),
        email: Yup.string().required('campo obrigatório').email('digite um E-mail válido'),
        mensagem: Yup.string().required('campo obrigatório'),
        nome: Yup.string().required('campo obrigatório'),
        numero: Yup.string().required('campo obrigatório'),
        resposta: Yup.string().required('campo obrigatório')

      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setTimeout(() => {
        fetch("https://getinfomail.herokuapp.com/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dest: "ouvidoria@oabsergipe.org.br",
            sub: "Email de contato da OAB",
            html: `<h1 style="color:#02354D">${data.denuncia}</h1>
                 <p><b style="color: #777bd9">Nome: </b>${data.nome}</p>
                 <p><b style="color: #777bd9">Email: </b>${data.email}</p>
                 <p><b style="color: #777bd9">Número OAB: </b>${data.numero}</p>
                 <p><b style="color: #777bd9">Número Telefone: </b>${data.telefone ? data.telefone : "não informado"}</p>
                 <p><b style="color: #777bd9">Mensagem: </b>${data.mensagem}</p>
                 <p><b style="color: #777bd9">Resposta por: </b>${data.resposta}</p>`,
          }),
        })
          .then((response) => {
            response.json();
          })
          .then(() => {
            // eslint-disable-next-line no-alert
            alert("Seu email foi enviado com sucesso!");
          })
          .catch(() => {
            // eslint-disable-next-line no-alert
            alert(
              "Desculpe, mas parece que houve algum problema ao enviar o email. Tente novamente mais tarde."
            );
          });
      }, 500);
      setTimeout(() => {
        reset();
      }, 1000);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errorMessages = {};

        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      } else {
        alert(
          "Desculpe, mas parece que houve algum problema ao enviar o email. Tente novamente mais tarde."
        );
        console.log(err);
      }
    }
    console.log(data);
  };


  return (
    <div id="defesa">
      <section className="section-1">
        <Title
          textContent='Defesa das Prerrogativas'
          customClass='title-page'
          link1='A OAB/SE >'
          link2='&nbsp;Fale conosco>'
          link3='&nbsp;Defesa das Prerrogativas'
          rota1='/'
          customClassBackground='background-red-horizontal'
          title
        />
      </section>
      <section className="section-2">
        <div className="container-form">
          <Form ref={formRef} className="form" onSubmit={handleSubmit}>
            <InputSelect
              placeholder="Selecione"
              label="Tipo de Denúncia"
              name="denuncia"
              options={denuncias}
              className="retangle"
            />
            <InputSelect
              placeholder="Selecione"
              label="Deseja receber sua resposta por"
              name="resposta"
              options={respostas}
              className="retangle"
            />
            <Input
              label="Nome"
              name="nome"
            />
            <Input
              label="E-mail"
              name="email"
            />
            <Input
              label="Nº OAB"
              name="numero"
            />
            <Input
              label="Telefone"
              name="telefone"
              type="number"
            />
            <InputArea
              label="Mensagem"
              name="mensagem"
            />
            <button className="button" type="submit">
              Enviar
            </button>
          </Form>
          <div className="container-perga">
            <img src={pergaminho} alt="" />
            <p>
              Todos os campos de envio são obrigatórios (exceto telefone). Por favor, preencha
              cada área com o que é requisitado.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DefesaPrerrogativas;